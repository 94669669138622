import React, {useEffect, useState} from 'react';
import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Image,
  FormControl,
  FormLabel,
  Button,
  Modal,
} from 'react-bootstrap';
import axios from '../axiosConfig';
import {useSelector} from 'react-redux';
import {RootState} from 'state';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {
  updateProfileFailed,
  updateProfileStart,
  updateProfileSuccess,
} from 'state/login';
import Spinner from 'components/Spinner';
import {useNavigate} from 'react-router-dom';

export const Profile = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {user, token} = useSelector((state: RootState) => state.login);

  const {i18n: translationI18n} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameAR, setNameAR] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [nationalId, setNationalId] = useState('');

  const [show, setShow] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseErrors = () => setShowErrors(false);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(`${baseURL}/validate-token/`, {
          headers: {Authorization: `Token ${token}`},
        });
        if (response.status === 200) {
          setIsTokenValidated(true);
        } else {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      }
    };

    if (token) {
      validateToken();
    } else {
      navigate('/login');
    }
  }, [baseURL, navigate, token]);

  const handleUpdateProfileSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('mobile_number', e.target.mobile_number.value);
    bodyFormData.append('email', e.target.email.value);

    try {
      dispatch(updateProfileStart());
      axios({
        method: 'patch',
        url: `${baseURL}/update-user/${user?.id}/`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`,
        },
      })
        .then(function (response) {
          const {mobile_number, email} = response.data;
          dispatch(updateProfileSuccess({mobile_number, email}));
          setIsLoading(false);
          setShow(true);
          setTimeout(() => {
            handleClose();
          }, 2000);
          console.clear();
        })
        .catch(function (response) {
          dispatch(updateProfileFailed('Failed to update profile.'));
          setShowErrors(true);
          setIsLoading(false);
          setTimeout(() => {
            handleCloseErrors();
          }, 2000);
          console.clear();
        });
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  useEffect(() => {
    if (isTokenValidated) {
      setMobileNumber(user?.mobile_number || '');
      setEmail(user?.email || '');
      setName(user?.full_name || '');
      setNameAR(user?.full_name_arabic || '');
      setDateOfBirth(user?.date_of_birth ? user.date_of_birth.toString() : '');
      setGender(user?.gender || '');
      setNationalId(user?.iqama_number || '');
    }
  }, [isTokenValidated, user]);

  return (
    <>
      <Container className="mt-5 h-profile">
        <div>
          {!isLoading && (
            <>
              {translationI18n.language === 'en' ? (
                <>
                  {/* /CONTACT US PAGE ENGLISH */}
                  <Row>
                    <Col md={6} sm={12}>
                      <Form
                        className="ekseerForm"
                        style={{textAlign: 'left'}}
                        onSubmit={handleUpdateProfileSubmit}>
                        <h2 className="title">
                          Update <span id="cutomText">profile</span>
                        </h2>
                        <Row>
                          <Col md={12} sm={12}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                Name:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {name}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                Date of Birth:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {dateOfBirth}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                Gender:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {translationI18n.language === 'en'
                                  ? gender === 'Male'
                                    ? 'Male'
                                    : 'Female'
                                  : gender === 'Male'
                                  ? 'ذكر'
                                  : 'أنثى'}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                National ID/Iqama number:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {nationalId}
                              </span>
                            </FormLabel>
                          </Col>
                        </Row>
                        <Row style={{marginTop: '35px'}}>
                          <Col md={6} sm={6} style={{width: '50%'}}>
                            <FormLabel
                              htmlFor="mobile_number"
                              className="ekseerForm-label">
                              Mobile number
                            </FormLabel>
                            <FloatingLabel
                              controlId="mobile_number"
                              label="Ex: 5XXXXXXXX"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                type="number"
                                name="mobile_number"
                                placeholder="Ex: 5XXXXXXXX"
                                inputMode="numeric"
                                required
                                value={mobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md={6} sm={6} style={{width: '50%'}}>
                            <FormLabel
                              htmlFor="email"
                              className="ekseerForm-label">
                              Email address
                            </FormLabel>
                            <FloatingLabel
                              controlId="email"
                              label="Email address"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                type="email"
                                name="email"
                                placeholder="Email address"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Button type="submit" className="btnPrimary btnLeft">
                          Update profile
                        </Button>
                      </Form>
                      {user?.is_doctor ? (
                        <>
                          {user?.contract ? (
                            <Col md={6} sm={6} style={{width: '30%'}}>
                              <a
                                href={user?.contract}
                                target="_blank"
                                className="ekseerForm-label">
                                {' '}
                                -{'>'} Contract{' '}
                              </a>
                            </Col>
                          ) : (
                            ''
                          )}
                          {user?.no_objection_letter ? (
                            <Col md={6} sm={6} style={{width: '30%'}}>
                              <a
                                href={user?.no_objection_letter}
                                target="_blank"
                                className="ekseerForm-label">
                                {' '}
                                -{'>'} Non objection letter
                              </a>
                            </Col>
                          ) : (
                            ''
                          )}
                          {user?.additional_attachment ? (
                            <Col md={6} sm={6} style={{width: '30%'}}>
                              <a
                                href={user?.additional_attachment}
                                target="_blank"
                                className="ekseerForm-label">
                                {' '}
                                -{'>'} Additional attachments
                              </a>
                            </Col>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col md={6} sm={12} className="scientistCol">
                      <Image
                        src="assets/images/contact.png"
                        className="float"
                      />
                    </Col>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Profile updated successfully!</Modal.Title>
                      </Modal.Header>
                      <Image
                        src="assets/images/success.png"
                        className="mb-5 float"
                        id="call"
                      />
                    </Modal>
                    <Modal show={showErrors} onHide={handleCloseErrors}>
                      <Modal.Header closeButton>
                        <Modal.Title>Profile updated failed!</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The mobile number you provided is incorrect or the email
                        format is wrong or the mobile number or the email
                        address are already registered!
                      </Modal.Body>
                      <Image
                        src="assets/images/failed.png"
                        className="mb-5 float"
                        id="call"
                      />
                    </Modal>
                  </Row>
                </>
              ) : (
                <>
                  {/* /CONTACT US PAGE ARABIIC */}
                  <Row className="wrapReverse" dir="ltr">
                    <Col md={6} sm={12} className="scientistCol">
                      <Image
                        src="assets/images/contact.png"
                        className="float"
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <Form onSubmit={handleUpdateProfileSubmit} dir="rtl">
                        <h2 className="title">تحديث الملف</h2>
                        <Row>
                          <Col md={12} sm={12}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                {' '}
                                الاسم:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {nameAR}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                تاريخ الميلاد:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {dateOfBirth}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                الجنس:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {gender === 'Male' ? 'ذكر' : 'أنثى'}
                              </span>
                            </FormLabel>
                          </Col>
                          <Col md={12} sm={12} style={{marginTop: '15px'}}>
                            <FormLabel>
                              <span
                                style={{
                                  borderBottom: '4px solid #dbff17',
                                }}>
                                رقم الهوية الوطنية / الإقامة:{' '}
                              </span>
                              <span
                                style={{color: '#606060', fontSize: '18px'}}>
                                {nationalId}
                              </span>
                            </FormLabel>
                          </Col>
                        </Row>
                        <Row style={{marginTop: '35px'}}>
                          <Col md={6} sm={6} style={{width: '50%'}}>
                            <FormLabel htmlFor="mobile_number">
                              رقم الجوال
                            </FormLabel>
                            <FloatingLabel
                              controlId="mobile_number"
                              label="Ex: 5XXXXXXXX"
                              className={
                                translationI18n.language === 'en'
                                  ? ' innerFormLabel'
                                  : ' innerFormLabelAr'
                              }>
                              <FormControl
                                type="number"
                                name="mobile_number"
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                                style={{textAlign: 'right'}}
                                value={mobileNumber}
                                placeholder="Ex: 5XXXXXXXX"
                                inputMode="numeric"
                                required
                              />
                            </FloatingLabel>
                          </Col>
                          <Col md={6} sm={6} style={{width: '50%'}}>
                            <FormLabel
                              htmlFor="email"
                              className="ekseerForm-labelAr">
                              البريد الالكتروني
                            </FormLabel>
                            <FloatingLabel
                              controlId="email"
                              label="البريد الالكتروني"
                              className={
                                translationI18n.language === 'en'
                                  ? ' innerFormLabel'
                                  : ' innerFormLabelAr'
                              }>
                              <FormControl
                                type="email"
                                name="email"
                                value={email}
                                style={{textAlign: 'right'}}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="البريد الالكتروني"
                                required
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Button type="submit" className="btnPrimary btnRight">
                          تحديث الملف
                        </Button>
                      </Form>
                    </Col>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title className="colorBlackAr">
                          تم تحديث الملف بنجاح
                        </Modal.Title>
                      </Modal.Header>
                      <Image
                        src="assets/images/success.png"
                        className="mb-5 float"
                        id="call"
                      />
                    </Modal>
                    <Modal show={showErrors} onHide={handleCloseErrors}>
                      <Modal.Header closeButton>
                        <Modal.Title className="colorBlackAr">
                          فشل تحديث الملف الشخصي
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="colorBlackAr">
                        رقم الهاتف الجوال الذي قدمته غير صحيح أو البريد
                        الالكتروني مسجل مسبقاً!
                      </Modal.Body>
                      <Image
                        src="assets/images/failed.png"
                        className="mb-5 float"
                        id="call"
                      />
                    </Modal>
                  </Row>
                </>
              )}
            </>
          )}
          {isLoading && <Spinner />}
        </div>
      </Container>
    </>
  );
};

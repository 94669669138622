import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export const About = () => {
  const {i18n: translationI18n} = useTranslation();

  return (
    <>
      <Container className="mt-5 pl-6">
        {translationI18n?.language === 'en' ? (
          <>
            {/* /ABOUT US PAGE ENGLISH */}
            <Row>
              <Col md={6} sm={12}>
                <div className="ekseerForm text-left">
                  <h2 className="title">About Medical Cloud:</h2>
                  <h4>
                    We are a Saudi medical company licensed by the Saudi
                    Ministry of Health, specializing in remote emergency
                    healthcare services and telemedicine. Through health
                    technology, we facilitate access to healthcare anytime and
                    anywhere needed, and we improve the quality of healthcare
                    services through our emergency medicine consultants
                    specialized in adult and pediatric healthcare to achieve the
                    objectives of the Health Sector Transformation Program
                    related to the Saudi Vision 2030
                  </h4>
                </div>
                <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">What Distinguishes Us:</h2>
                  <ul>
                    <li>
                      Our medical team is an elite group of Saudi emergency
                      medicine consultants specializing in the healthcare of
                      adults and children
                    </li>
                    <li>
                      Ease of starting a remote medical consultation from your
                      mobile device
                    </li>
                    <li>Available 24 hours a day, 7 days a week</li>
                    <li>Complete data security and privacy</li>
                    <li>
                      Providing comprehensive healthcare by delivering the
                      necessary blood tests, imaging, and treatments to your
                      home through our partners
                    </li>
                  </ul>
                </div>
                <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">Our Services:</h2>
                  <ul>
                    <li>
                      Provide immediate emergency medical consultation via
                      telemedicine (online doctor) by a consultant physician
                      specializing in adult and pediatric emergency medicine
                    </li>
                    <li>
                      Provide awareness, education, and medical advice to the
                      patient or parent for their child by a pediatric
                      consultant specialized in emergency medicine
                    </li>
                    <li>Provide home health care to seniors remotely</li>
                    <li>
                      {' '}
                      Provide remote clinical assessment and re-evaluation of
                      the treatment plan if the service user needs it
                    </li>
                    <li> Provide Remote diagnostics</li>
                    <li>
                      {' '}
                      Order remote medical tests and send medical staff to the
                      patient to measure vital signs, take blood samples, and
                      administer intravenous treatments
                    </li>
                    <li>Issue a prescription and have it delivered to you</li>
                    <li>Issue a certified sick leave from Sehati platform</li>
                  </ul>
                </div>
                {/* <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">Our address & how to contact us:</h2>
                  <h4>
                    Al Marwah District - Al Khaleel Bin Ahmad Street 3913
                    <br />
                    Postal Code: 14721
                    <br />
                    Riyadh - Kingdom of Saudi Arabia
                    <br />
                    <strong>
                      You can contact us for inquiries, complaints, or
                      suggestions using the contact form or WhatsApp on the
                      website and also through the following email address. We
                      will respond within 24 :hours
                    </strong>
                    <a
                      href="mailto:info@alsahaba.sa"
                      id="mailTo"
                      rel="noreferrer"
                      target="_blank">
                      {' '}
                      info@alsahaba.sa
                    </a>
                  </h4>
                </div> */}
                {/* <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">
                    Tele-Care and Emergency Communication Medicine Services:
                  </h2>
                  <ol>
                    <li>Get an emergency medical consultation remotely</li>
                    <li>Raising awareness and educating the patient</li>
                    <li>Provide medical advice to the patient</li>
                    <li>
                      Patient guidance for health institutions where appropriate
                      treatment is available when necessary
                    </li>
                    <li>
                      Follow-up patients remotely based on information provided
                      by the patient
                    </li>
                    <li>
                      Remote clinical assessment and reassessment of the
                      treatment plan, in case the applicant needs to do so
                    </li>
                    <li>Request medical examinations</li>
                    <li>Remote diagnosis of the condition</li>
                    <li>Evaluate the patient's condition</li>
                    <li>Issuance of sick leave</li>
                  </ol>
                </div> */}
                <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">Cases We Treat:</h2>
                  <h4>
                    We treat all emergency cases that do not require urgent
                    transfer of the patient to the hospital,
                    <strong> such as:</strong>
                  </h4>

                  <ul>
                    <li>Headache</li>
                    <li>Infections</li>
                    <li>Shortness of breath</li>
                    <li>Bone, muscle, or joint pain</li>
                    <li>High blood pressure</li>
                    <li>High blood sugar</li>
                    <li>Runny nose and cold</li>
                    <li>Allergies</li>
                    <li>Sinusitis</li>
                    <li>Diseases of the urinary and reproductive system</li>
                    <li>Fatigue and lethargy</li>
                    <li>Abdominal pain treatment and its causes</li>
                    <li>Infant colic and its causes</li>
                    <li>Treating nausea in children and adults</li>
                    <li>Throat congestion treatment</li>
                    <li>Heartburn treatment</li>
                    <li>Infant constipation treatment and its causes</li>
                    <li>
                      Cough treatment for children and adults and its causes
                    </li>
                    <li>Baby constipation treatment</li>
                    <li>And many other cases</li>
                  </ul>
                </div>
                <div className="mt-3 ekseerForm text-left">
                  <h2 className="title">Our Address and How to Contact Us</h2>
                  <h4>
                    Riyadh - Kingdom of Saudi Arabia
                    <br />
                    <br />
                    Email:
                    <a
                      href="mailto:info@alsahaba.sa"
                      id="mailTo"
                      rel="noreferrer"
                      target="_blank">
                      {' '}
                      info@alsahaba.sa
                    </a>
                    <br />
                    <br />
                    Phone/WhatsApp:
                    <a href="tel:+966535393388">+966535393388</a>
                  </h4>
                </div>

                {/* <div className="ekseerForm text-left">
                  <h2 className="title">Working hours:</h2>
                  <h4>
                    Our working hours from 9am till 4pm from Sunday till
                    Thursday.
                  </h4>
                </div> */}
                {/* <div className="ekseerForm text-left mb-5">
                  <h2 className="title">Time takes to reply to patients:</h2>
                  <h4>
                    Our medical team is keen on replying to patient as soon as
                    possible within 15 minutes and that depends on the number of
                    patients served and the waiting time
                  </h4>
                </div> */}
                {/* <div className="ekseerForm text-left">
                  <h2 className="title">Services and payment methods:</h2>
                  <h4>Telemedicine consultation</h4>
                  <hr style={{marginTop: '15px'}} />

                  <h4 className="mt-3">
                    400 SAR for Tele-medicine consultation. Using electronic
                    payment gateways.
                  </h4>
                </div> */}
              </Col>
              <Col md={6} sm={12} className="scientistCol customScientistCol">
                <Image
                  src="assets/images/about.png"
                  className="float fixed-img"
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            {/* /ABOUT US PAGE ARABIIC */}
            <Row className="wrapReverse" dir="ltr">
              <Col md={6} sm={12} className="scientistCol customScientistCol">
                <Image
                  src="assets/images/about.png"
                  className="float fixed-img"
                />
              </Col>
              <Col md={6} sm={12}>
                <div className="ekseerForm text-right">
                  <h2 id="bB">نبذة عن السحابة الطبية</h2>
                  <h4>
                    نحن شركة سعودية طبية مرخصة من قبل وزارة الصحة السعودية
                    ومتخصصون بخدمات الرعاية الصحية الطارئة عن بعد والطب الاتصالي
                    وتسهيل الوصول إلى الرعاية الصحية في أي زمان ومكان والارتقاء
                    بجودة الخدمات الصحية من خلال استشاريين متخصصين في طب طوارئ
                    الكبار والأطفال باستخدام التقنية الصحية والمساهمة بتحقيق
                    مستهدفات برنامج تحول القطاع الصحي التابع لرؤية السعودية ٢٠٣٠
                  </h4>
                </div>
                <div className="ekseerForm mt-5 text-right" dir="rtl">
                  <h2 id="bB">ما يميزنا</h2>
                  <ul id="formAr">
                    <li>
                      طاقمنا الطبي هو نخبة من استشاريين طب الطوارئ سعوديين
                      متخصصين برعاية الكبار والأطفال
                    </li>
                    <li>
                      {' '}
                      سهولة بدء الاستشارة الطبية عن بعد من جهازكم المحمول
                    </li>
                    <li>متوفرون على مدار الساعة وطيلة أيام الأسبوع</li>
                    <li> أمن وخصوصية تامة للبيانات</li>
                    <li>
                      توفير رعاية متكاملة من خلال توصيل التحاليل والأشعة
                      والعلاجات اللازمة لمنزلكم عن طريق شركاؤنا
                    </li>
                  </ul>
                </div>
                <div className="ekseerForm mt-5 text-right" dir="rtl">
                  <h2 id="bB">الخدمات التي نقدمها</h2>
                  <ul id="formAr">
                    <li>
                      الحصول على استشارة طبية فورية طارئة عن بعد (طبيب اونلاين)
                      من قبل طبيب استشاري متخصص في طب طوارئ الكبار والأطفال.
                    </li>
                    <li>
                      توعية وتثقيف وتقديم النصيحة الطبية للمريض أو الوالدين
                      لطفلهم عن طريق استشاري أطفال متخصص في طب الطوارئ.
                    </li>
                    <li>تقديم رعاية صحية منزلية لكبار السن عن بعد.</li>
                    <li>
                      التقييم السريري عن بعد وإعادة تقييم خطة العلاج، في حال
                      احتاج طالب الخدمة لذلك.
                    </li>
                    <li>تشخيص الحالة عن بعد.</li>
                    <li>
                      طلب فحوصات طبية عن بعد وإرسال طاقم طبي للمريض لقياس
                      العلامات الحيوية وأخذ عينات الدم وإعطاء العلاجات اللازمة
                      عن طريق الوريد وعمل الأشعة بالمنزل إن لزم.
                    </li>
                    <li>إصدار وصفة طبية وتوصيلها لموقعكم.</li>
                    <li>إصدار إجازة مرضية معتمدة من منصة صحتي.</li>
                  </ul>
                </div>
                {/* <div
                  className="ekseerForm mt-5 text-right"
                  id="formAr"
                  dir="rtl">
                  <h2 id="bB">
                    الخدمات التي يقدمها مركز الرعاية عن بعد وطب الطوارئ الاتصالي
                  </h2>
                  <ol>
                    <li>الحصول على استشارة طبية طارئة عن بعد</li>
                    <li>توعية وتثقيف المريض</li>
                    <li>تقديم النصيحة الطبية للمريض</li>
                    <li>
                      توجيه المريض للمؤسسات الصحية التي يتوفر فيها العلاج
                      المناسب عند اللزوم
                    </li>
                    <li>
                      متابعة المرضى عن بعد بناء على المعلومات التي يتم توفيرها
                      من قبل المريض
                    </li>
                    <li>
                      التقييم السريري عن بعد وإعادة تقييم خطة العلاج، في حال
                      احتاج طالب الخدمة لذلك
                    </li>
                    <li>طلب فحوصات طبية عن بعد</li>
                    <li>تشخيص الحالة عن بعد</li>
                    <li>تقييم حالة المريض</li>
                    <li>إصدار الإجازة المرضية</li>
                  </ol>
                </div> */}
                <div className="ekseerForm mt-5 text-right" dir="rtl">
                  <h2 id="bB">الحالات التي نعالجها</h2>
                  <h5>
                    نعالج جميع الحالات الطارئة التي لا تحتاج لنقل المريض
                    للمستشفى بصفة عاجلة، مثل:
                  </h5>
                  <ul id="formAr">
                    <li>الصداع</li>
                    <li>الالتهابات</li>
                    <li>ضيق النفس</li>
                    <li>ألم العظام أو العضلات أو المفاصل</li>
                    <li>ارتفاع ضغط الدم</li>
                    <li>ارتفاع مستوى السكر بالدم</li>
                    <li>الرشح والزكام</li>
                    <li>الحساسية</li>
                    <li>التهاب الجيوب الأنفية</li>
                    <li>أمراض الجهاز البولي والتناسلي</li>
                    <li>الإعياء والخمول</li>
                    <li>علاج ألم البطن وأسبابه</li>
                    <li>مغص الطفل الرضيع وأسبابه</li>
                    <li>علاج غثيان الأطفال والكبار</li>
                    <li>علاج احتقان الحلق</li>
                    <li>علاج حرقان المعدة</li>
                    <li>علاج إمساك الرضع وأسبابه</li>
                    <li>علاج كحة الأطفال والكبار وأسبابه</li>
                    <li>علاج إمساك الأطفال</li>
                    <li>وغيره الكثير من الحالات</li>
                  </ul>
                </div>

                <div className="ekseerForm mt-5 text-right" id="formAr">
                  <h2 id="bB">العنوان وطريقة التواصل</h2>
                  <h4 style={{direction: 'rtl'}}>
                    الرياض – المملكة العربية السعودية
                    <br />
                    <br />
                    البريد الالكتروني:
                    <a
                      href="mailto:info@alsahaba.sa"
                      id="mailTo"
                      rel="noreferrer"
                      target="_blank">
                      {' '}
                      info@alsahaba.sa{' '}
                    </a>
                    <br />
                    <br />
                    الهاتف أو الواتساب:{' '}
                    <a href="tel:+966535393388">٩٦٦٥٣٥٣٩٣٣٨٨+</a>
                  </h4>
                </div>

                {/* <div className="ekseerForm mt-5 text-right" id="formAr">
                  <h2 id="bB">عنواننا وطريقة التواصل</h2>
                  <h4>
                    حي المروة - شارع الخليل بن أحمد ٣٩١٣
                    <br />
                    الرمز البريدي: ١٤٧٢١
                    <br />
                    الرياض – المملكة العربية السعودية
                    <br />
                    <br />
                    يمكنكم التواصل معنا للاستفسار، الشكاوى أو الاقتراحات عن طريق
                    نموذج التواصل أو الواتساب في الموقع وأيضاً من خلال البريد
                    الالكتروني التالي، وسوف يتم الرد خلال ٢٤ ساعة
                    <a
                      href="mailto:info@alsahaba.sa"
                      id="mailTo"
                      rel="noreferrer"
                      target="_blank">
                      {' '}
                      info@alsahaba.sa
                    </a>
                  </h4>
                </div> */}
                {/* <div className="ekseerForm mt-5 text-right">
                  <h2 id="bB">أوقات العمل</h2>
                  <h4>
                    أوقات العمل من الساعة التاسعة صباحا وحتى الرابعة عصرا من
                    الأحد إلى الخميس
                  </h4>
                </div> */}
                {/* <div className="ekseerForm mt-5 text-right mb-5">
                  <h2 id="bB">الوقت المستغرق للرد على المريض</h2>
                  <h4>
                    طاقمنا الطبي حريص للرد على المريض بأسرع وقت ممكن حوالي 15
                    دقيقة ويعتمد على عدد المرضى الذين سيتم خدمتهم ووقت الانتظار
                  </h4>
                </div> */}
                {/* <div className="ekseerForm mt-5 mb-5 text-right">
                  <h2 id="bB">أسعار الخدمات المقدمة وطرق الدفع</h2>
                  <h4>
                    استشارة طبية عن بعد
                    <hr style={{marginTop: '15px'}} />
                  </h4>
                  <h4 className="mt-2">
                    ٤٠٠ ريال سعودي قيمة الاستشارة الطبية عن بعد ويتم الدفع عن
                    طريق بوابة الدفع الالكترونية المعتمدة
                  </h4>
                </div> */}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

import {VideoCall} from 'VideoCall';
import {Footer} from 'components/Footer';
import PreRegister from 'components/PreRegister';
import SharedNavbar from 'components/SharedNavbar';
import ScrollToTop from 'helpers/helpers';
import {About} from 'pages/About';
import Calls from 'pages/Calls';
import {Consultations} from 'pages/Consultations';
import {Contact} from 'pages/Contact';
import FAQs from 'pages/FAQs';
import Home from 'pages/Home';
import NotFoundPage from 'pages/NotFoundPage';
import {OurDoctors} from 'pages/OurDoctors';
import PaymentResult from 'pages/PaymentResult';
import {PrivacyPolicy} from 'pages/PrivacyPolicy';
import {Profile} from 'pages/Profile';
import Questions from 'pages/Questions';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
interface PrivateRoutesProps {
  isActived: boolean | undefined;
}

export const PrivateRoutes: React.FC<PrivateRoutesProps> = ({isActived}) => {
  return (
    <Router>
      <ScrollToTop />
      <SharedNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Navigate to="/" />} />
        {/* <Route path="/pre-register" element={<Navigate to="/" />} /> */}
        <Route path="/register" element={<Navigate to="/" />} />
        <Route path="/reset-password" element={<Navigate to="/" />} />
        <Route
          path="/confirm-reset-password/:token"
          element={<Navigate to="/" />}
        />
        <Route
          path="/questions"
          element={isActived ? <Questions /> : <Navigate to="/" />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pre-register" element={<PreRegister />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/our-doctors" element={<OurDoctors />} />
        <Route
          path="/video-call"
          element={isActived ? <VideoCall /> : <Navigate to="/" />}
        />
        <Route
          path="/payment"
          element={isActived ? <PaymentResult /> : <Navigate to="/" />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/consultations" element={<Consultations />} />
        <Route
          path="/calls"
          element={isActived ? <Calls /> : <Navigate to="/" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default PrivateRoutes;

import React from 'react';

function WhatsappLink() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=966535393388"
      className="float-whatsapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp my-float"></i>
    </a>
  );
}

export default WhatsappLink;

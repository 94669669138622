import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  FormEvent,
} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormCheck,
  Button,
  FormControl,
  FloatingLabel,
  FormLabel,
  Image,
  FormGroup,
  FormSelect,
  Modal,
} from 'react-bootstrap';
import Animate from 'components/Animate';
//@ts-ignore
import scientistImage from '../../assets/images/register.jpeg';
//@ts-ignore
import doctorImage from '../../assets/images/ddoctorregister.jpeg';
//@ts-ignore
import successImage from '../../assets/images/success.png';
//@ts-ignore
import failedImage from '../../assets/images/failed.png';
import {useTranslation} from 'react-i18next';
import i18n from 'locales/i18n';
import {useLocation, useNavigate} from 'react-router-dom';
import Spinner from 'components/Spinner';
import axios from '../../axiosConfig';

interface FormValues {
  id: number;
  password: string;
  mobile_number: string;
  email: string;
  scfhs_number: string;
  scfhs_card: File | null;
  current_employer_name: string;
  cv: File | null;
  personal_photo: File | null;
  is_verified: boolean;
  is_doctor: boolean;
  doctor_type: string;
  is_payment_enabled: boolean;
  is_actived: boolean;
  last_login: string;
  remember_me: boolean;
}

const Register = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {t} = useTranslation();
  const {state} = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    id: 0,
    password: '',
    mobile_number: '',
    email: '',
    scfhs_number: '',
    scfhs_card: null,
    current_employer_name: '',
    cv: null,
    personal_photo: null,
    is_verified: false,
    is_doctor: false,
    doctor_type: 'adult',
    is_payment_enabled: false,
    is_actived: false,
    last_login: '',
    remember_me: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [pageCurrentLang, setPageCurrentLang] = useState('en');
  const [errors, setErrors] = useState({
    idErrors: false,
    emailErrors: false,
    mobileNumberErrors: false,
    fileSizeError: false,
    fileErrors: {
      scfhs_card: false,
      personal_photo: false,
      cv: false,
    },
  });
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const personalPhotoRef = useRef<HTMLInputElement>(null);
  const cvRef = useRef<HTMLInputElement>(null);
  const copyOfSCFHSRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!state?.personBasicInfo) {
      navigate('/pre-register');
    }
  }, [state, navigate]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {id, files} = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setFormValues((prev) => ({
        ...prev,
        [id]: file,
      }));
      setErrors((prev) => ({
        ...prev,
        fileErrors: {
          ...prev.fileErrors,
          [id]: false,
        },
      }));
    }
  };

  const handleFieldClick = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.click();
  };

  const handleCloseErrorsModal = () => setShowErrorsModal(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const doctorsPortal = Boolean(state?.doctorsPortal);

  const togglePasswordVisibility = () => setPasswordVisible((prev) => !prev);

  const resetErrors = () => {
    setErrors({
      idErrors: false,
      emailErrors: false,
      mobileNumberErrors: false,
      fileSizeError: false,
      fileErrors: {
        scfhs_card: false,
        personal_photo: false,
        cv: false,
      },
    });
  };

  const validateFiles = () => {
    if (!doctorsPortal) return true; // Skip file validation if not in doctorsPortal

    const maxFileSize = 10 * 1024 * 1024; // 10MB

    const hasMissingFiles =
      !formValues.scfhs_card || !formValues.personal_photo || !formValues.cv;

    if (hasMissingFiles) {
      setErrors((prev) => ({
        ...prev,
        fileErrors: {
          scfhs_card: !formValues.scfhs_card,
          personal_photo: !formValues.personal_photo,
          cv: !formValues.cv,
        },
      }));
      return false;
    }

    const isFileTooLarge =
      (formValues.scfhs_card && formValues.scfhs_card.size > maxFileSize) ||
      (formValues.personal_photo &&
        formValues.personal_photo.size > maxFileSize) ||
      (formValues.cv && formValues.cv.size > maxFileSize);

    if (isFileTooLarge) {
      setErrors((prev) => ({...prev, fileSizeError: true}));
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    resetErrors();

    if (!validateFiles()) {
      setLoading(false);
      setShowErrorsModal(true);
      return;
    }

    const bodyFormData = new FormData();

    bodyFormData.append('is_elm_verified', 'true');
    bodyFormData.append('full_name', state.personBasicInfo.fullNameT);
    bodyFormData.append('full_name_arabic', state.personBasicInfo.fullName);
    bodyFormData.append(
      'gender',
      state.personBasicInfo.sexCode === '2' ? 'Female' : 'Male',
    );
    bodyFormData.append('iqama_number', state.id);
    bodyFormData.append('password', formValues.password);
    bodyFormData.append('date_of_birth', state.dob);
    bodyFormData.append('mobile_number', formValues.mobile_number);
    bodyFormData.append('email', formValues.email);
    bodyFormData.append(
      'nationality',
      state?.id?.startsWith('1')
        ? 'SA'
        : state.personBasicInfo.nationalityDescEn,
    );

    if (doctorsPortal) {
      bodyFormData.append('scfhs_registration', formValues.scfhs_number || '');
      if (formValues.scfhs_card)
        bodyFormData.append(
          'copy_of_scfhs_registration_card',
          formValues.scfhs_card,
        );
      if (formValues.personal_photo)
        bodyFormData.append('personal_photo', formValues.personal_photo);
      if (formValues.cv) bodyFormData.append('cv', formValues.cv);
      bodyFormData.append(
        'current_employer_name',
        formValues.current_employer_name || '',
      );
      bodyFormData.append('is_doctor', 'true');
      bodyFormData.append('doctor_type', formValues.doctor_type || '');
    } else {
      bodyFormData.append('is_doctor', 'false');
      bodyFormData.append('doctor_type', '');
    }

    try {
      const response = await axios.post(`${baseURL}/register/`, bodyFormData, {
        headers: {'Content-Type': 'multipart/form-data'},
      });

      // Assuming the response contains the user_id in the result
      const userId = response.data?.id;

      // Trigger the registration success event with the user_id in the data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'registrationSuccess',
        user_id: userId,
      });

      setLoading(false);
      setShowSuccessModal(true);
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setLoading(false);
      setShowErrorsModal(true);
      const responseErrors = error.response.data;
      setErrors((prev) => ({
        ...prev,
        idErrors: !!responseErrors.iqama_number,
        emailErrors: !!responseErrors.email,
        mobileNumberErrors: !!responseErrors.mobile_number,
      }));
    }
  };

  useEffect(() => {
    const handleLanguageChange = () => setPageCurrentLang(i18n.language);
    i18n.on('languageChanged', handleLanguageChange);
    handleLanguageChange();
    return () => i18n.off('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
    if (doctorsPortal) {
      i18n.changeLanguage('en');
    }
  }, [doctorsPortal]);

  const encodeUrl = (url: string) => btoa(url);
  const pdfUrl = 'https://backend.alsahaba.sa/media/terms/T&C.pdf';
  const encryptedUrl = encodeUrl(pdfUrl);
  useEffect(() => {
    if (!doctorsPortal) return;

    const elements = document.querySelectorAll<HTMLElement>('#arabicWord');

    elements.forEach((element) => {
      element.style.display = 'none';
    });
  }, [doctorsPortal]);

  return (
    <main>
      {!loading && (
        <Animate>
          <section className="d-flex align-items-center my-2 mt-lg-4 mb-lg-3">
            <Container className="mt-5">
              <Row className="align-items-top justify-content-between">
                <Col md={6} sm={12}>
                  <Form onSubmit={handleSubmit}>
                    <h2 className="title">
                      {t('pages.register.title', {
                        name:
                          i18n.language === 'ar'
                            ? state.personBasicInfo.fullName
                            : state.personBasicInfo.fullNameT,
                      })}
                    </h2>
                    <Row>
                      <Col md={6} sm={6}>
                        <FormGroup className="mb-3">
                          <FormLabel htmlFor="mobile_number">
                            {t('pages.register.mobileNumber')}
                          </FormLabel>
                          <FloatingLabel
                            controlId="mobile_number"
                            label={t('pages.register.mobileNumberExample')}
                            className={
                              pageCurrentLang === 'en'
                                ? 'innerFormLabel'
                                : 'innerFormLabelAr'
                            }>
                            <FormControl
                              type="text"
                              name="mobile_number"
                              value={formValues.mobile_number}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,9}$/.test(value)) {
                                  setFormValues((prev) => ({
                                    ...prev,
                                    mobile_number: value,
                                  }));
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Ex: 5XXXXXXXX"
                              required
                            />
                          </FloatingLabel>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={6}>
                        <FormGroup className="mb-3">
                          <FormLabel htmlFor="email">
                            {t('pages.register.email')}
                          </FormLabel>
                          <FloatingLabel
                            controlId="email"
                            label={t('pages.register.email')}
                            className={
                              pageCurrentLang === 'en'
                                ? 'innerFormLabel'
                                : 'innerFormLabelAr'
                            }>
                            <FormControl
                              type="email"
                              name="email"
                              value={formValues.email}
                              onChange={(e) =>
                                setFormValues((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }))
                              }
                              placeholder="Email address"
                              required
                            />
                          </FloatingLabel>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col md={12} sm={12}>
                      <FormGroup className="mb-3">
                        <FormLabel htmlFor="password">
                          {t('pages.register.password')}
                        </FormLabel>
                        <FloatingLabel
                          controlId="floatingPassword"
                          label={t('pages.register.password')}
                          className={
                            pageCurrentLang === 'en'
                              ? 'innerFormLabel password-input-container'
                              : 'innerFormLabelAr password-input-container'
                          }>
                          <FormControl
                            type={passwordVisible ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                            value={formValues.password}
                            onChange={(e) =>
                              setFormValues((prev) => ({
                                ...prev,
                                password: e.target.value,
                              }))
                            }
                            required
                          />
                          <span
                            className={
                              pageCurrentLang === 'en'
                                ? 'password-toggle-btn'
                                : 'password-toggle-btnAr'
                            }
                            onClick={togglePasswordVisibility}>
                            {t(
                              passwordVisible
                                ? 'pages.register.hide'
                                : 'pages.register.show',
                            )}
                          </span>
                        </FloatingLabel>
                      </FormGroup>
                    </Col>
                    {doctorsPortal && (
                      <>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <FormLabel htmlFor="scfhs_registration">
                              {t('pages.register.scfhs')}
                            </FormLabel>
                            <FloatingLabel
                              controlId="scfhs_registration"
                              label={t('pages.register.scfhs')}
                              className={
                                pageCurrentLang === 'en'
                                  ? 'innerFormLabel'
                                  : 'innerFormLabelAr'
                              }>
                              <FormControl
                                type="text"
                                name="scfhs_registration"
                                value={formValues.scfhs_number}
                                onChange={(e) =>
                                  setFormValues((prev) => ({
                                    ...prev,
                                    scfhs_number: e.target.value,
                                  }))
                                }
                                placeholder={t('pages.register.scfhs')}
                                required
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <FormLabel htmlFor="scfhs_card">
                              {t('pages.register.copyOfScfhs')}
                            </FormLabel>
                            <FormControl
                              type="file"
                              id="scfhs_card"
                              name="scfhs_card"
                              onChange={handleFileChange}
                              style={{display: 'none'}}
                              ref={copyOfSCFHSRef}
                            />
                            <FormControl
                              type="text"
                              value={formValues.scfhs_card?.name || ''}
                              readOnly
                              placeholder="Copy of SCFHS Registration"
                              style={{
                                padding: '1rem .75rem',
                                paddingLeft: '25px',
                              }}
                              onClick={() => handleFieldClick(copyOfSCFHSRef)}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <FormLabel htmlFor="personal_photo">
                                {t('pages.register.personalPhoto')}
                              </FormLabel>
                              <FormControl
                                type="file"
                                id="personal_photo"
                                name="personal_photo"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                ref={personalPhotoRef}
                              />
                              <FormControl
                                type="text"
                                value={formValues.personal_photo?.name || ''}
                                readOnly
                                placeholder="Your Personal Photo"
                                style={{
                                  padding: '1rem .75rem',
                                  paddingLeft: '25px',
                                }}
                                onClick={() =>
                                  handleFieldClick(personalPhotoRef)
                                }
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <FormLabel htmlFor="cv">
                                {t('pages.register.cv')}
                              </FormLabel>
                              <FormControl
                                type="file"
                                id="cv"
                                name="cv"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                ref={cvRef}
                              />
                              <FormControl
                                type="text"
                                value={formValues.cv?.name || ''}
                                readOnly
                                placeholder="CV"
                                style={{
                                  padding: '1rem .75rem',
                                  paddingLeft: '25px',
                                }}
                                onClick={() => handleFieldClick(cvRef)}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup className="mb-3">
                              <FormLabel htmlFor="current_employer_name">
                                {t('pages.register.employerName')}
                              </FormLabel>
                              <FloatingLabel
                                controlId="current_employer_name"
                                label={t('pages.register.employerName')}
                                className={
                                  pageCurrentLang === 'en'
                                    ? 'innerFormLabel'
                                    : 'innerFormLabelAr'
                                }>
                                <FormControl
                                  type="text"
                                  name="current_employer_name"
                                  value={formValues.current_employer_name}
                                  onChange={(e) =>
                                    setFormValues((prev) => ({
                                      ...prev,
                                      current_employer_name: e.target.value,
                                    }))
                                  }
                                  placeholder="Current Employer Name"
                                  required
                                />
                              </FloatingLabel>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                    {doctorsPortal && (
                      <FormGroup className="mb-3">
                        <FormLabel htmlFor="pediatric">
                          Based on your Emergency Medicine Certificate
                          specialty/sub-specialty, you prefer to provide a
                          consultation to?
                        </FormLabel>
                        <FormSelect
                          name="pediatric"
                          style={{padding: '1rem .75rem'}}
                          required
                          value={formValues.doctor_type}
                          onChange={(e) =>
                            setFormValues((prev) => ({
                              ...prev,
                              doctor_type: e.target.value,
                            }))
                          }>
                          <option value="adult">Adult Patients</option>
                          <option value="pediatric">Pediatric Patients</option>
                          <option value="adultAndPediatric">
                            Adult & Pediatric Patients
                          </option>
                        </FormSelect>
                      </FormGroup>
                    )}
                    <FormGroup className="rememberMeGroup">
                      <Form.Check type="checkbox">
                        {i18n.language === 'ar' ? (
                          <>
                            <FormCheck.Input
                              data-testid="login-rememberMe"
                              id="defaultCheck5"
                              className="me-2"
                              required
                              checked={formValues.remember_me}
                              onChange={(e) => {
                                const {checked} = e.target;
                                setFormValues((prev) => ({
                                  ...prev,
                                  remember_me: checked,
                                }));
                                localStorage.setItem(
                                  'rememberMe',
                                  checked ? 'true' : 'false',
                                );
                              }}
                            />
                            <FormCheck.Label
                              htmlFor="defaultCheck5"
                              style={{fontWeight: 'bold'}}
                              className="mb-0 newUserRegister">
                              {t('pages.register.agree')}{' '}
                              <a
                                href={`/redirect?u=${encryptedUrl}`}
                                type="application/pdf"
                                rel="noopener noreferrer"
                                target="_blank">
                                <span style={{textDecoration: 'underline'}}>
                                  {t('pages.register.termsAndConditions')}
                                </span>
                              </a>
                            </FormCheck.Label>
                          </>
                        ) : (
                          <>
                            <FormCheck.Input
                              data-testid="login-rememberMe"
                              id="defaultCheck5"
                              className="me-2"
                              required
                              checked={formValues.remember_me}
                              onChange={(e) => {
                                const {checked} = e.target;
                                setFormValues((prev) => ({
                                  ...prev,
                                  remember_me: checked,
                                }));
                                localStorage.setItem(
                                  'rememberMe',
                                  checked ? 'true' : 'false',
                                );
                              }}
                            />
                            <FormCheck.Label
                              htmlFor="defaultCheck5"
                              style={{fontWeight: 'bold'}}
                              className="mb-0 newUserRegister">
                              {t('pages.register.agree')}{' '}
                              <a
                                href={`/redirect?u=${encryptedUrl}`}
                                type="application/pdf"
                                rel="noopener noreferrer"
                                target="_blank">
                                <span style={{textDecoration: 'underline'}}>
                                  {t('pages.register.termsAndConditions')}
                                </span>
                              </a>
                            </FormCheck.Label>
                          </>
                        )}
                      </Form.Check>
                    </FormGroup>

                    <Button type="submit" className="btnPrimary">
                      {t('pages.register.register')}
                    </Button>
                  </Form>
                </Col>
                <Col md={6} sm={12}>
                  <Image
                    src={doctorsPortal ? doctorImage : scientistImage}
                    className={
                      i18n.language === 'ar' ? 'fixed-img2-ar' : 'fixed-img2'
                    }
                    style={{borderRadius: '25px', marginBottom: '30px'}}
                  />
                </Col>
                <Modal show={showErrorsModal} onHide={handleCloseErrorsModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t('pages.register.registerError')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {doctorsPortal &&
                      (errors.fileErrors.cv ||
                        errors.fileErrors.personal_photo ||
                        errors.fileErrors.scfhs_card) && (
                        <ul className="error-list">
                          <li className="error">
                            Make sure all files are uploaded
                          </li>
                        </ul>
                      )}
                    {errors.idErrors && (
                      <ul className="error-list">
                        <li className="error">
                          {t('pages.register.idIqamaNumberErrors')}
                        </li>
                      </ul>
                    )}
                    {errors.mobileNumberErrors && (
                      <ul className="error-list">
                        <li className="error">
                          {t('pages.register.mobileNumberErrors')}
                        </li>
                      </ul>
                    )}
                    {errors.emailErrors && (
                      <ul className="error-list">
                        <li className="error">
                          {t('pages.register.emailErrors')}
                        </li>
                      </ul>
                    )}
                    {errors.fileSizeError && (
                      <ul className="error-list">
                        <li className="error">
                          {t('pages.register.fileSizeError')}
                        </li>
                      </ul>
                    )}
                  </Modal.Body>
                  <Image src={failedImage} className="mb-5 float" id="call" />
                </Modal>
                <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t('pages.register.registerSuccess')}
                    </Modal.Title>
                  </Modal.Header>
                  <Image src={successImage} className="mb-5 float" id="call" />
                </Modal>
              </Row>
            </Container>
          </section>
        </Animate>
      )}
      {loading && <Spinner />}
    </main>
  );
};

export default Register;

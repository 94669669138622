import React, {useState} from 'react';
import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Image,
  FormControl,
  FormLabel,
  Button,
  Modal,
} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import axios from '../axiosConfig';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/Spinner';

export const ConfirmForgotPassword = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {t, i18n: translationI18n} = useTranslation();

  let {token} = useParams();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClose = () => setShow(false);

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('password', e.target.password.value);
    bodyFormData.append('token', token);
    axios({
      method: 'post',
      url: `${baseURL}/password_reset/confirm/`,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(function (response) {
        setIsLoading(false);
        setShow(true);
        setTimeout(() => {
          nav('/');
        }, 2000);
        console.clear();
      })
      .catch(function (response) {
        setIsLoading(false);
        console.clear();
        if (response.response.data.email) {
          setWrongCredentials(true);
        }
      });
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <Container className="mt-5 h-profile">
        {!isLoading && (
          <>
            {translationI18n?.language === 'en' ? (
              <>
                {/* /LOGIN PAGE ENGLISH */}
                <Row className="align-center">
                  <Col md={6} sm={12}>
                    <Form
                      style={{textAlign: 'left'}}
                      onSubmit={handleResetPasswordSubmit}>
                      <h2 className="title">Reset your password</h2>
                      {wrongCredentials ? (
                        <p className="error">Something went wrong.</p>
                      ) : (
                        ''
                      )}
                      <FormLabel
                        htmlFor="password"
                        className="ekseerForm-label">
                        Enter your new password
                      </FormLabel>
                      <FloatingLabel
                        label="Enter your new password"
                        className="mb-3 ekseerFormInnerLabel">
                        <FormControl
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          placeholder="Enter your new password"
                        />
                        <span
                          className={
                            translationI18n?.language === 'en'
                              ? 'password-toggle-btn'
                              : 'password-toggle-btnAr'
                          }
                          onClick={togglePasswordVisibility}>
                          {passwordVisible
                            ? t('pages.login.hide')
                            : t('pages.login.show')}
                        </span>
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnLeft">
                        Reset password
                      </Button>
                    </Form>
                  </Col>
                  <Col md={6} sm={12} className="scientistCol">
                    <Image
                      src={
                        process.env.PUBLIC_URL + '/assets/images/scientist.png'
                      }
                      className="float"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {/* /LOGIN PAGE ARABIIC */}
                <Row className="wrapReverse" dir="ltr">
                  <Col md={6} sm={12} className="scientistCol">
                    <Image
                      src={
                        process.env.PUBLIC_URL + '/assets/images/scientist.png'
                      }
                      className="float"
                    />
                  </Col>
                  <Col md={6} sm={12} className="fixMargin">
                    <Form dir="rtl" onSubmit={handleResetPasswordSubmit}>
                      <h2 className="title">استعادة كلمة المرور</h2>
                      {wrongCredentials ? (
                        <p className="error">حدث خطأ من فضلك حاول في وقت اخر</p>
                      ) : (
                        ''
                      )}
                      <FormLabel htmlFor="password">كلمة المرور</FormLabel>
                      <FloatingLabel
                        label="كلمة المرور"
                        className="mb-3 innerFormLabelAr">
                        <FormControl
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          placeholder=">كلمة المرور"
                        />
                        <span
                          className={
                            translationI18n?.language === 'en'
                              ? 'password-toggle-btn'
                              : 'password-toggle-btnAr'
                          }
                          onClick={togglePasswordVisibility}>
                          {passwordVisible
                            ? t('pages.login.hide')
                            : t('pages.login.show')}
                        </span>
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnRight">
                        تغيير كلمة المرور
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '14px'}}>
                  {translationI18n?.language === 'en' ? 'Done!' : 'تم'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>
                  {translationI18n?.language === 'en'
                    ? 'Your password has been changed!, you can now log in with your new password.'
                    : 'تم تغيير كلمة المرور الخاصة بك! يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك.'}
                </h5>
              </Modal.Body>
            </Modal>
          </>
        )}
        {isLoading && <Spinner />}
      </Container>
    </>
  );
};

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import {RootState} from 'state';
import {logout} from 'state/login';
import i18n from 'locales/i18n';

export const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const {isLogged, user, token} = useSelector(
    (state: RootState) => state.login,
  );

  // Set language based on user's status
  useEffect(() => {
    if (user?.is_doctor) {
      i18n.changeLanguage('en');
    }
  }, [user]);

  // Conditional rendering based on login status
  if (isLogged && user) {
    const isDoctorVerified = user.is_doctor ? user.is_verified : true;
    const isUserActive =
      user.is_actived && user.is_elm_verified && isDoctorVerified;

    return <PrivateRoutes isActived={isUserActive} />;
  }

  if (token && !user) {
    dispatch(logout(token));
  }

  return <PublicRoutes />;
};

export default Routes;

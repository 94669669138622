import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {
  Container,
  FloatingLabel,
  Form,
  FormControl,
  FormLabel,
  Button,
  Modal,
  Image,
} from 'react-bootstrap';
import axios from '../axiosConfig';
import {useSelector} from 'react-redux';
import {RootState} from 'state';

export const ModifyConsultation = (props) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {token} = useSelector((state: RootState) => state.login);
  const {state} = useLocation();

  const [show, setShow] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [medication, setMedication] = useState(false);
  const [sickLeave, setSickLeave] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    props?.handleCloseModifyConsultation();
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    props?.handleCloseModifyConsultation();
  };

  const medic = (e) => {
    if (e.target.value === 'YES') {
      setMedication(true);
    } else {
      setMedication(false);
    }
  };
  const sick = (e) => {
    if (e.target.value === 'YES') {
      setSickLeave(true);
    } else {
      setSickLeave(false);
    }
  };

  const handleModifyConsultationSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    var bodyFormData = new FormData();
    var doctor_id = state?.user.id;
    bodyFormData.append('doctor_id', doctor_id);
    bodyFormData.append('patient_id', props?.consultation?.patient_id);
    bodyFormData.append('chief_complaint', e.target.chief_complaint.value);
    bodyFormData.append(
      'history_of_illness',
      e.target.history_of_illness.value,
    );
    bodyFormData.append('review_of_systems', e.target.review_of_systems.value);
    bodyFormData.append('examination', e.target.examination.value);
    bodyFormData.append('assessment', e.target.assessment.value);
    if (e.target.medication === undefined) {
      bodyFormData.append('medication', 'No');
    } else {
      bodyFormData.append('medication', e.target.medication.value);
    }
    if (e.target.sick_leave === undefined) {
      bodyFormData.append('sick_leave', 0);
    } else {
      bodyFormData.append('sick_leave', e.target.sick_leave.value);
    }
    await axios({
      method: 'put',
      url: `${baseURL}/update-consultation/${props?.consultation.id}/`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${token}`,
      },
    })
      .then(function (response) {
        console.clear();
        setIsLoading(false);
        setShowSuccessModal(true);
        setShow(false);
        setTimeout(() => {
          setShowSuccessModal(false);
          handleClose();
        }, 2000);
      })
      .catch(function (response) {
        console.clear();
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <Container>
      {!isLoading && (
        <Modal show={show} onHide={handleClose} id="consultationModal">
          <Modal.Body>
            <Form
              className="ekseerForm"
              style={{textAlign: 'left'}}
              onSubmit={handleModifyConsultationSubmit}>
              <div className="row">
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    Chief Complaint:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="chief_complaint"
                    defaultValue={props?.consultation.chief_complaint}
                    placeholder="Chief Complaint ..."
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    History of Presenting Illness:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="history_of_illness"
                    defaultValue={props?.consultation.history_of_illness}
                    placeholder="History of Presenting Illness ..."
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    Review of Systems:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="review_of_systems"
                    defaultValue={props?.consultation.review_of_systems}
                    placeholder="Review of Systems ..."
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    Examination:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="examination"
                    defaultValue={props?.consultation.examination}
                    placeholder="Examination ..."
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    Assessment/Plan:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="assessment"
                    defaultValue={props?.consultation.assessment}
                    placeholder="Assessment/Plan ..."
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="ekseerForm-label">
                    Prescribe Medication
                  </Form.Label>
                  <div className="d-flex mb-4" id="d-flex">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      id="yes"
                      name="yesOrNo"
                      defaultValue="YES"
                      onChange={medic}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      id="no"
                      name="yesOrNo"
                      defaultChecked
                      onChange={medic}
                    />
                  </div>
                  {medication && (
                    <>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="medic"
                        name="medication"
                        defaultValue={props?.consultation.medication}
                        placeholder="Write the full prescription medications ..."
                        required
                      />
                    </>
                  )}
                </Form.Group>
                <Form.Group>
                  <FormLabel htmlFor="sick_leave" className="ekseerForm-label">
                    Prescribe Sick-leave
                  </FormLabel>
                  <div className="d-flex mb-4" id="d-flex">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      id="yes1"
                      name="yesOrNo1"
                      defaultValue="YES"
                      onChange={sick}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      id="no1"
                      name="yesOrNo1"
                      defaultChecked
                      onChange={sick}
                    />
                  </div>
                  {sickLeave && (
                    <FloatingLabel
                      label="How many days?"
                      className="mb-3 ekseerFormInnerLabel">
                      <FormControl
                        id="sick"
                        name="sick_leave"
                        defaultValue={props?.consultation.sick_leave}
                        type="number"
                        placeholder="How many days?"
                        required
                      />
                    </FloatingLabel>
                  )}
                </Form.Group>
                <div className="col-xl-12">
                  <Button type="submit" className="btnPrimary btnLeft">
                    Modify consultation
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {isLoading && (
        <div className="spinner">
          <Image
            src="assets/icons/clock.gif"
            className="mb-5 float"
            id="clockSpinner"
          />
        </div>
      )}
      <Modal show={showSuccessModal} onHide={handleSuccessModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Consultation modified successfully!</Modal.Title>
        </Modal.Header>
        <Image
          src="assets/images/success.png"
          className="mb-5 float"
          id="call"
        />
      </Modal>
    </Container>
  );
};

import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import axios from '../axiosConfig';
import {RootState} from 'state';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Call {
  id: number;
  patient_id: number;
  created_at: string;
  is_new: boolean;
  meeting_id: string;
  is_follow_up: boolean;
  call_type: string;
}

interface Patient {
  id: number;
  full_name: string;
  full_name_arabic: string;
  date_of_birth: string;
}

export const Calls: React.FC = () => {
  const {user, token} = useSelector((state: RootState) => state.login);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL || '';
  const [calls, setCalls] = useState<Call[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(`${baseURL}/validate-token/`, {
          headers: {Authorization: `Token ${token}`},
        });
        if (response.status === 200) {
          setIsTokenValidated(true);
        } else {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      }
    };

    if (token) {
      validateToken();
    } else {
      navigate('/login');
    }
  }, [baseURL, navigate, token]);

  const fetchNewCalls = async (
    token: string | null,
    baseURL: string,
    user: any,
  ) => {
    const response = await axios.get<Call[]>(`${baseURL}/calls/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    const sortedCalls = response.data.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    );

    let filteredCalls = sortedCalls.filter(
      (call) => call.is_new && getDateAndTime(call.created_at) <= 60,
    );

    if (
      user?.doctor_type !== 'adultAndPediatric' &&
      user?.doctor_type !== null
    ) {
      filteredCalls = filteredCalls.filter(
        (call) => call.call_type === user?.doctor_type,
      );
    }

    return filteredCalls;
  };

  const fetchPatients = async (token: string | null, baseURL: string) => {
    const response = await axios.get<Patient[]>(`${baseURL}/users/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  };

  const getDateAndTime = (dateString: string): number => {
    const timeDifference =
      (new Date().getTime() - new Date(dateString).getTime()) / 1000;
    return Math.floor(timeDifference / 60);
  };

  const getAge = (dateString: string) => {
    const birthDate = new Date(dateString);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (birthDate.getDate() > today.getDate()) {
      const temp = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += temp;
      months--;
    }

    if (years === 0) {
      return `${months} months`;
    }

    return `${years} years`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [callsData, patientsData] = await Promise.all([
          fetchNewCalls(token, baseURL, user),
          fetchPatients(token, baseURL),
        ]);
        setCalls(callsData);
        setPatients(patientsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    if (isTokenValidated) {
      fetchData();
    }
  }, [baseURL, token, user, isTokenValidated]);

  const handleAcceptCallClick = async (id: number, meeting_id: string) => {
    try {
      const response = await axios.get(
        `${baseURL}/retrieve-call/${meeting_id}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        },
      );

      const callResponse = response.data;

      if (callResponse && callResponse.is_new) {
        const bodyFormData = new FormData();
        bodyFormData.append('meeting_id', meeting_id);
        bodyFormData.append('doctor_id', user?.id.toString() || '');

        await axios.patch(`${baseURL}/update-call/${id}/`, bodyFormData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        });

        navigate('/video-call', {
          state: {
            currentLanguage: 'en',
            isLoggedIn: true,
            user,
            logInToken: token,
            meeting_id,
            patient_id: callResponse.patient_id,
            call_id: id,
            isFollowUp: callResponse.is_follow_up,
            followUpConsultationId: callResponse.follow_up_consultation_id,
          },
        });

        // Update calls and patients state after accepting a call
        const [callsData, patientsData] = await Promise.all([
          fetchNewCalls(token, baseURL, user),
          fetchPatients(token, baseURL),
        ]);
        setCalls(callsData);
        setPatients(patientsData);
      } else {
        alert("There's a doctor already in this call");
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating call:', error);
    }
  };

  return (
    <Container className="mt-5 h-75">
      <div className="title">
        <h2>Calls</h2>
      </div>
      <Row>
        <Col md={6} sm={12}>
          {isLoading ? (
            <>
              <Skeleton height={100} count={10} />
            </>
          ) : calls.length > 0 ? (
            calls.map((call) => {
              const patient = patients.find(
                (patient) => call.patient_id === patient.id,
              );
              if (!patient) return null;
              return (
                <div
                  key={call.id}
                  className="callsRow justify-content-between align-center d-flex">
                  <div className="d-flex">
                    <h5>
                      <div>
                        <span className="callFullName">
                          {patient.full_name_arabic}
                        </span>
                        {(user?.doctor_type === 'adultAndPediatric' ||
                          user?.doctor_type === null) && (
                          <span
                            style={{
                              fontSize: '12px',
                              position: 'relative',
                              top: '15px',
                              fontStyle: 'italic',
                              color: 'grey',
                              fontWeight: '700',
                              textDecoration: 'underline',
                            }}>
                            <br />
                            {call.call_type.charAt(0).toUpperCase() +
                              call.call_type.slice(1)}{' '}
                            Call
                          </span>
                        )}
                        {call.is_follow_up && (
                          <span
                            style={{
                              fontSize: '12px',
                              position: 'relative',
                              top: '15px',
                              fontWeight: 'bold',
                              color: 'orange',
                            }}>
                            <br />
                            Follow-up Call
                          </span>
                        )}
                        <br />
                        <span
                          style={{
                            fontSize: '12px',
                            color: '#3c3c3c',
                            position: 'relative',
                            top: '15px',
                          }}>
                          Age: {getAge(patient.date_of_birth)}
                        </span>
                      </div>
                      <br />
                      <span style={{position: 'relative', top: '5px'}}>
                        {getDateAndTime(call.created_at) >= 0
                          ? `${getDateAndTime(call.created_at)} minute(s) ago`
                          : 'More than an hour ago'}
                      </span>
                    </h5>
                  </div>
                  <div className="reply-btn">
                    <div
                      className="btn-reply text-uppercase"
                      onClick={() =>
                        handleAcceptCallClick(call.id, call.meeting_id)
                      }>
                      Accept Call
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h5>No upcoming calls yet!</h5>
          )}
        </Col>
        <Col md={6} sm={12} className="scientistCol">
          <Image
            src="assets/images/calls.png"
            className="float fixed-img"
            id="callsImg"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Calls;

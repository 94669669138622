import React, {useState} from 'react';
import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Image,
  FormControl,
  FormLabel,
  Button,
  Modal,
} from 'react-bootstrap';
import axios from '../axiosConfig';
import Spinner from 'components/Spinner';
import {useTranslation} from 'react-i18next';

export const ForgotPassword = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {i18n: translationI18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('email', e.target.email.value);
    axios({
      method: 'post',
      url: `${baseURL}/password_reset/`,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(function (response) {
        setIsLoading(false);
        setShow(true);
      })
      .catch(function (response) {
        setIsLoading(false);
        if (response.response.data.email) {
          setWrongCredentials(true);
        }
      });
  };
  const handleResetPasswordSubmitAr = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('email', e.target.email.value);
    axios({
      method: 'post',
      url: `${baseURL}/password_reset/`,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(function (response) {
        setIsLoading(false);
        setShow(true);
        console.clear();
      })
      .catch(function (response) {
        setIsLoading(false);
        console.clear();
        if (response.response.data.email) {
          setWrongCredentials(true);
        }
      });
  };
  return (
    <>
      <Container className="mt-5 h-profile">
        {!isLoading && (
          <>
            {translationI18n?.language === 'en' ? (
              <>
                {/* /LOGIN PAGE ENGLISH */}
                <Row className="align-center">
                  <Col md={6} sm={12}>
                    <Form
                      style={{textAlign: 'left'}}
                      onSubmit={handleResetPasswordSubmit}>
                      <h2 className="title">Reset your password</h2>
                      {wrongCredentials ? (
                        <p className="error">
                          This email address is not registered!
                        </p>
                      ) : (
                        ''
                      )}
                      <FormLabel htmlFor="email" className="ekseerForm-label">
                        E-mail address
                      </FormLabel>
                      <FloatingLabel
                        label="E-mail address"
                        className="mb-3 ekseerFormInnerLabel">
                        <FormControl
                          type="email"
                          name="email"
                          placeholder="E-mail address"
                        />
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnLeft">
                        Reset password
                      </Button>
                    </Form>
                  </Col>
                  <Col md={6} sm={12} className="scientistCol">
                    <Image
                      src="assets/images/scientist.png"
                      className="float"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {/* /LOGIN PAGE ARABIIC */}
                <Row className="wrapReverse" dir="ltr">
                  <Col md={6} sm={12} className="scientistCol">
                    <Image
                      src="assets/images/scientist.png"
                      className="float"
                    />
                  </Col>
                  <Col md={6} sm={12} className="fixMargin">
                    <Form dir="rtl" onSubmit={handleResetPasswordSubmitAr}>
                      <h2 className="title">استعادة كلمة المرور</h2>
                      {wrongCredentials ? (
                        <p className="error">البريد الالكتروني غير مسجل</p>
                      ) : (
                        ''
                      )}
                      <FormLabel htmlFor="email">البريد الالكتروني</FormLabel>
                      <FloatingLabel
                        label="البريد الالكتروني"
                        className="mb-3 innerFormLabelAr">
                        <FormControl
                          type="email"
                          name="email"
                          placeholder=">البريد الالكتروني"
                        />
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnRight">
                        استعادة كلمة المرور
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '14px'}}>
                  {translationI18n?.language === 'en'
                    ? 'E-mail sent!'
                    : 'تم ارسال البريد الالكتروني'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>
                  {translationI18n?.language === 'en'
                    ? 'Kindly check your e-mail to reset your password, Thanks.'
                    : 'يرجى الذهاب الى بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك، شكرًا لك.'}
                </h5>
              </Modal.Body>
            </Modal>
          </>
        )}
        {isLoading && <Spinner />}
      </Container>
    </>
  );
};

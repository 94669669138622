import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import axios from '../axiosConfig';
import { LoginResponse, User } from 'slices/types';

type loginState = {
  user: User | null;
  token: string | null;
  error: string | null;
  loading: boolean;
  isLogged: boolean;
  expiry: string;
};

const initialState: loginState = {
  user: null,
  error: null,
  loading: false,
  isLogged: false,
  token: '',
  expiry: '',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
      state.expiry = '';
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.loading = false;
      state.error = null;
      state.isLogged = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.expiry = action.payload.expiry;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    logoutSuccess() {
      return { ...initialState };
    },
    updateProfileStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateProfileSuccess(
      state,
      action: PayloadAction<{ mobile_number: string; email: string }>,
    ) {
      state.loading = false;
      state.error = null;
      if (state.user) {
        state.user.mobile_number = action.payload.mobile_number;
        state.user.email = action.payload.email;
      }
    },
    updateProfileFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    isAvailableUser(
      state,
      action: PayloadAction<{ is_actived: boolean }>,
    ) {
      state.loading = false;
      state.error = null;
      if (state.user) {
        state.user.is_actived = action.payload.is_actived;
      }
    },
    setIsPaymentConsultationEnabled(
      state,
      action: PayloadAction<{ is_payment_enabled: boolean }>,
    ) {
      state.loading = false;
      state.error = null;
      if (state.user) {
        state.user.is_payment_enabled = action.payload.is_payment_enabled;
      }
    },
    setIsDoctorVerified(
      state,
      action: PayloadAction<{ is_verified: boolean }>,
    ) {
      state.loading = false;
      state.error = null;
      if (state.user) {
        state.user.is_verified = action.payload.is_verified;
      }
    },
    updateUserStateSuccess(state, action: PayloadAction<{ user: User }>) {
      state.loading = false;
      state.error = null;
      state.user = action.payload.user;
    },
  },
});

export const {
  loginSuccess,
  loginFailed,
  loginStart,
  logoutSuccess,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailed,
  isAvailableUser,
  setIsPaymentConsultationEnabled,
  setIsDoctorVerified,
  updateUserStateSuccess,
} = loginSlice.actions;

export default loginSlice.reducer;

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const login =
  (iqama_number: string, password: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(loginStart());
        const response = await axios.post(`${baseURL}/login/`, {
          iqama_number,
          password,
        });

        if (response.status === 200) {
          dispatch(loginSuccess(response.data));
        } else {
          if (response.data && response.data.error) {
            dispatch(loginFailed('An error occurred.'));
            console.clear();
          }
        }
      } catch (error) {
        dispatch(loginFailed('An error occurred.'));
        console.clear();
      }
    };

export const logout =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        await axios({
          method: 'post',
          url: `${baseURL}/logout-all/`,
          headers: { Authorization: `Token ${token}` },
        });
        dispatch(logoutSuccess());
      } catch (error) {
        dispatch(logoutSuccess());
      }
    };

export const updateUserState = (iqama_number: string, token: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/retrieve-user/${iqama_number}/`,
      headers: { Authorization: `Token ${token}` },
    });

    if (response.status === 200) {
      dispatch(updateUserStateSuccess({ user: response.data }));
    } else {
      dispatch(loginFailed('An error occurred.'));
    }
  } catch (error) {
    dispatch(loginFailed('An error occurred.'));
  }
};

import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from '../axiosConfig';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'state';
import Spinner from 'components/Spinner';
import {initiatePaymentFailed, initiatePaymentSuccess} from 'state/payment';

const PaymentResult = () => {
  const {user, token} = useSelector((state: RootState) => state.login);
  const {orderId: storedOrderId} = useSelector(
    (state: RootState) => state.payment,
  );
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const nav = useNavigate();
  const queryString = useLocation().search;
  const urlParams = new URLSearchParams(queryString);
  const orderId = urlParams.get('orderId') || storedOrderId; // Use orderId from params or fallback to storedOrderId
  const [isLoading, setIsLoading] = useState(true);

  const createPayment = useCallback(
    async (orderId, response_data) => {
      try {
        const headers = {Authorization: `Token ${token}`};
        const requestData = {orderId, response_data};
        const response = await axios.post(
          `${baseURL}/create-payment/`,
          requestData,
          {headers},
        );
        return response.data;
      } catch (error) {
        console.error('Error creating payment:', error);
        return null;
      }
    },
    [baseURL, token],
  );

  const handleReturnToHomepage = useCallback(() => {
    nav('/');
  }, [nav]);

  const handlePaymentFailure = useCallback(() => {
    dispatch(initiatePaymentFailed('Payment failed.')); // Dispatch failure action to update Redux state
    handleReturnToHomepage();
  }, [dispatch]);

  const handleStartVideoCall = useCallback(
    (paymentId) => {
      nav('/video-call', {
        state: {paymentId, user, logInToken: token},
      });
    },
    [nav, user, token],
  );

  const checkOrderStatus = useCallback(async () => {
    if (!orderId) {
      handlePaymentFailure();
      return;
    }

    try {
      const headers = {Authorization: `Token ${token}`};
      const response = await axios.get(
        `${baseURL}/get-order-payment/${orderId}/`,
        {headers},
      );

      if (response.status === 201) {
        const createPaymentResponse = await createPayment(
          orderId,
          response.data.payment_data,
        );

        const paymentData = response.data?.payment_data;
        const transactions = paymentData?.result?.transactions;
        const orderStatus = transactions?.[0]?.status;
        const userNationality = user?.nationality;
        let vat = 0;
        let amountWithVat = transactions[0]?.amount;

        if (userNationality && userNationality.toUpperCase() !== 'SA') {
          vat = amountWithVat / 1.15;
        }
        if (orderStatus === 'SUCCESS') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'paymentSuccess',
            result: {
              value: transactions[0]?.amount,
              currency: transactions[0]?.currency,
              order_id: orderId,
              vat: vat,
            },
          });
          
          dispatch(
            initiatePaymentSuccess({
              orderId: orderId,
            }),
          );

          handleStartVideoCall(createPaymentResponse.id); // Navigate immediately to video call
        } else {
          handlePaymentFailure(); // Handle failure immediately
        }
      } else {
        handlePaymentFailure(); // Handle failure immediately
      }
    } catch (error) {
      handlePaymentFailure(); // Handle failure immediately on error
    } finally {
      setIsLoading(false); // Set loading to false after check
    }
  }, [
    baseURL,
    orderId,
    token,
    createPayment,
    user,
    dispatch,
    handlePaymentFailure,
    handleStartVideoCall,
  ]);

  useEffect(() => {
    checkOrderStatus();
  }, [checkOrderStatus]);

  return <>{isLoading && <Spinner />}</>;
};

export default PaymentResult;

import React, {useState} from 'react';
import {
  Navbar,
  Container,
  Nav,
  Image,
  Button,
  NavDropdown,
} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
//@ts-ignore
import customTogglerIcon from '../assets/icons/toggler.png';
//@ts-ignore
import homeIcon from '../assets/icons/home.png';
//@ts-ignore
import registerLoginIcon from '../assets/icons/customer.png';
//@ts-ignore
import profileIcon from '../assets/icons/profile-user.png';
//@ts-ignore;
import consultationIcon from '../assets/icons/to-do-list.png';
//@ts-ignore
import arIcon from '../assets/icons/saudi-arabia.png';
//@ts-ignore
import enIcon from '../assets/icons/united-states.png';
//@ts-ignore
import aboutIcon from '../assets/icons/id-card.png';
//@ts-ignore
import logoutIcon from '../assets/icons/logout.png';
//@ts-ignore
import contactIcon from '../assets/icons/customer-service.png';
//@ts-ignore
import doctorIcon from '../assets/icons/doctor.png';
//@ts-ignore
import doctorsIcon from '../assets/icons/our-doctors.png';
//@ts-ignore
import privacyIcon from '../assets/icons/privacy-policy.png';
//@ts-ignore
import faqsIcon from '../assets/icons/question.png';
//@ts-ignore
import logo from '../assets/mclogo.png';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'state';
import {logout} from 'state/login';
import {useTranslation} from 'react-i18next';

const SharedNavbar = () => {
  const {isLogged, user, token} = useSelector(
    (state: RootState) => state.login,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t, i18n: translationI18n} = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isDoctorsPortalClicked, setIsDoctorsPortalClicked] = useState(false);
  const location = useLocation();
  const doctorsPortal = location.state?.doctorsPortal;

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const changeLanguage = (lang: string) => {
    translationI18n.changeLanguage(lang);
    localStorage.setItem('locale', lang);
    setExpanded(false);
  };

  const handleDoctorsPortalClick = () => {
    setIsDoctorsPortalClicked(true);
    navigate('/login', {state: {doctorsPortal: true}});
    setExpanded(false);
  };

  return (
    <Navbar
      expanded={expanded}
      onToggle={handleToggle}
      expand="lg"
      className={expanded ? 'navbar-expanded' : 'navbar-collapsed'}>
      <Container>
        <div className="navbar-header">
          <Navbar.Brand
            as={Link}
            to="/"
            style={{
              marginRight: translationI18n.language === 'ar' ? '-15px' : '',
            }}>
            <Image src={logo} className="our-logo" />
          </Navbar.Brand>
          <Button
            className="navbar-toggler"
            type="button"
            onClick={handleToggle}
            aria-label="Toggle navigation">
            <Image
              src={customTogglerIcon}
              alt="Custom Toggler Icon"
              className={
                translationI18n.language === 'en'
                  ? 'togglerIcon'
                  : 'togglerIconAr'
              }
            />
          </Button>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-center" style={{width: '100%'}}>
            <Nav.Link
              as={Link}
              to="/"
              onClick={() => {
                setExpanded(false);
                setIsDoctorsPortalClicked(false);
              }}>
              <Image src={homeIcon} className="nav-link-img" />
              {t('layout.navbar.home')}
            </Nav.Link>
            {!isLogged && !token && (
              <Nav.Link
                as={Link}
                to="/login"
                onClick={() => {
                  setExpanded(false);
                  setIsDoctorsPortalClicked(false);
                }}>
                <Image src={registerLoginIcon} className="nav-link-img" />
                {t('layout.navbar.registerLogin')}
              </Nav.Link>
            )}
            {isLogged && token && (
              <NavDropdown
                className={
                  translationI18n.language === 'en'
                    ? 'dropdown-menu-custom'
                    : 'dropdown-menu-customAR'
                }
                title={
                  <>
                    <Image src={profileIcon} className="nav-link-img" />
                    {t('layout.navbar.profile')}
                  </>
                }
                id="basic-nav-dropdown">
                <NavDropdown.Item
                  className="nav-link mx-2"
                  as={Link}
                  to="/consultations"
                  onClick={() => {
                    setExpanded(false);
                    setIsDoctorsPortalClicked(false);
                  }}>
                  <Image src={consultationIcon} className="nav-link-img" />
                  {t('layout.navbar.myConsultations')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="nav-link mx-2"
                  as={Link}
                  to="/profile"
                  onClick={() => {
                    setExpanded(false);
                    setIsDoctorsPortalClicked(false);
                  }}>
                  <Image src={profileIcon} className="nav-link-img" />
                  {t('layout.navbar.profile')}
                </NavDropdown.Item>
              </NavDropdown>
            )}
            <NavDropdown
              title={
                <>
                  <Image src={aboutIcon} className="nav-link-img" />
                  {t('layout.navbar.aboutUs')}
                </>
              }
              className={
                translationI18n.language === 'en'
                  ? 'dropdown-menu-custom'
                  : 'dropdown-menu-customAR'
              }
              id="basic-nav-dropdown">
              <NavDropdown.Item
                className="nav-link mx-2"
                as={Link}
                to="/our-doctors"
                onClick={() => {
                  setExpanded(false);
                  setIsDoctorsPortalClicked(false);
                }}>
                <Image src={doctorsIcon} className="nav-link-img" />
                {t('layout.navbar.ourDoctors')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link mx-2"
                as={Link}
                to="/about"
                onClick={() => {
                  setExpanded(false);
                  setIsDoctorsPortalClicked(false);
                }}>
                <Image src={aboutIcon} className="nav-link-img" />
                {t('layout.navbar.aboutUs')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link mx-2"
                as={Link}
                to="/faqs"
                onClick={() => {
                  setExpanded(false);
                  setIsDoctorsPortalClicked(false);
                }}>
                <Image src={faqsIcon} />
                {t('layout.navbar.faqs')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link mx-2"
                as={Link}
                to="/privacy-policy"
                onClick={() => {
                  setExpanded(false);
                  setIsDoctorsPortalClicked(false);
                }}>
                <Image src={privacyIcon} className="nav-link-img" />
                {t('layout.navbar.privacyPolicy')}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/contact"
              onClick={() => {
                setExpanded(false);
                setIsDoctorsPortalClicked(false);
              }}>
              <Image src={contactIcon} className="nav-link-img" />
              {t('layout.navbar.contactUs')}
            </Nav.Link>
            {isLogged && token && (
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => dispatch(logout(token))}>
                <Image src={logoutIcon} className="nav-link-img" />
                {t('layout.navbar.logout')}
              </Nav.Link>
            )}
          </Nav>
          <Nav className="justify-content-end">
            {!isLogged &&
              token === '' &&
              (!user?.is_doctor || user === null) && (
                <Nav.Link
                  onClick={handleDoctorsPortalClick}
                  className={
                    translationI18n.language === 'en'
                      ? 'customLink'
                      : 'customLinkAr'
                  }>
                  <Image src={doctorIcon} className="nav-link-img" />
                  {t('layout.navbar.doctorsPortal')}
                </Nav.Link>
              )}
            {!doctorsPortal && !user?.is_doctor && !isDoctorsPortalClicked && (
              <>
                {translationI18n.language === 'en' ? (
                  <Nav.Link
                    id="arabicWord"
                    onClick={() => changeLanguage('ar')}
                    style={{ fontFamily: "'IBM Plex Sans Arabic', sans-serif" }}>
                    <Image src={arIcon} className="nav-link-img" />
                    العربية
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    onClick={() => changeLanguage('en')}
                    style={{
                      fontFamily: "'Helvetica-Neue-Font-Normal', sans-serif",
                    }}>
                    En
                    <Image src={enIcon} className="nav-link-img" />
                  </Nav.Link>
                )}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SharedNavbar;

import React, {useState, useEffect, useCallback} from 'react';
import {Container} from 'react-bootstrap';
import Animate from './Animate';
import NewRegister from 'pages/auth/NewRegister';
//@ts-ignore
import doctorIcon from '../assets/icons/dicon.png';
//@ts-ignore
import patientIcon from '../assets/icons/picon.png';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/Spinner';
import OtpModal from 'components/OtpModal';
import {toast} from 'react-toastify';
import moment from 'moment';
import axios from '../axiosConfig';
import {useSelector} from 'react-redux';
import {RootState} from 'state';

const PreRegister: React.FC = () => {
  const {token} = useSelector((state: RootState) => state.login);
  const {state} = useLocation();
  const {t, i18n} = useTranslation();
  const [selectedOption, setSelectedOption] = useState<
    'doctor' | 'patient' | ''
  >('');
  const [loading, setLoading] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [idNumber, setIdNumber] = useState('');
  const [dob, setDob] = useState('');
  const [yakeenData, setYakeenData] = useState<any>(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL || '';
  const navigate = useNavigate();

  const handleOptionSelect = (option: 'doctor' | 'patient') => {
    setSelectedOption(option);
    navigate('/pre-register/#fillForm');
  };

  useEffect(() => {
    if (state?.doctorsPortal !== undefined) {
      setSelectedOption(state.doctorsPortal ? 'doctor' : 'patient');
    }
  }, [state]);

  useEffect(() => {
    if (window.innerWidth <= 768 && selectedOption) {
      document.querySelector('#fillForm')?.scrollIntoView({behavior: 'smooth'});
    }
  }, [selectedOption]);
  const sendOtp = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await axios.post(`${baseURL}/send-otp/`, {
        customer_id: idNumber,
        operator_id: idNumber,
        reason: 'To execute a service',
        language: i18n.language,
        param1: 'Medical-Consultation',
      });

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data.response, 'application/xml');

      const faultElement = xmlDoc.querySelector('Fault');
      if (faultElement) {
        const errorCode =
          faultElement.querySelector('ErrorCode')?.textContent?.trim() ||
          'Unknown';
        const errorMessage =
          faultElement.querySelector('ErrorMessage')?.textContent ||
          'Unknown error';
        return {status: false, errorCode, errorMessage};
      }

      const status = xmlDoc.querySelector('status')?.textContent;
      if (status === '0') {
        const verificationCode =
          xmlDoc.querySelector('verificationCode')?.textContent || null;
        console.log(verificationCode);
        setVerificationCode(verificationCode);
        toast.success(t('pages.newRegister.otpSmsSentSuccessfully'), {
          position: i18n.language === 'ar' ? 'top-left' : 'top-right',
        });
        setIsOtpModalOpen(true);
        return {status: true, errorCode: null};
      } else {
        return {status: false, errorCode: 'Unexpected status'};
      }
    } catch (error) {
      return {status: false, errorCode: 'Request failed'};
    } finally {
      setLoading(false);
    }
  }, [idNumber, baseURL, t, i18n.language]);

  const onYakeenRequest = useCallback(async () => {
    const idOrNin = idNumber.startsWith('1')
      ? 'nin'
      : idNumber.startsWith('2')
      ? 'iqama'
      : '';
    if (!idOrNin) {
      console.error(t('pages.newRegister.invalidIdNumber'));
      return;
    }

    setLoading(true);
    try {
      const {data} = await axios.get(`${baseURL}/yakeen-login/`, {
        params: {
          id: idNumber,
          iqamaOrNin: idOrNin,
          birthDateG: moment(dob).locale('en').format('YYYY-MM'),
        },
      });
      setYakeenData(data);
      return data;
    } catch (error) {
      toast.error(t('pages.newRegister.yakeenRequestFailed'), {
        position: i18n.language === 'ar' ? 'top-left' : 'top-right',
      });
    } finally {
      setLoading(false);
    }
  }, [idNumber, dob, baseURL, t, i18n.language]);

  const verifyElmUser = useCallback(
    async (iqama_number: number, userData: any) => {
      try {
        const response = await axios.patch(
          `${baseURL}/elm-verify-user/${iqama_number}/`,
          {...userData, is_elm_verified: true},
          {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );
        return response.data;
      } catch (error) {
        toast.error(t('pages.newRegister.elmVerifiedFailed'), {
          position: i18n.language === 'ar' ? 'top-left' : 'top-right',
        });
        console.error('Error verifying user:', error);
        throw error;
      }
    },
    [baseURL, token, t, i18n.language],
  );

  return (
    <Container>
      <Animate>
        {loading ? (
          <Spinner />
        ) : (
          <div className="signup-container">
            <div
              className="signup-left"
              style={{textAlign: i18n.language === 'ar' ? 'right' : 'left'}}>
              <h2>{t('pages.newRegister.signUpTo')}</h2>
              <div className="homeLogo text-center">
                <h1>
                  {t('pages.home.medicalCloud')}
                  <br />
                  <span id="homeCopyRights">{t('pages.home.cloud')}</span>
                </h1>
                <p>{t('pages.home.emergencyConsultant')}</p>
              </div>
              <div className="options">
                <div
                  className={`option-card ${
                    selectedOption === 'patient' ? 'selected' : ''
                  }`}
                  onClick={() =>
                    state?.shouldBeElmVerified
                      ? null
                      : !state?.doctorsPortal && handleOptionSelect('patient')
                  }
                  style={{opacity: state?.doctorsPortal ? 0.4 : 1}}>
                  <img
                    src={patientIcon}
                    alt="remote worker"
                    className="avatar"
                  />
                  <div>
                    <p className="option-title">
                      {t('pages.newRegister.patientOptionTitle')}
                    </p>
                    <p className="option-subtitle">
                      {t('pages.newRegister.patientOptionSubtitle')}
                    </p>
                  </div>
                  {selectedOption === 'patient' && (
                    <span
                      className="checkmark"
                      style={{
                        marginInlineStart: i18n.language === 'ar' ? 'auto' : '',
                        marginLeft: i18n.language === 'en' ? 'auto' : '',
                      }}>
                      ✔
                    </span>
                  )}
                </div>
                <div
                  className={`option-card ${
                    selectedOption === 'doctor' ? 'selected' : ''
                  }`}
                  onClick={() =>
                    state?.shouldBeElmVerified
                      ? null
                      : state?.doctorsPortal !== false &&
                        handleOptionSelect('doctor')
                  }
                  style={{opacity: state?.doctorsPortal === false ? 0.4 : 1}}>
                  <img
                    src={doctorIcon}
                    alt="hire employees"
                    className="avatar"
                  />
                  <div>
                    <p className="option-title">
                      {t('pages.newRegister.doctorOptionTitle')}
                    </p>
                    <p className="option-subtitle">
                      {t('pages.newRegister.doctorOptionSubtitle')}
                    </p>
                  </div>
                  {selectedOption === 'doctor' && (
                    <span
                      className="checkmark"
                      style={{
                        marginInlineStart: i18n.language === 'ar' ? 'auto' : '',
                        marginLeft: i18n.language === 'en' ? 'auto' : '',
                      }}>
                      ✔
                    </span>
                  )}
                </div>
              </div>
              <p className="signin">
                {t('pages.newRegister.alreadyHaveAccount')}{' '}
                <span
                  className="signin-link"
                  onClick={() =>
                    navigate('/login', {
                      state: {doctorsPortal: state?.doctorsPortal},
                    })
                  }>
                  {t('pages.newRegister.signIn')}
                </span>
              </p>
            </div>
            <div className="signup-right">
              <NewRegister
                selectedOption={selectedOption}
                setIdNumber={setIdNumber}
                setDob={setDob}
                sendOtp={sendOtp}
                onYakeenRequest={onYakeenRequest}
                shouldBeElmVerified={state?.shouldBeElmVerified}
              />
            </div>
          </div>
        )}
      </Animate>
      {isOtpModalOpen && (
        <OtpModal
          isOtpModalOpen={isOtpModalOpen}
          onHide={() => setIsOtpModalOpen(false)}
          verificationCode={verificationCode}
          sendOtp={sendOtp}
          yakeenData={yakeenData}
          idNumber={idNumber}
          dob={dob}
          selectedOption={selectedOption}
          shouldBeElmVerified={state?.shouldBeElmVerified}
          verifyElmUser={verifyElmUser}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </Container>
  );
};

export default PreRegister;

import {MeetingProvider} from '@videosdk.live/react-sdk';
import {useEffect, useState, useMemo} from 'react';
import {MeetingAppProvider} from './MeetingAppContextDef';
import {MeetingContainer} from './meeting/MeetingContainer';
import {LeaveScreen} from './components/screens/LeaveScreen';
import {JoiningScreen} from './components/screens/JoiningScreen';
import {useLocation} from 'react-router-dom';

export const VideoCall = () => {
  const {state} = useLocation();
  const [token, setToken] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(false);
  const [selectedMic, setSelectedMic] = useState({id: null});
  const [selectedWebcam, setSelectedWebcam] = useState({id: null});
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const isMobile = useMemo(
    () => window.matchMedia('only screen and (max-width: 768px)').matches,
    [],
  );

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => 'Are you sure you want to exit?';
    }
  }, [isMobile]);

  return (
    <div id="zIndex">
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}>
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: state?.user.full_name,
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange
            joinWithoutUserInteraction>
            <MeetingContainer
              onMeetingLeave={() => {
                setToken('');
                setMeetingId('');
                setParticipantName('');
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              setSelectWebcamDeviceId={setSelectedWebcam}
              setSelectMicDeviceId={setSelectedMic}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              isFollowUp={state?.isFollowUp}
              followUpConsultationId={state?.followUpConsultationId}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : (
        <JoiningScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => setMeetingStarted(true)}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
        />
      )}
    </div>
  );
};

import React, {useEffect, useRef, useState} from 'react';
import useIsTab from '../../hooks/useIsTab';
import useIsMobile from '../../hooks/useIsMobile';
import {useTranslation} from 'react-i18next'; // Import useTranslation

const WaitingToJoinScreen = () => {
  const {i18n} = useTranslation(); // Get the i18n instance for language detection

  const waitingMessages = {
    en: [
      {index: 0, text: 'Joining...'},
      {index: 1, text: 'Almost there...'},
    ],
    ar: [
      {index: 0, text: 'جاري الانضمام...'}, // Joining...
      {index: 1, text: 'اقتربنا...'}, // Almost there...
    ],
  };

  const [message, setMessage] = useState(waitingMessages[i18n.language][0]); // Initialize based on the current language

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMessage((s) =>
        s.index === waitingMessages[i18n.language].length - 1
          ? s
          : waitingMessages[i18n.language][s.index + 1],
      );
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [i18n.language]); // Re-run effect if the language changes

  const isTab = useIsTab();
  const isMobile = useIsMobile();

  return (
    <div
      className="bg-gray-800"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
      <div className="flex flex-col">
        <div
          style={{
            height: isTab ? 200 : isMobile ? 200 : 250,
            width: isTab ? 200 : isMobile ? 200 : 250,
          }}>
          <img
            src="assets/icons/wait.png"
            className="float waitingImg"
            alt="..."
          />
        </div>
        <h1 className="text-white text-center font-bold mt-5 text-xl">
          {message.text}
        </h1>
      </div>
    </div>
  );
};

export default WaitingToJoinScreen;

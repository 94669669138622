import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export const RedirectComponent = () => {
  const history = useNavigate();

  const decodeUrl = (encodedUrl) => {
    return atob(encodedUrl);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const encryptedUrl = searchParams.get('u');

  const decodedUrl = decodeUrl(encryptedUrl);

  useEffect(() => {
    if (decodedUrl) {
      window.location.href = decodedUrl;
      history('/');
    } else {
      history('/');
    }
  }, [decodedUrl, history]);

  return null;
};

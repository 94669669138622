import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loginReducer from './login';
import paymentReducer from './payment';

const rootReducer = combineReducers({
  login: loginReducer,
  payment: paymentReducer, // add the payment reducer

});

// Persist redux
const persistConfig = {
  key: 'root',
  version: 1.03,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      serializableCheck: false,
    },
  }),
});

let persistor = persistStore(store);

// Types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

const storeConfig = {
  store,
  persistor,
};

export default storeConfig;

import React from 'react';
import { Image } from 'react-bootstrap';
import loadingIcon from '../assets/icons/clock.gif';

const Spinner: React.FC = () => {
  return (
    <div className="spinner">
      <Image src={loadingIcon} className="mb-5 float" id="clockSpinner" alt="Loading..." />
    </div>
  );
};

export default Spinner;

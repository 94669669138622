// src/pages/NotFoundPage.tsx
import React from 'react';
import {useTranslation} from 'react-i18next';
import '../styles/NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
  const {t} = useTranslation();

  const bubbles: JSX.Element[] = [];
  for (let x = 1; x <= 5; x++) {
    bubbles.push(<div key={x} className="bubble"></div>);
  }

  return (
    <div className="notfound">
      <div className="notfound-bg">{bubbles}</div>
      <div className="main">
        <h1>404</h1>
        <p>
          {t('pages.404page.main.message')}
          <br />
          {t('pages.404page.main.submessage')}
        </p>
        <button type="button" onClick={() => (window.location.href = '/')}>
          {t('pages.404page.main.button')}
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;

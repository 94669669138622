import React, {useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormCheck,
  Button,
  FormControl,
  FloatingLabel,
  FormLabel,
  Image,
  FormGroup,
} from 'react-bootstrap';
import Animate from 'components/Animate';
import {useDispatch, useSelector} from 'react-redux';
import {login, loginFailed} from 'state/login';
//@ts-ignore
import patientImage from '../../assets/images/login.jpeg';
import {useTranslation} from 'react-i18next';
import i18n from 'locales/i18n';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {RootState} from 'state';
import Spinner from 'components/Spinner';

const Login = () => {
  const {error, loading} = useSelector((state: RootState) => state.login);
  const {t} = useTranslation();
  const {state} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const doctorsPortal = state && state?.doctorsPortal;
  const [iqamaNumber, setIqamaNumber] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [pageCurrentLang, setPageCurrentLang] = useState('en');

  const handleDoctorsPortalClick = () => {
    navigate('/pre-register', {state: {doctorsPortal: true}});
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch(login(iqamaNumber, password));
  }

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe');
    if (rememberMeValue === 'true') {
      setRememberMe(true);

      const storedIqamaNumber = localStorage.getItem('iqamaNumber');
      const storedPassword = localStorage.getItem('password');

      if (storedIqamaNumber) {
        setIqamaNumber(storedIqamaNumber);
      }

      if (storedPassword) {
        setPassword(storedPassword);
      }
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('iqamaNumber', iqamaNumber);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('iqamaNumber');
      localStorage.removeItem('password');
    }
  }, [rememberMe, iqamaNumber, password]);

  useEffect(() => {
    const handleLanguageChange = () => {
      const currentLanguage = i18n.language;
      setPageCurrentLang(currentLanguage);
    };

    i18n.on('languageChanged', handleLanguageChange);
    handleLanguageChange();

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    // Cleanup function to reset error when leaving the page
    return () => {
      dispatch(loginFailed(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (doctorsPortal) {
      i18n.changeLanguage('en');
    }
  }, [doctorsPortal]);

  return (
    <main>
      {!loading && (
        <Animate>
          <section className="d-flex align-items-center my-2 mt-lg-4 mb-lg-3">
            <Container className="mt-5 h-50">
              <Row className="align-items-center justify-content-between">
                <Col md={6} sm={12}>
                  <Form onSubmit={handleSubmit}>
                    <h2 className="title">
                      {!doctorsPortal && !doctorsPortal
                        ? t('pages.login.title')
                        : t('pages.login.titleDoctor')}
                    </h2>
                    <FormGroup>
                      {error && (
                        <div className="alert alert-danger" role="alert">
                          {t('pages.login.loginFailed')}
                        </div>
                      )}
                      <FormLabel htmlFor="iqama_number">
                        {t('pages.login.idIqamaNumber')}
                      </FormLabel>
                      <FloatingLabel
                        label={`${t('pages.login.idIqamaNumber')}`}
                        className={
                          pageCurrentLang === 'en'
                            ? 'mb-3 innerFormLabel'
                            : 'mb-3 innerFormLabelAr'
                        }>
                        <FormControl
                          type="text"
                          name="iqama_number"
                          placeholder="Identification or Iqama number"
                          value={iqamaNumber}
                          inputMode="numeric"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,10}$/.test(value)) {
                              setIqamaNumber(value);
                            }
                          }}
                          required
                        />
                      </FloatingLabel>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="password">
                        {t('pages.login.password')}
                      </FormLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label={`${t('pages.login.password')}`}
                        className={
                          pageCurrentLang === 'en'
                            ? 'mb-3 innerFormLabel password-input-container'
                            : 'mb-3 innerFormLabelAr password-input-container'
                        }>
                        <FormControl
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <span
                          className={
                            pageCurrentLang === 'en'
                              ? 'password-toggle-btn'
                              : 'password-toggle-btnAr'
                          }
                          onClick={togglePasswordVisibility}>
                          {passwordVisible
                            ? t('pages.login.hide')
                            : t('pages.login.show')}
                        </span>
                      </FloatingLabel>
                    </FormGroup>
                    <FormGroup className="rememberMeGroup">
                      <Form.Check type="checkbox">
                        <FormCheck.Input
                          data-testid="login-rememberMe"
                          id="defaultCheck5"
                          className="me-2"
                          checked={rememberMe}
                          onChange={(e) => {
                            const {checked} = e.target;

                            setRememberMe(checked);

                            localStorage.setItem(
                              'rememberMe',

                              checked ? 'true' : 'false',
                            );
                          }}
                        />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0">
                          {t('pages.login.rememberMe')}
                        </FormCheck.Label>
                      </Form.Check>
                    </FormGroup>
                    <Button type="submit" className="btnPrimary">
                      {t('pages.login.login')}
                    </Button>
                    <Row className="justify-content-between">
                      <Col sm={6} xs={7}>
                        <p className="newUserRegister">
                          {!doctorsPortal && !doctorsPortal ? (
                            <>
                              {t('pages.login.newUser')}{' '}
                              <span>
                                {' '}
                                <Link to="/pre-register">
                                  <span>{t('pages.login.registerHere')}</span>
                                </Link>
                              </span>
                            </>
                          ) : (
                            <>
                              {t('pages.login.newDoctor')}{' '}
                              <span>
                                {' '}
                                <span onClick={handleDoctorsPortalClick}>
                                  <span>
                                    {t('pages.login.registerDoctorHere')}
                                  </span>
                                </span>
                              </span>
                            </>
                          )}
                        </p>
                      </Col>
                      <Col sm={6} xs={5}>
                        <Link
                          to={'/reset-password'}
                          className="d-flex justify-content-end newUserRegister">
                          <span>{t('pages.login.forgotPassword')}</span>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col
                  md={6}
                  sm={12}
                  className="scientistCol d-flex justify-content-end float">
                  <Image
                    style={{borderRadius:'24px'}}
                    src={patientImage}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </Animate>
      )}
      {loading && <Spinner />}
    </main>
  );
};

export default Login;

import React, {useState, useEffect, useRef, createRef, useMemo} from 'react';
import {Constants, useMeeting, usePubSub} from '@videosdk.live/react-sdk';
import {BottomBar} from './components/BottomBar';
import {SidebarConatiner} from '../components/sidebar/SidebarContainer';
import MemorizedParticipantView from './components/ParticipantView';
import {PresenterView} from '../components/PresenterView';
import {nameTructed, trimSnackBarText} from '../utils/helper';
import WaitingToJoinScreen from '../components/screens/WaitingToJoinScreen';
import ConfirmBox from '../components/ConfirmBox';
import useIsMobile from '../hooks/useIsMobile';
import useIsTab from '../hooks/useIsTab';
import {useMediaQuery} from 'react-responsive';
import {toast} from 'react-toastify';
import {useMeetingAppContext} from '../MeetingAppContextDef';
import useMediaStream from '../hooks/useMediaStream';
import {useLocation} from 'react-router-dom';
import {
  FloatingLabel,
  Form,
  Image,
  FormControl,
  FormLabel,
  Button,
  Modal,
  FormGroup,
  Col,
  Row,
} from 'react-bootstrap';
import axios from '../axiosConfig';
import {SelectedPatient} from '../components/SelectedPatient';

export function MeetingContainer({
  onMeetingLeave,
  setIsMeetingLeft,
  selectedMic,
  selectedWebcam,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  selectMicDeviceId,
  setSelectMicDeviceId,
  micEnabled,
  webcamEnabled,
  isFollowUp,
  followUpConsultationId,
}) {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {useRaisedHandParticipants} = useMeetingAppContext();
  const {getVideoTrack} = useMediaStream();
  const {state} = useLocation();
  const [show, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [chiefComplaint, setChiefComplaint] = useState('');
  const [historyOfIllness, setHistoryOfIllness] = useState('');
  const [reviewOfSystems, setReviewOfSystems] = useState('');
  const [examination, setExamination] = useState('');
  const [assessment, setAssessment] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [medicationField, setMedicationField] = useState('');
  const [orderLabs, setOrderLabs] = useState('');
  const [sickLeaveField, setSickLeaveField] = useState(null);

  const [showUpdateConsultation, setShowUpdateConsultation] = useState(false);
  const [filledFollowUp, setFilledFollowUp] = useState('');
  const [followUp, setFollowUp] = useState(filledFollowUp || '');

  const handleFollowUpChange = (event) => {
    setFollowUp(event.target.value);
  };
  const [updateConsultationData, setUpdateConsultationData] = useState({
    chief_complaint: '',
    history_of_illness: '',
    review_of_systems: '',
    examination: '',
    assessment: '',
    medication: '',
    order_labs: '',
    sick_leave: '',
  });
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [showPatientProfile, setShowPatientProfile] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseUpdateConsultation = () => setShowUpdateConsultation(false);
  const handleCloseFollowUp = () => setShowFollowUp(false);
  const handleClosePatientProfile = () => setShowPatientProfile(false);
  const handleErrorModalClose = () => {
    setErrorMessage('');
    setShowErrorModal(false);
  };
  const [patients, setPatients] = useState([]);
  const [followUpData, setFollowUpData] = useState([]);
  const [medication, setMedication] = useState(false);
  const [showLabs, setShowLabs] = useState(false);
  const [sickLeave, setSickLeave] = useState(false);
  const [isConsultationCreated, setIsConsultationCreated] = useState(false);
  const [isFollowUpCreated, setIsFollowUpCreated] = useState(false);
  const bottomBarHeight = 60;

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] =
    useState(null);
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [meetingError, setMeetingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mMeetingRef = useRef();
  const containerRef = createRef();
  const containerHeightRef = useRef();
  const containerWidthRef = useRef();

  useEffect(() => {
    containerHeightRef.current = containerHeight;
    containerWidthRef.current = containerWidth;
  }, [containerHeight, containerWidth]);

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isLGDesktop = useMediaQuery({minWidth: 1024, maxWidth: 1439});
  const isXLDesktop = useMediaQuery({minWidth: 1440});

  const sideBarContainerWidth = isXLDesktop
    ? 400
    : isLGDesktop
    ? 360
    : isTab
    ? 320
    : isMobile
    ? 280
    : 240;

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setContainerWidth(containerRef.current.offsetWidth);

      const handleResize = () => {
        setContainerHeight(containerRef.current.offsetHeight);
        setContainerWidth(containerRef.current.offsetWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [containerRef]);

  const {participantRaisedHand} = useRaisedHandParticipants();

  const _handleMeetingLeft = () => {
    setIsMeetingLeft(true);
  };

  const _handleOnRecordingStateChanged = ({status}) => {
    const recordingMessage =
      status === Constants.recordingEvents.RECORDING_STARTED
        ? 'Meeting recording is started'
        : 'Meeting recording is stopped.';

    toast(recordingMessage, {
      position: 'bottom-left',
      autoClose: 4000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  function onParticipantJoined(participant) {
    if (participant) {
      participant.setQuality('high');
    }
  }

  function onEntryResponded(participantId, name) {
    if (mMeetingRef.current?.localParticipant?.id === participantId) {
      if (name === 'allowed') {
        setLocalParticipantAllowedJoin(true);
      } else {
        setLocalParticipantAllowedJoin(false);
        setTimeout(() => {
          _handleMeetingLeft();
        }, 3000);
      }
    }
  }

  async function onMeetingJoined() {
    const {changeWebcam, changeMic, muteMic, disableWebcam} =
      mMeetingRef.current;

    if (webcamEnabled && selectedWebcam.id) {
      await new Promise((resolve) => {
        let track;
        disableWebcam();
        setTimeout(async () => {
          track = await getVideoTrack({
            webcamId: selectedWebcam.id,
            encoderConfig: 'h540p_w960p',
          });
          changeWebcam(track);
          resolve();
        }, 500);
      });
    }

    if (micEnabled && selectedMic.id) {
      await new Promise((resolve) => {
        muteMic();
        setTimeout(() => {
          changeMic(selectedMic.id);
          resolve();
        }, 500);
      });
    }
  }

  function onMeetingLeft() {
    onMeetingLeave();
  }

  const _handleOnError = (data) => {
    const {code, message} = data;
    const joiningErrCodes = [
      4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010,
    ];
    const isJoiningError = joiningErrCodes.includes(code);
    const isCriticalError = `${code}`.startsWith('500');

    new Audio(
      isCriticalError
        ? `https://static.videosdk.live/prebuilt/notification_critical_err.mp3`
        : `https://static.videosdk.live/prebuilt/notification_err.mp3`,
    ).play();

    setMeetingErrorVisible(true);
    setMeetingError({
      code,
      message: isJoiningError ? 'Unable to join meeting!' : message,
    });
  };

  const mMeeting = useMeeting({
    onParticipantJoined,
    onEntryResponded,
    onMeetingJoined,
    onMeetingLeft,
    onError: _handleOnError,
    onRecordingStateChanged: _handleOnRecordingStateChanged,
  });

  const isPresenting = Boolean(mMeeting.presenterId);

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  usePubSub('RAISE_HAND', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;
      const {senderId, senderName} = data;
      const isLocal = senderId === localParticipantId;

      new Audio(
        `https://static.videosdk.live/prebuilt/notification.mp3`,
      ).play();

      toast(`${isLocal ? 'You' : nameTructed(senderName, 15)} raised hand 🖐🏼`, {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      participantRaisedHand(senderId);
    },
  });

  usePubSub('CHAT', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;
      const {senderId, senderName, message} = data;
      const isLocal = senderId === localParticipantId;

      if (!isLocal) {
        new Audio(
          `https://static.videosdk.live/prebuilt/notification.mp3`,
        ).play();

        toast(
          trimSnackBarText(`${nameTructed(senderName, 15)} says: ${message}`),
          {
            position: 'bottom-left',
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          },
        );
      }
    },
  });

  const labs = (e) => setShowLabs(e.target.value === 'YES');
  const medic = (e) => setMedication(e.target.value === 'YES');
  const sick = (e) => setSickLeave(e.target.value === 'YES');

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${baseURL}/users/`, {
        headers: {
          Authorization: `Token ${state?.logInToken}`,
        },
      });
      setPatients(response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };
  const fetchSelectedConsultation = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/consultations/${followUpConsultationId}/`,
        {
          headers: {
            Authorization: `Token ${state?.logInToken}`,
          },
        },
      );
      setFollowUpData(response.data);
    } catch (error) {
      console.error('Error fetching consultation data:', error);
    }
  };

  const doctor_id = state?.user?.id;

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setUpdateConsultationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateConsultationSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append('doctor_id', doctor_id);
    bodyFormData.append('patient_id', state?.patient_id);
    bodyFormData.append('chief_complaint', e.target.chief_complaint.value);
    bodyFormData.append(
      'history_of_illness',
      e.target.history_of_illness.value,
    );
    bodyFormData.append('review_of_systems', e.target.review_of_systems.value);
    bodyFormData.append('examination', e.target.examination.value);
    bodyFormData.append('assessment', e.target.assessment.value);
    bodyFormData.append('call_id', state?.call_id);
    bodyFormData.append(
      'medication',
      e.target.medication ? e.target.medication.value : 'No',
    );
    bodyFormData.append(
      'order_labs',
      e.target.order_labs ? e.target.order_labs.value : 'No',
    );
    if (e.target.sick_leave && e.target.sick_leave.value) {
      bodyFormData.append('sick_leave', e.target.sick_leave.value);
    }

    try {
      await axios.put(
        `${baseURL}/update-consultation/${updateConsultationData?.id}/`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${state?.logInToken}`,
          },
        },
      );
      setIsLoading(false);
      setShowSuccessModal(true);
      setShowUpdateConsultation(false);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleCloseUpdateConsultation();
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating consultation:', error);
    }
  };

  const handleFollowUpSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (state?.call_id) {
      const updateCallFormData = new FormData();
      updateCallFormData.append('consultation_status', 'consultation_created');
      const callData = await axios.patch(
        `${baseURL}/update-call/${state.call_id}/`,
        updateCallFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${state?.logInToken}`,
          },
        },
      );
      if (callData?.data?.payment_id) {
        const updatePaymentFormData = new FormData();
        updatePaymentFormData.append('call_id', callData?.data?.id);
        updatePaymentFormData.append('payment_status', 'consultation_created');

        await axios.patch(
          `${baseURL}/update-payment/${callData?.data?.payment_id}/`,
          updatePaymentFormData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${state?.logInToken}`,
            },
          },
        );
      }
    }
    const updateConsultationFormData = new FormData();
    updateConsultationFormData.append('follow_up', e.target.follow_up.value);
    updateConsultationFormData.append('follow_up_doctor_id', doctor_id);
    updateConsultationFormData.append('doctor_id', followUpData?.doctor_id);
    updateConsultationFormData.append('patient_id', followUpData?.patient_id);
    updateConsultationFormData.append(
      'chief_complaint',
      followUpData?.chief_complaint,
    );
    updateConsultationFormData.append(
      'history_of_illness',
      followUpData?.history_of_illness,
    );
    updateConsultationFormData.append(
      'review_of_systems',
      followUpData?.review_of_systems,
    );
    updateConsultationFormData.append('weight', followUpData?.weight);
    updateConsultationFormData.append('height', followUpData?.height);
    updateConsultationFormData.append('examination', followUpData?.examination);
    updateConsultationFormData.append('assessment', followUpData?.assessment);

    try {
      const response = await axios.patch(
        `${baseURL}/update-consultation/${followUpConsultationId}/`,
        updateConsultationFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${state?.logInToken}`,
          },
        },
      );
      setIsLoading(false);
      setShowSuccessModal(true);
      setFilledFollowUp(response?.data?.follow_up);
      setIsFollowUpCreated(true);
      setShowFollowUp(false);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleCloseFollowUp();
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.error('Error submitting follow-up:', error);
    }
  };

  const getAgeInYears = (dateString) => {
    const birthDate = new Date(dateString);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (birthDate.getDate() > today.getDate()) {
      const temp = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += temp;
      months--;
    }

    return years;
  };

  const formatErrorMessage = (errors) => {
    return Object?.entries(errors)
      ?.map(([field, messages]) => `${field}: ${messages.join(' ')}`)
      ?.join('\n');
  };

  const handleWriteConsultationSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append('doctor_id', doctor_id);
    bodyFormData.append('patient_id', state?.patient_id);
    bodyFormData.append('chief_complaint', chiefComplaint);
    bodyFormData.append('history_of_illness', historyOfIllness);
    bodyFormData.append('review_of_systems', reviewOfSystems);
    bodyFormData.append('examination', examination);
    bodyFormData.append('assessment', assessment);
    bodyFormData.append('weight', weight || null);
    bodyFormData.append('height', height || null);
    bodyFormData.append('call_id', state?.call_id);
    if (medicationField === '') {
      bodyFormData.append('medication', 'No');
    } else {
      bodyFormData.append('medication', medicationField);
    }
    if (orderLabs === '') {
      bodyFormData.append('order_labs', 'No');
    } else {
      bodyFormData.append('order_labs', orderLabs);
    }
    if (sickLeaveField === null) {
      bodyFormData.append('sick_leave', 0);
    } else {
      bodyFormData.append('sick_leave', sickLeaveField);
    }

    try {
      const patientsResponse = await axios.get(`${baseURL}/users/`, {
        headers: {
          Authorization: `Token ${state?.logInToken}`,
        },
      });
      const patients = patientsResponse.data;

      const patient = patients.find((p) => p.id === state?.patient_id);
      const age = getAgeInYears(patient.date_of_birth);

      const consultationType = age < 14 ? 'pediatric' : 'adult';
      bodyFormData.append('consultation_type', consultationType);

      const response = await axios.post(
        `${baseURL}/create-consultation/`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${state?.logInToken}`,
          },
        },
      );

      if (state?.call_id) {
        const updateCallFormData = new FormData();
        updateCallFormData.append(
          'consultation_status',
          'consultation_created',
        );
        const callData = await axios.patch(
          `${baseURL}/update-call/${state.call_id}/`,
          updateCallFormData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${state?.logInToken}`,
            },
          },
        );
        if (callData?.data?.payment_id) {
          const updatePaymentFormData = new FormData();
          updatePaymentFormData.append('call_id', callData?.data?.id);
          updatePaymentFormData.append(
            'payment_status',
            'consultation_created',
          );

          await axios.patch(
            `${baseURL}/update-payment/${callData?.data?.payment_id}/`,
            updatePaymentFormData,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${state?.logInToken}`,
              },
            },
          );
        }
      }

      setIsLoading(false);
      setShowSuccessModal(true);
      setIsConsultationCreated(true);
      setUpdateConsultationData(response?.data);
      setShow(false);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleClose();
      }, 2000);
    } catch (error) {
      handleClose();
      setIsLoading(false);
      const formattedErrorMessage = error.response?.data
        ? formatErrorMessage(error.response.data)
        : error.message;
      setErrorMessage(formattedErrorMessage);
      setShowErrorModal(true);
    }
  };

  const getAge = (dateString) => {
    const birthDate = new Date(dateString);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (birthDate.getDate() > today.getDate()) {
      const temp = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += temp;
      months--;
    }

    if (years === 0) {
      return `${months} months`;
    }

    return `${years} years`;
  };

  const selectedPatient = useMemo(
    () => patients.find((patient) => state?.patient_id === patient.id),
    [patients, state],
  );

  useEffect(() => {
    fetchPatients();
    if (isFollowUp) {
      fetchSelectedConsultation();
    }
  }, [isFollowUp]);

  return (
    <div className="fixed inset-0 customPosition">
      <div ref={containerRef} className="h-full flex flex-col bg-gray-800">
        {typeof localParticipantAllowedJoin === 'boolean' ? (
          localParticipantAllowedJoin ? (
            <>
              {state?.user?.is_doctor && (
                <div className="row">
                  {isFollowUp ? (
                    <div className="btnPrimary" onClick={setShowFollowUp}>
                      {isFollowUpCreated
                        ? 'Update Follow Up'
                        : 'Fill Follow Up'}
                    </div>
                  ) : isConsultationCreated ? (
                    <div
                      className="btnPrimary"
                      onClick={setShowUpdateConsultation}>
                      Update Consultation
                    </div>
                  ) : (
                    <div className="btnPrimary" onClick={setShow}>
                      Write Consultation
                    </div>
                  )}
                  <div className="btnPrimary" onClick={setShowPatientProfile}>
                    View patient's profile
                  </div>
                </div>
              )}
              {showPatientProfile && (
                <SelectedPatient
                  patient={selectedPatient}
                  handleCloseSelectedPatient={handleClosePatientProfile}
                />
              )}
              <Modal show={show} onHide={handleClose} id="consultationModal">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 style={{color: '#0f0f17'}}>
                      Patient's name: &nbsp;
                      <span style={{color: '#3c3c3c', fontSize: '18px'}}>
                        {selectedPatient?.full_name}
                        <br />
                        Age:{' '}
                        <strong>
                          {selectedPatient &&
                            getAge(selectedPatient?.date_of_birth)}
                        </strong>
                      </span>
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    className="ekseerForm"
                    style={{textAlign: 'left'}}
                    onSubmit={handleWriteConsultationSubmit}>
                    <div className="row">
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Chief Complaint:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="chief_complaint"
                          value={chiefComplaint}
                          placeholder="Chief Complaint ..."
                          onChange={(e) => setChiefComplaint(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          History of Presenting Illness:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={historyOfIllness}
                          name="history_of_illness"
                          placeholder="History of Presenting Illness ..."
                          onChange={(e) => setHistoryOfIllness(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Review of Systems:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="review_of_systems"
                          value={reviewOfSystems}
                          placeholder="Review of Systems ..."
                          onChange={(e) => setReviewOfSystems(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Examination:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={examination}
                          name="examination"
                          placeholder="Examination ..."
                          onChange={(e) => setExamination(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Assessment/Plan:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={assessment}
                          name="assessment"
                          placeholder="Assessment/Plan ..."
                          onChange={(e) => setAssessment(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Row style={{padding: '0', margin: '0'}}>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Weight (KG):
                            </Form.Label>
                            <FloatingLabel
                              label="Weight (KG)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="weight"
                                name="weight"
                                type="number"
                                min={0}
                                step={0.01}
                                value={weight}
                                placeholder="Patient's weight"
                                onChange={(e) => setWeight(e.target.value)}
                                required
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Height (CM):
                            </Form.Label>
                            <FloatingLabel
                              label="Height (cm)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="height"
                                name="height"
                                type="number"
                                min={0}
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                                step={0.01}
                                placeholder="Patient's height"
                                required
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Prescribe Medication
                        </Form.Label>
                        <div className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            id="yes"
                            defaultValue="YES"
                            name="yesOrNo"
                            onChange={medic}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            id="no"
                            name="yesOrNo"
                            defaultChecked
                            onChange={medic}
                          />
                        </div>
                        {medication && (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className="mb-3"
                            value={medicationField}
                            id="medic"
                            name="medication"
                            onChange={(e) => setMedicationField(e.target.value)}
                            placeholder="Write the full prescription medications ..."
                            required
                          />
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Order home labs, IV/IM medication, others:
                        </Form.Label>
                        <div className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            id="yes2"
                            name="yesOrNo2"
                            defaultValue="YES"
                            onChange={labs}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            id="no2"
                            name="yesOrNo2"
                            defaultChecked
                            onChange={labs}
                          />
                        </div>
                        {showLabs && (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className="mb-3"
                            id="order_labs"
                            name="order_labs"
                            value={orderLabs}
                            onChange={(e) => setOrderLabs(e.target.value)}
                            placeholder="Order home labs, IV/IM medication, others..."
                            required
                          />
                        )}
                      </Form.Group>
                      <Form.Group>
                        <FormLabel
                          htmlFor="sick_leave"
                          className="ekseerForm-label">
                          Prescribe Sick-leave
                        </FormLabel>
                        <div className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            id="yes3"
                            name="yesOrNo3"
                            defaultValue="YES"
                            onChange={sick}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            id="no3"
                            name="yesOrNo3"
                            defaultChecked
                            onChange={sick}
                          />
                        </div>
                        {sickLeave && (
                          <FloatingLabel
                            label="How many days?"
                            className="mb-3 ekseerFormInnerLabel">
                            <FormControl
                              id="sick"
                              name="sick_leave"
                              type="number"
                              value={sickLeaveField}
                              min={0}
                              onChange={(e) =>
                                setSickLeaveField(e.target.value)
                              }
                              placeholder="How many days?"
                            />
                          </FloatingLabel>
                        )}
                      </Form.Group>
                      <div className="col-xl-12">
                        <Button type="submit" className="btnPrimary btnLeft">
                          Save consultation
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                show={showFollowUp}
                onHide={handleCloseFollowUp}
                id="consultationModal">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 style={{color: '#0f0f17'}}>
                      Patient's name: &nbsp;
                      <span style={{color: '#3c3c3c', fontSize: '18px'}}>
                        {selectedPatient?.full_name}
                        <br />
                        Age:{' '}
                        <strong>
                          {selectedPatient &&
                            getAge(selectedPatient?.date_of_birth)}
                        </strong>
                      </span>
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    className="ekseerForm"
                    style={{textAlign: 'left'}}
                    onSubmit={handleFollowUpSubmit}>
                    <div className="row">
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Follow up brief:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="follow_up"
                          placeholder="Follow up brief ..."
                          value={followUp}
                          onChange={handleFollowUpChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Chief Complaint:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="chief_complaint"
                          value={followUpData?.chief_complaint}
                          placeholder="Chief Complaint ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          History of Presenting Illness:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="history_of_illness"
                          value={followUpData?.history_of_illness}
                          placeholder="History of Presenting Illness ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Review of Systems:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="review_of_systems"
                          value={followUpData?.review_of_systems}
                          placeholder="Review of Systems ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Examination:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="examination"
                          value={followUpData?.examination}
                          placeholder="Examination ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Assessment/Plan:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="assessment"
                          value={followUpData?.assessment}
                          placeholder="Assessment/Plan ..."
                          disabled
                        />
                      </Form.Group>
                      <Row style={{padding: '0', margin: '0'}}>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Weight (KG):
                            </Form.Label>
                            <FloatingLabel
                              label="Weight (KG)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="weight"
                                name="weight"
                                type="number"
                                min={0}
                                step={0.01}
                                value={followUpData?.weight}
                                placeholder="Patient's weight"
                                disabled
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Height (CM):
                            </Form.Label>
                            <FloatingLabel
                              label="Height (cm)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="height"
                                name="height"
                                type="number"
                                min={0}
                                step={0.01}
                                value={followUpData?.height}
                                placeholder="Patient's height"
                                disabled
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Medication:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="medication"
                          value={followUpData?.medication}
                          placeholder="Prescription medications ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Order home labs, IV/IM medication, others:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="order_labs"
                          value={followUpData?.order_labs}
                          placeholder="Order home labs, IV/IM medication, others ..."
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Sick Leave:
                        </Form.Label>
                        <Form.Control
                          as="input"
                          rows={3}
                          name="sick_leave"
                          value={followUpData?.sick_leave}
                          placeholder="Sick Leave ..."
                          disabled
                        />
                      </Form.Group>
                      <div className="col-xl-12">
                        <Button type="submit" className="btnPrimary btnLeft">
                          Save Follow Up
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                show={showUpdateConsultation}
                onHide={handleCloseUpdateConsultation}
                id="consultationModal">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 style={{color: '#0f0f17'}}>
                      Patient's name: &nbsp;
                      <span style={{color: '#3c3c3c', fontSize: '18px'}}>
                        {selectedPatient?.full_name}
                        <br />
                        Age:{' '}
                        <strong>
                          {selectedPatient &&
                            getAge(selectedPatient?.date_of_birth)}
                        </strong>
                      </span>
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    className="ekseerForm"
                    style={{textAlign: 'left'}}
                    onSubmit={handleUpdateConsultationSubmit}>
                    <div className="row">
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Chief Complaint:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="chief_complaint"
                          value={updateConsultationData.chief_complaint}
                          placeholder="Chief Complaint ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          History of Presenting Illness:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="history_of_illness"
                          value={updateConsultationData.history_of_illness}
                          placeholder="History of Presenting Illness ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Review of Systems:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="review_of_systems"
                          value={updateConsultationData.review_of_systems}
                          placeholder="Review of Systems ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Examination:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="examination"
                          value={updateConsultationData.examination}
                          placeholder="Examination ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Assessment/Plan:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="assessment"
                          value={updateConsultationData.assessment}
                          placeholder="Assessment/Plan ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Row style={{padding: '0', margin: '0'}}>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Weight (KG):
                            </Form.Label>
                            <FloatingLabel
                              label="Weight (KG)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="weight"
                                name="weight"
                                type="number"
                                onChange={handleInputChange}
                                min={0}
                                step={0.01}
                                value={updateConsultationData.weight}
                                placeholder="Patient's weight"
                                required
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Form.Label className="ekseerForm-label">
                              Height (CM):
                            </Form.Label>
                            <FloatingLabel
                              label="Height (cm)"
                              className="mb-3 ekseerFormInnerLabel">
                              <FormControl
                                id="height"
                                name="height"
                                onChange={handleInputChange}
                                type="number"
                                min={0}
                                step={0.01}
                                value={updateConsultationData.height}
                                placeholder="Patient's height"
                                required
                              />
                            </FloatingLabel>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Medication:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="medication"
                          value={updateConsultationData.medication}
                          placeholder="Prescription medications ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Order home labs, IV/IM medication, others:
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="order_labs"
                          value={updateConsultationData.order_labs}
                          placeholder="Prescription medications ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="ekseerForm-label">
                          Sick Leave:
                        </Form.Label>
                        <Form.Control
                          as="input"
                          rows={3}
                          name="sick_leave"
                          value={updateConsultationData.sick_leave}
                          placeholder="Sick Leave ..."
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <div className="col-xl-12">
                        <Button type="submit" className="btnPrimary btnLeft">
                          Update Consultation
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              {isLoading && (
                <div className="spinner">
                  <Image
                    src="assets/icons/clock.gif"
                    className="mb-5 float"
                    id="clockSpinner"
                  />
                </div>
              )}
              <Modal show={showSuccessModal} onHide={handleSuccessModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isFollowUp
                      ? 'Follow up filled successfully!'
                      : 'Consultation filled successfully. Thank you!'}
                  </Modal.Title>
                </Modal.Header>
                <Image
                  src="assets/images/success.png"
                  className="mb-5 float"
                  id="call"
                />
              </Modal>
              <Modal show={showErrorModal} onHide={handleErrorModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  There was an error in creating the consultation. Please try
                  again.
                  <br />
                  <strong>Error details:</strong>
                  <pre>{errorMessage}</pre>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleErrorModalClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className={`flex flex-1 flex-row bg-gray-800`}>
                <div className={`flex flex-1`}>
                  {isPresenting && (
                    <PresenterView height={containerHeight - bottomBarHeight} />
                  )}
                  {!isPresenting || !isMobile ? (
                    <MemorizedParticipantView isPresenting={isPresenting} />
                  ) : null}
                </div>

                <SidebarConatiner
                  height={containerHeight - bottomBarHeight}
                  sideBarContainerWidth={sideBarContainerWidth}
                />
              </div>

              <BottomBar
                bottomBarHeight={bottomBarHeight}
                setIsMeetingLeft={setIsMeetingLeft}
                isDoctor={state?.user?.is_doctor}
                selectWebcamDeviceId={selectWebcamDeviceId}
                setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                selectMicDeviceId={selectMicDeviceId}
                setSelectMicDeviceId={setSelectMicDeviceId}
              />
            </>
          ) : null
        ) : (
          !mMeeting.isMeetingJoined && <WaitingToJoinScreen />
        )}
        <ConfirmBox
          open={meetingErrorVisible}
          successText="OKAY"
          onSuccess={() => setMeetingErrorVisible(false)}
          title={`Error Code: ${meetingError.code}`}
          subTitle={meetingError.message}
        />
      </div>
    </div>
  );
}

import {CheckIcon, ClipboardIcon} from '@heroicons/react/outline';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
}) {
  const {state} = useLocation();
  const {i18n: translationI18n} = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [meetingId, setMeetingId] = useState('');
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  const handleClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      setIsLoading(true);

      const newMeetingId = await _handleOnCreateMeeting();
      setMeetingId(newMeetingId);

      // Assuming onClickStartMeeting and onClickJoin are defined elsewhere
      if (newMeetingId.match('\\w{4}\\-\\w{4}\\-\\w{4}')) {
        if (videoTrack) {
          videoTrack.stop();
          setVideoTrack(null);
        }
        onClickStartMeeting();
      } else {
        onClickJoin(newMeetingId);
      }

      setIsLoading(false); // Done loading
    }
  };
  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 flex flex-col">
          <div className="flex items-center justify-center flex-col w-full ">
            {state?.user?.is_doctor ? (
              <button
                className="w-full btnPrimary text-white px-2 py-3 rounded-xl mt-3"
                onClick={(e) => {
                  setMeetingId(state?.meeting_id);
                  setIsJoinMeetingClicked(true);
                  onClickJoin(state?.meeting_id);
                }}
              >
                Join consultation
              </button>
            ) : (
              <button
                className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl questions-labelAr"
                onClick={handleClick}
                disabled={isLoading || isClicked}
              >
                {isLoading
                  ? 'Loading...'
                  : translationI18n.language === 'en'
                  ? 'Request a doctor'
                  : 'طلب طبيب'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PaymentState = {
  orderId: string | null;
  paymentStatus: 'idle' | 'loading' | 'success' | 'failed';
  error: string | null;
};

const initialState: PaymentState = {
  orderId: null,
  paymentStatus: 'idle',
  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    initiatePaymentStart(state) {
      state.paymentStatus = 'loading';
      state.error = null;
    },
    initiatePaymentSuccess(
      state,
      action: PayloadAction<{ orderId: string; }>
    ) {
      state.paymentStatus = 'success';
      state.orderId = action.payload.orderId;
      state.error = null;
    },
    initiatePaymentFailed(state, action: PayloadAction<string>) {
      state.paymentStatus = 'failed';
      state.error = action.payload;
    },
    resetPayment(state) {
      state.paymentStatus = 'idle';
      state.orderId = null;
      state.error = null;
    },
  },
});

export const {
  initiatePaymentStart,
  initiatePaymentSuccess,
  initiatePaymentFailed,
  resetPayment,
} = paymentSlice.actions;

export default paymentSlice.reducer;

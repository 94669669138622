import React from 'react';
import {Container} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
//@ts-ignore
import twitterx from '../assets/icons/social-media/twitterx.svg';
//@ts-ignore
import facebook from '../assets/icons/social-media/facebook.svg';
//@ts-ignore
import linkedin from '../assets/icons/social-media/linkedin.svg';
//@ts-ignore
import instagram from '../assets/icons/social-media/instagram.svg';
//@ts-ignore
import tiktok from '../assets/icons/social-media/tiktok.svg';
//@ts-ignore
import youtube from '../assets/icons/social-media/youtube.svg';
//@ts-ignore
import snapchat from '../assets/icons/social-media/snapchat.svg';
//@ts-ignore
import applepay from '../assets/icons/payments/applepay.png';
//@ts-ignore
import visa from '../assets/icons/payments/visa.png';
//@ts-ignore
import mada from '../assets/icons/payments/mada.png';
//@ts-ignore
import mastercard from '../assets/icons/payments/mastercard.png';
export const Footer: React.FC = () => {
  const {t} = useTranslation();
  const nav = useNavigate();

  const socialMediaLinks = [
    {
      url: 'https://x.com/MedicalCloudSA?s=08',
      icon: twitterx,
    },
    {
      url: 'https://www.linkedin.com/company/medicalcloudsa/',
      icon: linkedin,
    },
    {
      url: 'https://www.tiktok.com/@medicalcloudsa?_r=1&_d=e7gfa7fea6lkg6&sec_uid=MS4wLjABAAAAsLhXjC5HsFzZfgn0wmdpwW2osjHs3qPErO-9b0BuKH3u5PCplz2KWTQAJNzg2213&share_author_id=7298677051664696327&sharer_language=en&source=h5_m&u_code=eb13gf9ieh5ijj&timestamp=1719422083&user_id=7298677051664696327&sec_user_id=MS4wLjABAAAAsLhXjC5HsFzZfgn0wmdpwW2osjHs3qPErO-9b0BuKH3u5PCplz2KWTQAJNzg2213&utm_source=whatsapp&utm_campaign=client_share&utm_medium=android&share_iid=7235423581349693185&share_link_id=f27e70f3-5539-4623-9cba-591ca1049157&share_app_id=1233&ugbiz_name=Account&ug_btm=b8727%2Cb4907&social_share_type=5',
      icon: tiktok,
    },
    {
      url: 'https://www.instagram.com/medicalcloudsa?igsh=MXNkeXFmZ2FnZTg2bg==',
      icon: instagram,
    },
    {
      url: 'https://youtube.com/@medicalcloudsa?si=HtK908ALTzPs3F_a',
      icon: youtube,
    },
    {
      url: 'https://www.snapchat.com/add/medicalcloudsa',
      icon: snapchat,
    },
    {
      url: 'https://www.facebook.com/medicalcloudsa?mibextid=ZbWKwL',
      icon: facebook,
    },
  ];

  const paymentIcons = [
    {
      icon: visa,
    },
    {
      icon: mastercard,
    },
    {
      icon: applepay,
    },
    {
      icon: mada,
    },
  ];

  return (
    <>
      <Container fluid className="footer text-center bg-color-primary">
        <div className="RowflexDirCol">
          <div className="column">
            <div className="logo">
              <span>{t('layout.navbar.logoM')}</span>
              <span>{t('layout.navbar.logoC')}</span>
            </div>
          </div>
          <div className="column bB">
            <h4>
              {t('layout.footer.healthCare')} <br />{' '}
              {t('layout.footer.wherever')}
            </h4>
            <div className="btnDark" onClick={() => nav('/contact')}>
              {t('layout.navbar.contactUs')}
            </div>
            <div className="social-media-icons">
              {socialMediaLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={link.icon}
                    alt="Social Media Icon"
                    className="social-icon"
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="column">
            <h5 className="mt-3">{t('layout.footer.copyRight')}</h5>
            <div className="payments-icons">
              {paymentIcons.map((link, index) => (
                <img
                  key={index}
                  src={link.icon}
                  alt="Payment Icon"
                  className="payment-icon"
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

import React, {useEffect, useState} from 'react';
import axios from '../axiosConfig';
import {Container, Modal, ModalHeader, Table} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {RootState} from 'state';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

export const FollowUpConsultations = ({consultationIds, show, onClose}) => {
  const {user, token, isLogged} = useSelector(
    (state: RootState) => state.login,
  );
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const nav = useNavigate();
  const {i18n: translationI18n} = useTranslation();

  const [doctors, setDoctors] = useState([]);
  const [consultations, setConsultations] = useState([]);

  const fetchConsultations = async () => {
    await axios
      .get(`${baseURL}/consultations/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setConsultations(response.data);
      });
  };

  const formatDate = (date) => {
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    // Split the formatted date into day, month, and year parts
    const [month, day, year, hour, minute] = formattedDate.split(' ');
    // Convert the month abbreviation to uppercase
    const capitalizedMonth = month.toUpperCase();
    // Return the formatted date with uppercase month abbreviation and desired format
    return `${day} ${capitalizedMonth} ${year} ${hour} ${minute}`;
  };

  const fetchDoctors = async () => {
    await axios
      .get(`${baseURL}/users/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setDoctors(response.data);
      });
  };

  const redirectVideoCall = (followUpConsultationId) => {
    nav('/video-call', {
      state: {
        currentLanguage: translationI18n.language,
        isLoggedIn: isLogged,
        user: user,
        logInToken: token,
        followUpConsultationId: followUpConsultationId,
        isFollowUp: true,
      },
    });
  };

  useEffect(() => {
    fetchConsultations();
    fetchDoctors();
  }, []);

  const filteredConsultations = consultations.filter((consultation) =>
    consultationIds.includes(consultation.id),
  );

  return (
    <Container>
      <Modal show={show} onHide={onClose} id="showAllConsultationsModal">
        <ModalHeader>
          Please select which consultation you would like to follow up on
        </ModalHeader>
        <Modal.Body>
          {filteredConsultations.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Dr. Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody style={{cursor: 'pointer'}}>
                {filteredConsultations.map((filteredConsultation, index) => (
                  <tr
                    key={filteredConsultation.id}
                    onClick={() => redirectVideoCall(filteredConsultation.id)}>
                    <>
                      <td>
                        <span style={{color: '#0f0f17'}}>{index + 1}</span>
                      </td>
                      {doctors
                        ?.filter(
                          (doctor) =>
                            filteredConsultation.doctor_id === doctor.id,
                        )
                        .map((filteredDoctor) => (
                          <td key={filteredDoctor.id}>
                            <span
                              style={{
                                color: '#0f0f17',
                                marginTop: '15px',
                                fontSize: '14px',
                              }}>
                              {filteredDoctor.full_name}
                            </span>
                          </td>
                        ))}
                      <td>
                        <strong style={{fontSize: '12px'}}>
                          {formatDate(filteredConsultation.created_at)}
                        </strong>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No previous consultations found.</p>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Image, ListGroup, Modal} from 'react-bootstrap';
import axios from '../axiosConfig';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/Spinner';
import {SelectedPatient} from '../components/SelectedPatient';
import {ShowSelectedConsultation} from '../components/ShowSelectedConsultation';
import {RootState} from 'state';

interface Consultation {
  id: number;
  patient_id: number;
  doctor_id: number;
  created_at: string;
  order_labs: string;
  upload_order_labs: string | null;
}
interface Patient {
  id: number;
  full_name: string;
  full_name_arabic: string;
  iqama_number: string;
}

interface Doctor {
  id: number;
  full_name: string;
  full_name_arabic: string;
}

export const Consultations: React.FC = () => {
  const {user, token} = useSelector((state: RootState) => state.login);
  const {i18n} = useTranslation();
  const baseURL = process.env.REACT_APP_API_BASE_URL!;
  const [consultations, setConsultations] = useState<Consultation[]>([]);
  const [newConsultations, setNewConsultations] = useState(false);
  const [visibleSelectedPatient, setVisibleSelectedPatient] = useState(false);
  const [showUploadOrderLabs, setShowUploadOrderLabs] = useState(false);
  const [fileOrderLabs, setFileOrderLabs] = useState<string | null>(null);
  const [selectedConsultationId, setSelectedConsultationId] = useState<
    number | null
  >(null);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [selectedConsultation] = useState<Consultation | null>(null);
  const [visibleSelectedConsultation, setVisibleSelectedConsultation] =
    useState(false);
  const [doctorsOrPatients, setDoctorsOrPatients] = useState<
    (Patient | Doctor)[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const {t} = useTranslation();

  const handleCloseUploadOrderLabs = () => {
    setShowUploadOrderLabs(false);
  };

  const fetchConsultations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get<Consultation[]>(
        `${baseURL}/consultations/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      );
      setConsultations(response.data);
      setIsLoading(false);

      const hasNewConsultations = response.data.some((consultation) =>
        user?.is_doctor
          ? consultation.doctor_id === user.id
          : consultation.patient_id === user?.id,
      );
      setNewConsultations(hasNewConsultations);

      if (selectedConsultationId) {
        setIsModalLoading(true);
        const consultationResponse = await axios.get<Consultation>(
          `${baseURL}/consultations/${selectedConsultationId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          },
        );
        setFileOrderLabs(consultationResponse.data.upload_order_labs);
        setShowUploadOrderLabs(true); // Show modal only when content is loaded
        setIsModalLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsModalLoading(false);
      console.error(error);
    }
  };

  const fetchDoctorsOrPatients = async () => {
    try {
      const response = await axios.get<(Patient | Doctor)[]>(
        `${baseURL}/users/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      );
      setDoctorsOrPatients(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUniquePatients = (): Patient[] => {
    const uniquePatients = doctorsOrPatients.filter((patient) =>
      consultations.some(
        (consultation) => consultation.patient_id === patient.id,
      ),
    ) as Patient[];
    return Array.from(new Set(uniquePatients.map((patient) => patient.id))).map(
      (id) => uniquePatients.find((patient) => patient.id === id)!,
    );
  };

  const getUniqueConsultations = (): Consultation[] => {
    const uniquePatients = getUniquePatients();
    const uniqueConsultations = consultations.filter((consultation, index) =>
      uniquePatients.some((patient) => consultation.patient_id === patient.id),
    );
    return Array.from(
      new Map(
        uniqueConsultations.map((consultation) => [
          consultation.patient_id,
          consultation,
        ]),
      ).values(),
    );
  };

  const showSelectedPatient = async (patient: Patient) => {
    setSelectedPatient(patient);
    setVisibleSelectedPatient(true);
  };

  const formatDate = (date: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = new Date(date)
      .toLocaleDateString('en-US', options)
      .split(' ');
    const [month, day, year, hour, minute] = formattedDate;
    const capitalizedMonth = month.toUpperCase();
    return `${day} ${capitalizedMonth} ${year} ${hour} ${minute}`;
  };

  const hideSelectedPatient = () => {
    setVisibleSelectedPatient(false);
  };

  const hideShowSelectedConsultations = () => {
    setVisibleSelectedConsultation(false);
  };

  useEffect(() => {
    fetchConsultations();
    fetchDoctorsOrPatients();
  }, [selectedConsultationId]);

  return (
    <Container className="mt-5 h-75 mb-5">
      {!isLoading ? (
        <>
          {i18n.language === 'en' ? (
            <Row className="align-center">
              <Col md={6} sm={12}>
                <div style={{textAlign: 'left', padding: '0px'}}>
                  <h2 className="title">
                    My <span id="cutomText">Consultations</span>
                  </h2>
                  {!newConsultations ? (
                    <h5 className="ml-4">No consultations yet!</h5>
                  ) : (
                    <>
                      {getUniqueConsultations()
                        .filter(
                          (consultation) => consultation.doctor_id === user?.id,
                        )
                        .map((filteredConsultation, index) => (
                          <ListGroup as="ol" key={filteredConsultation.id}>
                            {getUniquePatients()
                              .filter(
                                (patient) =>
                                  filteredConsultation.patient_id ===
                                  patient.id,
                              )
                              .map((filteredPatient) => (
                                <ListGroup.Item
                                  as="li"
                                  className="d-flex justify-content-between align-items-center"
                                  key={filteredPatient.id}
                                  style={{cursor: 'pointer'}}
                                  onClick={() =>
                                    showSelectedPatient(filteredPatient)
                                  }>
                                  {index + 1}.
                                  <div className="ms-2 me-auto">
                                    <div className="fw-bold">
                                      {filteredPatient.full_name_arabic}
                                      <br />
                                      Patient ID/Iqama: &nbsp;
                                      {filteredPatient.iqama_number}
                                      <br />
                                      <span style={{marginLeft: '3px'}}>
                                        View profile
                                      </span>
                                    </div>
                                  </div>
                                  <strong>
                                    Last consultation on: <br />
                                    {formatDate(
                                      filteredConsultation.created_at,
                                    )}
                                  </strong>
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                        ))}
                      {visibleSelectedPatient && selectedPatient && (
                        <SelectedPatient
                          patient={selectedPatient}
                          handleCloseSelectedPatient={hideSelectedPatient}
                        />
                      )}
                      {visibleSelectedConsultation && selectedConsultation && (
                        <ShowSelectedConsultation
                          consultation={selectedConsultation}
                          handleCloseShowSelectedConsultation={
                            hideShowSelectedConsultations
                          }
                        />
                      )}
                      {consultations
                        .filter(
                          (consultation) =>
                            consultation.patient_id === user?.id,
                        )
                        .map((filteredConsultation, index) => (
                          <ListGroup
                            as="ol"
                            className={
                              filteredConsultation.upload_order_labs !== null
                                ? 'customBorder'
                                : ''
                            }
                            key={filteredConsultation.id}>
                            {doctorsOrPatients
                              .filter(
                                (doctor) =>
                                  filteredConsultation.doctor_id === doctor.id,
                              )
                              .map((filteredDoctor) => (
                                <ListGroup.Item
                                  as="li"
                                  onClick={() =>
                                    filteredConsultation.upload_order_labs !==
                                    null
                                      ? (setSelectedConsultationId(
                                          filteredConsultation.id,
                                        ),
                                        setShowUploadOrderLabs(false))
                                      : undefined
                                  }
                                  className="d-flex justify-content-between align-items-center"
                                  key={filteredDoctor.id}>
                                  {index + 1}.
                                  <div className="ms-2 me-auto">
                                    <strong>{filteredDoctor.full_name}</strong>
                                  </div>
                                  <strong>
                                    {formatDate(
                                      filteredConsultation.created_at,
                                    )}
                                  </strong>
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                        ))}
                    </>
                  )}
                </div>
              </Col>
              <Col md={6} sm={12} className="scientistCol">
                <Image
                  src="assets/images/consultations.png"
                  className="float"
                />
              </Col>
            </Row>
          ) : (
            <Row className="wrapReverse align-center" dir="ltr">
              <Col md={6} sm={12} className="scientistCol m0">
                <Image
                  src="assets/images/consultations.png"
                  id="scientistImg"
                  className="float"
                />
              </Col>
              <Col md={6} sm={12}>
                {visibleSelectedConsultation && selectedConsultation && (
                  <ShowSelectedConsultation
                    consultation={selectedConsultation}
                    handleCloseShowSelectedConsultation={
                      hideShowSelectedConsultations
                    }
                  />
                )}
                <div className="text-right" style={{padding: '0px'}}>
                  <h2 id="bB" style={{marginRight: '15px'}}>
                    إستشاراتي
                  </h2>
                  {!newConsultations ? (
                    <h5 className="ml-4">!لا توجد استشارات بعد</h5>
                  ) : (
                    consultations
                      .filter(
                        (consultation) => consultation.patient_id === user?.id,
                      )
                      .map((filteredConsultation, index) => (
                        <ListGroup
                          as="ol"
                          className={
                            filteredConsultation.upload_order_labs !== null
                              ? 'customBorder'
                              : ''
                          }
                          key={filteredConsultation.id}>
                          {doctorsOrPatients
                            .filter(
                              (doctor) =>
                                filteredConsultation.doctor_id === doctor.id,
                            )
                            .map((filteredDoctor) => (
                              <ListGroup.Item
                                as="li"
                                dir="rtl"
                                onClick={() =>
                                  filteredConsultation.upload_order_labs !==
                                  null
                                    ? (setSelectedConsultationId(
                                        filteredConsultation.id,
                                      ),
                                      setShowUploadOrderLabs(false))
                                    : undefined
                                }
                                className="d-flex justify-content-between align-items-center"
                                key={filteredDoctor.id}>
                                <div className="text-right">
                                  <strong>
                                    {index + 1}.
                                    {filteredDoctor.full_name_arabic}
                                  </strong>
                                </div>
                                <strong dir="ltr">
                                  {formatDate(filteredConsultation.created_at)}
                                </strong>
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      ))
                  )}
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Spinner />
      )}
      {showUploadOrderLabs && (
        <Modal show={showUploadOrderLabs} onHide={handleCloseUploadOrderLabs}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t('pages.consultations.uploadOrderLabs')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isModalLoading ? (
              <Spinner />
            ) : (
              fileOrderLabs && (
                <a
                  href={fileOrderLabs}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('pages.consultations.uploadedFile')}
                </a>
              )
            )}
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

import React, {useState} from 'react';
import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Image,
  FormControl,
  FormLabel,
  Button,
  Modal,
} from 'react-bootstrap';
import axios from '../axiosConfig';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/Spinner';

export const Contact = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const {i18n: translationI18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [setMessage] = useState('');

  const [show, setShow] = useState(false);
  const [showAr, setShowAr] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const handleCloseAr = () => setShowAr(false);
  const handleClose = () => setShow(false);
  const handleCloseErrors = () => setShowErrors(false);

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('full_name', e.target.full_name.value);
    bodyFormData.append('mobile_number', e.target.mobile_number.value);
    bodyFormData.append('email', e.target.email.value);
    bodyFormData.append('message', e.target.message.value);

    try {
      axios({
        method: 'post',
        url: `${baseURL}/send-email/`,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
        .then(function (response) {
          setFullName('');
          setEmail('');
          setMobileNumber('');
          setMessage('');
          setIsLoading(false);
          setShow(true);
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch(function (response) {
          setFullName('');
          setEmail('');
          setMobileNumber('');
          setMessage('');
          setIsLoading(false);
          setShowErrors(true);
          setTimeout(() => {
            handleCloseErrors();
          }, 2000);
        });
    } catch (error) {
      console.clear();
    }
  };
  const handleContactSubmitAr = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append('full_name', e.target.full_name.value);
    bodyFormData.append('mobile_number', e.target.mobile_number.value);
    bodyFormData.append('email', e.target.email.value);
    bodyFormData.append('message', e.target.message.value);

    try {
      axios({
        method: 'post',
        url: `${baseURL}/send-email/`,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
        .then(function (response) {
          setFullName('');
          setEmail('');
          setMobileNumber('');
          setMessage('');
          setIsLoading(false);
          setShowAr(true);
          setTimeout(() => {
            handleCloseAr();
          }, 2000);
        })
        .catch(function (response) {
          setFullName('');
          setEmail('');
          setMobileNumber('');
          setMessage('');
          setIsLoading(false);
          setShowErrors(true);
          setTimeout(() => {
            handleCloseErrors();
          }, 2000);
        });
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <>
      <Container className="mt-5">
        {!isLoading && (
          <>
            {translationI18n?.language === 'en' ? (
              <>
                {/* /CONTACT US PAGE ENGLISH */}
                <Row>
                  <Col md={6} sm={12}>
                    <Form
                      className="ekseerForm"
                      style={{textAlign: 'left'}}
                      onSubmit={handleContactSubmit}>
                      <h2 className="title" style={{fontSize: '1.5rem'}}>
                        Contact us for inquiries, complaints, or suggestions:
                      </h2>
                      <FormLabel
                        htmlFor="full_name"
                        className="ekseerForm-label">
                        Your Full Name
                      </FormLabel>
                      <FloatingLabel
                        controlId="full_name"
                        label="Enter your full name"
                        className="mb-3 innerFormLabel">
                        <FormControl
                          type="text"
                          name="full_name"
                          placeholder="Enter your full name"
                          required
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </FloatingLabel>
                      <Row>
                        <Col md={6} sm={6} style={{width: '50%'}}>
                          <FormLabel
                            htmlFor="mobile_number"
                            className="ekseerForm-label">
                            Mobile number
                          </FormLabel>
                          <FloatingLabel
                            controlId="mobile_number"
                            label="Ex: 5XXXXXXXX"
                            className="mb-3 innerFormLabel">
                            <FormControl
                              type="number"
                              name="mobile_number"
                              placeholder="Ex: 5XXXXXXXX"
                              required
                              value={mobileNumber}
                              inputMode="numeric"
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6} sm={6} style={{width: '50%'}}>
                          <FormLabel
                            htmlFor="email"
                            className="ekseerForm-label">
                            Email address
                          </FormLabel>
                          <FloatingLabel
                            controlId="email"
                            label="Email address"
                            className="mb-3 innerFormLabel">
                            <FormControl
                              type="email"
                              name="email"
                              placeholder="Email address"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Form.Label className="ekseerForm-label">
                        Your message:
                      </Form.Label>
                      <FloatingLabel
                        controlId="message"
                        label="Enter your message"
                        className="mb-3 innerFormLabel">
                        <Form.Control
                          style={{height: '150px'}}
                          as="textarea"
                          rows={4}
                          name="message"
                          placeholder="Enter your message ..."
                          required
                        />
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnLeft">
                        Send message
                      </Button>
                    </Form>
                  </Col>
                  <Col md={6} sm={12} className="scientistCol">
                    <Image src="assets/images/contact.png" className="float" />
                  </Col>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        The message has been sent successfully!
                      </Modal.Title>
                    </Modal.Header>
                    <Image
                      src="assets/images/success.png"
                      className="mb-5 float"
                      id="call"
                    />
                  </Modal>
                  <Modal show={showErrors} onHide={handleCloseErrors}>
                    <Modal.Header closeButton>
                      <Modal.Title>Sending message failed!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please try again.</Modal.Body>
                    <Image
                      src="assets/images/failed.png"
                      className="mb-5 float"
                      id="call"
                    />
                  </Modal>
                </Row>
              </>
            ) : (
              <>
                {/* /CONTACT US PAGE ARABIIC */}
                <Row className="wrapReverse" dir="ltr">
                  <Col md={6} sm={12} className="scientistCol">
                    <Image src="assets/images/contact.png" className="float" />
                  </Col>
                  <Col md={6} sm={12}>
                    <Form
                      onSubmit={handleContactSubmitAr}
                      dir="rtl"
                      className="ekseerForm">
                      <h2
                        className="title"
                        style={{
                          fontSize: '1.5rem',
                        }}>
                        تواصل معنا للاستفسارات، الشكاوى أو الاقتراحات:
                      </h2>
                      <FormLabel
                        htmlFor="full_name"
                        className="ekseerForm-labelAr">
                        الاسم الكامل
                      </FormLabel>
                      <FloatingLabel
                        controlId="full_name"
                        label="الاسم الكامل"
                        className="mb-3 innerFormLabelAr">
                        <FormControl
                          type="text"
                          name="full_name"
                          placeholder="الاسم الكامل"
                          required
                        />
                      </FloatingLabel>
                      <Row>
                        <Col md={6} sm={6} style={{width: '50%'}}>
                          <FormLabel
                            htmlFor="mobile_number"
                            className="ekseerForm-labelAr">
                            رقم الجوال
                          </FormLabel>
                          <FloatingLabel
                            controlId="mobile_number"
                            label="Ex: 5XXXXXXXX"
                            className="mb-3 innerFormLabelAr">
                            <FormControl
                              type="number"
                              name="mobile_number"
                              placeholder="Ex: 5XXXXXXXX"
                              inputMode="numeric"
                              required
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6} sm={6} style={{width: '50%'}}>
                          <FormLabel
                            htmlFor="email"
                            className="ekseerForm-labelAr">
                            البريد الالكتروني
                          </FormLabel>
                          <FloatingLabel
                            controlId="email"
                            label="البريد الالكتروني"
                            className="mb-3 innerFormLabelAr">
                            <FormControl
                              type="email"
                              name="email"
                              placeholder="البريد الالكتروني"
                              required
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Form.Label className="ekseerForm-labelAr">
                        الرسالة
                      </Form.Label>
                      <FloatingLabel
                        controlId="message"
                        label="نص الرسالة"
                        className="mb-3 innerFormLabelAr">
                        <Form.Control
                          style={{height: '150px'}}
                          as="textarea"
                          rows={4}
                          name="message"
                          placeholder="... نص الرسالة"
                          required
                        />
                      </FloatingLabel>
                      <Button type="submit" className="btnPrimary btnRight">
                        ارسال الرسالة
                      </Button>
                    </Form>
                  </Col>
                  <Modal show={showAr} onHide={handleCloseAr} dir="rtl">
                    <Modal.Header closeButton>
                      <Modal.Title className="colorBlackAr">
                        تم أرسال الرسالة بنجاح
                      </Modal.Title>
                    </Modal.Header>
                    <Image
                      src="assets/images/success.png"
                      className="mb-5 float"
                      id="call"
                    />
                  </Modal>
                  <Modal show={showErrors} onHide={handleCloseErrors} dir="rtl">
                    <Modal.Header closeButton>
                      <Modal.Title className="colorBlackAr">
                        فشل ارسال الرسالة
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="colorBlackAr">
                      برجاء المحاولة في وقت اخر
                    </Modal.Body>
                    <Image
                      src="assets/images/failed.png"
                      className="mb-5 float"
                      id="call"
                    />
                  </Modal>
                </Row>
              </>
            )}
          </>
        )}
        {isLoading && <Spinner />}
      </Container>
    </>
  );
};

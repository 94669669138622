import React, {useState, useEffect, useCallback} from 'react';
import {Container, Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Animate from './Animate';
import Spinner from './Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {updateUserState} from 'state/login';
import {RootState} from 'state';

interface OtpModalProps {
  isOtpModalOpen: boolean;
  onHide: () => void;
  verificationCode: string | null;
  sendOtp: () => Promise<{status: boolean; errorCode: string | null}>;
  idNumber: string;
  dob: string;
  selectedOption?: string;
  yakeenData?: any;
  shouldBeElmVerified?: boolean;
  verifyElmUser: (iqama_number: number, userData: any) => Promise<any>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const OtpModal: React.FC<OtpModalProps> = ({
  isOtpModalOpen,
  onHide,
  verificationCode,
  sendOtp,
  idNumber,
  dob,
  selectedOption,
  yakeenData,
  shouldBeElmVerified,
  verifyElmUser,
  loading,
  setLoading,
}) => {
  const {token} = useSelector((state: RootState) => state.login);
  const {t, i18n} = useTranslation();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [error, setError] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true); // Start with disabled resend
  const [countdown, setCountdown] = useState(60); // Start countdown from 60 seconds
  const [isRTL, setIsRTL] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const direction = i18n.dir();
  useEffect(() => {
    setIsRTL(i18n.language === 'ar');
  }, [i18n.language]);

  useEffect(() => {
    // Start countdown when modal opens
    if (isOtpModalOpen) {
      setResendDisabled(true); // Disable resend button when modal opens
      setCountdown(60); // Reset countdown to 60 seconds

      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setResendDisabled(false); // Enable resend button after countdown finishes
            return 60;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer when the component unmounts or modal closes
    }
  }, [isOtpModalOpen]);

  const handleOtpComparison = useCallback(
    async (enteredOtp: string) => {
      setLoading(true);
      if (verificationCode && enteredOtp === verificationCode) {
        setError(false);
        onHide();

        if (!shouldBeElmVerified) {
          toast.success(t('pages.newRegister.redirectingToRegister'), {
            position: i18n.language === 'ar' ? 'top-left' : 'top-right',
          });
        }

        try {
          const formattedDob = moment(dob).locale('en').format('YYYY-MM-DD');
          if (shouldBeElmVerified && yakeenData) {
            const result = await verifyElmUser(Number(idNumber), {
              gender:
                yakeenData.personBasicInfo.sexCode === '2' ? 'Female' : 'Male',
              full_name_arabic: yakeenData.personBasicInfo.fullName,
              nationality: idNumber.startsWith('1')
                ? 'SA'
                : yakeenData.personBasicInfo.nationalityDescEn,
              full_name: yakeenData.personBasicInfo.fullNameT,
              date_of_birth: moment(dob).locale('en').format('YYYY-MM-DD'),
              iqama_number: idNumber,
            });

            if (result && token) {
              dispatch(updateUserState(idNumber, token));
              setTimeout(() => {
                navigate('/', {
                  state: {
                    elmVerifiedSuccessfully: true,
                  },
                });
              }, 1000);
            } else {
              toast.error(t('pages.newRegister.elmVerifiedFailed'), {
                position: i18n.language === 'ar' ? 'top-left' : 'top-right',
              });
              setLoading(false); // Only stop loading on failure
            }
          } else {
            setTimeout(() => {
              navigate('/register', {
                state: {
                  personBasicInfo: yakeenData.personBasicInfo,
                  doctorsPortal: selectedOption === 'doctor',
                  id: idNumber,
                  dob: formattedDob,
                },
              });
            }, 3000);
          }
        } catch (error) {
          toast.error(
            t('pages.newRegister.OtpVerification.yakeenError', {
              message: error.message,
            }),
            {
              position: i18n.language === 'ar' ? 'top-left' : 'top-right',
            },
          );
          setLoading(false); // Only stop loading on error
        }
      } else {
        setError(true);
        setLoading(false); // Stop loading if OTP comparison fails
      }
    },
    [
      verificationCode,
      shouldBeElmVerified,
      yakeenData,
      idNumber,
      dob,
      selectedOption,
      verifyElmUser,
      token,
      dispatch,
      navigate,
      setLoading,
      t,
      i18n.language,
      onHide,
    ],
  );

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const index = parseInt(e.target.dataset.index || '0', 10);
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);

      if (e.target.value.length >= 1) {
        const nextIndex = index + 1; // Always move to the next input to the right
        if (nextIndex < otp.length) {
          document
            .querySelector<HTMLInputElement>(
              `.wrapper input[data-index="${nextIndex}"]`,
            )
            ?.focus();
        } else {
          handleOtpComparison(newOtp.join(''));
        }
      }
    },
    [otp, handleOtpComparison],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const index = parseInt(e.currentTarget.dataset.index || '0', 10);
      if (e.key === 'Backspace' && otp[index] === '') {
        const prevIndex = index - 1; // Move to the previous input to the left
        if (prevIndex >= 0 && prevIndex < otp.length) {
          document
            .querySelector<HTMLInputElement>(
              `.wrapper input[data-index="${prevIndex}"]`,
            )
            ?.focus();
        }
      }
    },
    [otp],
  );

  const handleResendOtp = useCallback(async () => {
    setLoading(true);
    const {status, errorCode} = await sendOtp();
    setLoading(false);

    if (status) {
      setOtp(['', '', '', '']); // Reset the OTP fields
      setResendDisabled(true);
      setCountdown(60); // Reset countdown when resend is successful
    } else {
      toast.error(
        t('pages.newRegister.OtpVerification.resendOtpError', {errorCode}),
        {
          position: i18n.language === 'ar' ? 'top-left' : 'top-right',
        },
      );
    }
  }, [sendOtp, t, i18n.language]);

  return (
    <Animate>
      {loading ? (
        <Spinner />
      ) : (
        <Modal show={isOtpModalOpen} onHide={onHide} id="otpModal">
          <Container dir={direction}>
            <Modal.Header
              closeButton
              className={i18n.language === 'ar' ? 'modal-header-ar' : ''}>
              <Modal.Title>
                {t('pages.newRegister.OtpVerification.title')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={`wrapper ${isRTL ? 'rtl' : 'ltr'}`} dir="ltr">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    className={`field ${error ? 'error' : ''}`}
                    maxLength={1}
                    data-index={index}
                    value={digit}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    disabled={loading}
                    autoComplete="one-time-code"
                    inputMode="numeric"
                  />
                ))}
              </div>
              <button
                className="resend"
                onClick={handleResendOtp}
                disabled={resendDisabled || loading}>
                {resendDisabled ? (
                  <p>
                    <span style={{opacity: 0.5}}>
                      {t('pages.newRegister.OtpVerification.resendOtpIn')}
                    </span>{' '}
                    ({countdown})s
                  </p>
                ) : (
                  <p>{t('pages.newRegister.OtpVerification.resendOtp')}</p>
                )}
              </button>
            </Modal.Body>
          </Container>
        </Modal>
      )}
    </Animate>
  );
};

export default OtpModal;

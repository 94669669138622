import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

function FontManager() {
  const {i18n} = useTranslation();

  useEffect(() => {
    const handleLanguageChange = () => {
      const currentLanguage = i18n.language;
      const isArabic = currentLanguage === 'ar';

      const fontFamilySansSerif = isArabic
        ? "'IBM Plex Sans Arabic', sans-serif"
        : "'Helvetica-Neue-Font-Normal', sans-serif";

      document.documentElement.style.setProperty(
        '--bs-font-sans-serif',
        fontFamilySansSerif,
      );

      document.documentElement.dir = isArabic ? 'rtl' : 'ltr';
    };

    i18n.on('languageChanged', handleLanguageChange);
    handleLanguageChange();

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return null;
}

export default FontManager;

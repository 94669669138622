import React, {useEffect, useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import axios from '../axiosConfig';
import {
  Button,
  Card,
  CardBody,
  Container,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';
import Animate from 'components/Animate';
import Spinner from 'components/Spinner';
import {FollowUpConsultations} from 'components/FollowUpConsultations';
import {FollowUpConsultationsAr} from 'components/FollowUpConsultationsAr';
import {
  logout,
  setIsDoctorVerified,
  setIsPaymentConsultationEnabled,
  isAvailableUser,
} from 'state/login';
import {resetPayment} from 'state/payment';
import {RootState} from 'state';

// Import images
//@ts-ignore
import twoDoctorsImage from '../assets/images/2doctors.png';
//@ts-ignore
import kids2 from '../assets/images/kids2.png';
//@ts-ignore
import kids1 from '../assets/images/kids1.png';
//@ts-ignore
import patient1 from '../assets/images/patient1.png';
//@ts-ignore
import pediatric1 from '../assets/images/pediatric1.png';
//@ts-ignore
import partners1 from '../assets/images/partners/partner1.png';
//@ts-ignore
import partners3 from '../assets/images/partners/partner3.png';
//@ts-ignore
import partners4 from '../assets/images/partners/partner4.png';
//@ts-ignore
import partners5 from '../assets/images/partners/partner5.png';
//@ts-ignore
import partners6 from '../assets/images/partners/partner6.png';
//@ts-ignore
import paymentFailed from '../assets/icons/cancelled.png';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Home = () => {
  const {user, token, isLogged} = useSelector(
    (state: RootState) => state.login,
  );
  const {paymentStatus} = useSelector((state: RootState) => state.payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {t, i18n} = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [isFollowUpAvailable, setIsFollowUpAvailable] = useState(false);
  const [isConsultationEnabled, setIsConsultationEnabled] = useState(false);
  const [consultationIds, setConsultationIds] = useState([]);
  const [selectedConsultationId, setSelectedConsultationId] = useState(null);
  const [adminTitle, setAdminTitle] = useState('');
  const [adminMessage, setAdminMessage] = useState('');
  const [showFollowUps, setShowFollowUps] = useState(false);
  const [showFollowUpsAr, setShowFollowUpsAr] = useState(false);
  const [showPediatric, setShowPediatric] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showVerificationModalSuccess, setShowVerificationModalSuccess] =
    useState(false);

  const isDoctor = user?.is_doctor && token;

  useEffect(() => {
    if (user && !user.is_elm_verified) {
      setShowVerificationModal(true);
    }
  }, [user]);

  useEffect(() => {
    if (state?.elmVerifiedSuccessfully === true) {
      setShowVerificationModalSuccess(true);
    }
  }, [state]);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        const video = document.querySelector('video');
        if (video) {
          await video.play();
        }
      } catch (error) {
        console.error('Autoplay was prevented:', error);
      } finally {
        setLoading(false);
      }
    };

    loadVideo();
  }, []);

  const checkFollowUpAvailability = useCallback(async () => {
    try {
      const {data} = await axios.get(`${baseURL}/is_follow_up_available/`, {
        headers: {Authorization: `Token ${token}`},
      });
      setIsFollowUpAvailable(data.isFollowUpAvailable);
      setConsultationIds(data.consultationIds);
      if (data.consultationIds.length === 1) {
        setSelectedConsultationId(data.consultationIds[0]);
      }
    } catch (error) {
      console.error('Error checking follow-up availability:', error);
    }
  }, [token]);

  const validateToken = useCallback(async () => {
    try {
      const {data} = await axios.get(`${baseURL}/validate-token/`, {
        headers: {Authorization: `Token ${token}`},
      });
      if (data.message !== 'Token is valid') {
        throw new Error('Invalid token');
      }
    } catch (error) {
      if (token) dispatch(logout(token));
      navigate('/');
    }
  }, [token, dispatch, navigate]);

  const checkConsultationAvailability = useCallback(async () => {
    try {
      const {data} = await axios.get(`${baseURL}/enable-consultation/`, {
        headers: {Authorization: `Token ${token}`},
      });
      setIsConsultationEnabled(data[data.length - 1].is_enabled);
    } catch (error) {
      console.error('Error checking consultation availability:', error);
    }
  }, [token]);

  const checkUserAvailability = useCallback(async () => {
    try {
      const {data: userData} = await axios.get(
        `${baseURL}/retrieve-user/${user?.iqama_number}`,
        {
          headers: {Authorization: `Token ${token}`},
        },
      );
      if (userData) {
        dispatch(isAvailableUser({is_actived: !!userData.is_actived}));
      }
    } catch (error) {
      console.error('Error checking user availability:', error);
    }
  }, [token, user, dispatch]);

  const checkIsConsultationPaymentEnabled = useCallback(
    async (userId) => {
      try {
        const {data} = await axios.get(`${baseURL}/users/`, {
          headers: {Authorization: `Token ${token}`},
        });
        if (data?.length) {
          const loggedInUser = data.find(
            (user) => user?.id === userId && user?.is_payment_enabled,
          );
          dispatch(
            setIsPaymentConsultationEnabled({
              is_payment_enabled: !!loggedInUser,
            }),
          );
        }
      } catch (error) {
        console.error('Error checking consultation payment:', error);
      }
    },
    [token, dispatch],
  );

  const checkIsDoctorEnabled = useCallback(async () => {
    try {
      const {data: userData} = await axios.get(
        `${baseURL}/retrieve-user/${user?.iqama_number}`,
        {
          headers: {Authorization: `Token ${token}`},
        },
      );
      if (userData) {
        dispatch(
          setIsDoctorVerified({
            is_verified: !!userData.is_verified,
          }),
        );
      }
    } catch (error) {
      console.error('Error checking doctor status:', error);
    }
  }, [token, user, dispatch]);

  const checkAdminMessageAvailability = useCallback(async () => {
    try {
      const {data} = await axios.get(`${baseURL}/admin-message/`, {
        headers: {Authorization: `Token ${token}`},
      });
      if (data?.length) {
        setAdminTitle(data[0].message_title.toString());
        setAdminMessage(data[0].message.toString());
      }
    } catch (error) {
      console.error('Error checking admin message:', error);
    }
  }, [token]);

  const checkAllAvailability = useCallback(async () => {
    setLoading(true);
    try {
      await validateToken();
      await Promise.all([
        checkConsultationAvailability(),
        checkUserAvailability(),
        checkIsConsultationPaymentEnabled(user?.id),
        checkAdminMessageAvailability(),
        checkIsDoctorEnabled(),
        !isDoctor && checkFollowUpAvailability(),
      ]);
    } catch (error) {
      console.error('Error checking availability:', error);
    } finally {
      setLoading(false);
    }
  }, [
    validateToken,
    checkConsultationAvailability,
    checkUserAvailability,
    checkIsConsultationPaymentEnabled,
    checkAdminMessageAvailability,
    checkIsDoctorEnabled,
    checkFollowUpAvailability,
    isDoctor,
    user,
  ]);

  useEffect(() => {
    if (isLogged) {
      checkAllAvailability();
    }
  }, [isLogged, checkAllAvailability]);

  useEffect(() => {
    const currentLanguage = i18n.language;

    if (user && isLogged && isDoctor) {
      i18n.changeLanguage('en');
    } else if (
      currentLanguage !== 'en' &&
      (currentLanguage === 'dev' || currentLanguage !== 'ar')
    ) {
      i18n.changeLanguage('ar');
    }
  }, [user, isLogged, isDoctor, i18n]);

  useEffect(() => {
    if (paymentStatus === 'success') {
      dispatch(resetPayment());
    }
  }, [paymentStatus, dispatch]);

  const getAge = (dateString: string) => {
    const birthDate = new Date(dateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const redirectLogin = () => navigate('/login');
  const redirectQuestions = () => navigate('/questions');
  const redirectVideoCall = (followUpConsultationId) => {
    navigate('/video-call', {
      state: {
        isLoggedIn: isLogged,
        user,
        logInToken: token,
        followUpConsultationId,
        isFollowUp: true,
      },
    });
  };
  const redirectCalls = () => navigate('/calls');

  const showPopupPediatric = () => {
    if (user && user.is_elm_verified) {
      setShowPediatric(true);
    } else {
      setShowVerificationModal(true);
    }
  };

  const handleClose = () => setShowPediatric(false);

  const renderYoutubeVideo = () => {
    if (!isLogged && !isDoctor) {
      return (
        <div
          style={{position: 'relative', width: '100%', paddingTop: '56.25%'}}
        >
          <iframe
            src="https://www.youtube.com/embed/Xei9RPFTdHU?autoplay=1&mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '12px',
            }}
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=Xei9RPFTdHU',
                '_blank',
              )
            }
          />
        </div>
      );
    } else if (isLogged && isDoctor && user.doctor_type === 'pediatric') {
      return <Image src={pediatric1} className="float" />;
    } else if (isLogged && isDoctor) {
      return <Image src={twoDoctorsImage} className="float" />;
    } else if (
      !isDoctor &&
      user &&
      getAge(user?.date_of_birth?.toString()) < 14
    ) {
      return <Image src={kids1} className="float" />;
    } else {
      return <Image src={twoDoctorsImage} className="float" />;
    }
  };

  const renderActionButton = () => {
    if (!isLogged) {
      return (
        <div className="btnPrimary" onClick={redirectLogin}>
          {t('pages.home.requestConsultation')}
        </div>
      );
    }

    if (isDoctor) {
      if (user?.is_verified && user?.is_actived) {
        return (
          <div className="btnPrimary" onClick={redirectCalls}>
            {t('pages.home.acceptConsultation')}
          </div>
        );
      } else if (!user?.is_actived) {
        return (
          <p className="mt-4">
            <strong
              style={{
                fontSize: '1.5rem',
                fontStyle: 'italic',
                color: '#3c3c3c',
                lineHeight: '12px',
              }}
            >
              {t('pages.home.notVerified')}
            </strong>
          </p>
        );
      } else {
        return (
          <p className="mt-4">
            <strong
              style={{
                fontSize: '1.5rem',
                fontStyle: 'italic',
                color: '#3c3c3c',
                lineHeight: '12px',
              }}
            >
              {t('pages.home.reviewingApplication')}
            </strong>
          </p>
        );
      }
    }

    if (isConsultationEnabled && user?.is_actived) {
      return (
        <>
          <div
            className="btnPrimary"
            onClick={
              getAge(user?.date_of_birth?.toString()) >= 18
                ? redirectQuestions
                : showPopupPediatric
            }
          >
            {t('pages.home.requestConsultation')}
          </div>
          {renderFollowUpButton()}
        </>
      );
    }

    if (!isConsultationEnabled) {
      return <br />;
    }

    return (
      <p className="mt-4">
        <strong
          style={{
            fontSize: '1.5rem',
            fontStyle: 'italic',
            color: '#3c3c3c',
            lineHeight: '12px',
          }}
        >
          {t('pages.home.notVerified')}
        </strong>
      </p>
    );
  };

  const renderFollowUpButton = () => {
    if (!isFollowUpAvailable) return null;

    if (consultationIds.length <= 1) {
      return (
        <button
          className="btnSecondary"
          id="followUpBtn"
          onClick={() => redirectVideoCall(selectedConsultationId)}
        >
          {t('pages.home.followUp')}
        </button>
      );
    }

    return (
      <button
        className="btnSecondary"
        id="followUpBtn"
        onClick={() =>
          i18n.language === 'en'
            ? setShowFollowUps(true)
            : setShowFollowUpsAr(true)
        }
      >
        {t('pages.home.followUp')}
      </button>
    );
  };

  const renderPartners = () => {
    if (isLogged) return null;

    return (
      <div className="partners-container">
        <div className="title-with-lines">
          <span className="line"></span>
          <h2 className="title" style={{margin: '15px 20px'}}>
            {t('pages.home.ourPartners')}
          </h2>
          <span className="line"></span>
        </div>
        <div
          className={`partners-row ${
            i18n.language === 'en' ? 'scroll-ltr' : 'scroll-rtl'
          }`}
        >
          <div className="partner-logos">
            {[
              partners1,
              partners3,
              partners4,
              partners5,
              partners6,
              partners1,
              partners3,
              partners4,
              partners5,
              partners6,
            ].map((partner, index) => (
              <img
                key={index}
                src={partner}
                alt={`Partner ${index + 1}`}
                className="partner-logo"
              />
            ))}
          </div>
          <div className="partner-logos">
            {[
              partners1,
              partners3,
              partners4,
              partners5,
              partners6,
              partners1,
              partners3,
              partners4,
              partners5,
              partners6,
            ].map((partner, index) => (
              <img
                key={index}
                src={partner}
                alt={`Partner ${index + 1}`}
                className="partner-logo"
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner />
      </div>
    );
  }

  return (
    <Animate>
      {paymentStatus === 'failed' && (
        <Modal
          show={true}
          onHide={() => dispatch(resetPayment())}
          id="viewConsultationModal"
        >
          <Modal.Header>
            <Modal.Title style={{color: 'red', fontWeight: '900'}}>
              {t('pages.paymentResult.errorMessage')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{margin: '0px auto'}}>
            <Image src={paymentFailed} width={250} />
          </Modal.Body>
        </Modal>
      )}
      {showFollowUps && (
        <FollowUpConsultations
          show={showFollowUps}
          onClose={() => setShowFollowUps(false)}
          consultationIds={consultationIds}
        />
      )}
      {showFollowUpsAr && (
        <FollowUpConsultationsAr
          show={showFollowUpsAr}
          onClose={() => setShowFollowUpsAr(false)}
          consultationIds={consultationIds}
        />
      )}
      <div className="d-flex justify-content-center align-items-center">
        <Container className="mt-5">
          {isLogged && (
            <h5
              className={i18n.language === 'en' ? 'text-left' : 'text-right'}
              id="welcomeFullName"
              style={{fontWeight: '600'}}
            >
              {t('pages.home.welcome', {
                name:
                  i18n.language === 'ar'
                    ? user?.full_name_arabic
                    : user?.full_name,
              })}
              <br />
              {!user?.is_payment_enabled && !isDoctor && (
                <span style={{fontStyle: 'italic'}}>
                  {t('pages.home.freeCall')}
                </span>
              )}
            </h5>
          )}
          <h2 className="licenseNo">{t('pages.home.licenseNo')}</h2>
          <div className="homeLogo text-center">
            <h1>
              {t('pages.home.medicalCloud')}
              <br />
              <span id="homeCopyRights">{t('pages.home.cloud')}</span>
            </h1>
            <p>{t('pages.home.emergencyConsultant')}</p>
          </div>
          <Card className="mt-2 mb-3 bg-dark-card">
            <CardBody>
              {renderYoutubeVideo()}
              {renderActionButton()}
            </CardBody>
          </Card>
          {renderPartners()}
          {adminMessage && (
            <Card className="banner" dir="ltr">
              <Card.Title>
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{textAlign: 'center', flex: '1'}}>
                    {adminTitle && (
                      <h3 style={{margin: 0}}>
                        {adminTitle}
                        <span style={{marginLeft: '5px'}}>✨</span>
                      </h3>
                    )}
                  </div>
                  <span
                    id="close"
                    onClick={() => setAdminMessage('')}
                    style={{cursor: 'pointer', marginLeft: 'auto'}}
                  >
                    x
                  </span>
                </div>
              </Card.Title>
              <hr />
              <CardBody className="text-center">{adminMessage}</CardBody>
            </Card>
          )}
          <Modal show={showPediatric} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {user &&
                  getAge(user?.date_of_birth?.toString()) < 18 &&
                  t('pages.home.pediatricMessage')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: 'center'}}>
              <Row>
                <Button
                  type="submit"
                  className="btnPrimary"
                  onClick={redirectQuestions}
                >
                  {t('pages.questions.yes')}
                </Button>
                <Button
                  type="submit"
                  className="btnPrimary"
                  onClick={handleClose}
                >
                  {t('pages.questions.no')}
                </Button>
              </Row>
            </Modal.Body>
            <Image
              src={
                user && getAge(user?.date_of_birth?.toString()) < 14
                  ? kids2
                  : patient1
              }
              className="mb-5 float"
              id="call"
            />
          </Modal>
          <Modal
            show={showVerificationModal}
            onHide={() => setShowVerificationModal(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p>{t('pages.home.identityVerification.message')}</p>
              <Row>
                <Button
                  className="btnPrimary"
                  onClick={() =>
                    navigate('/pre-register/#fillForm', {
                      state: {
                        doctorsPortal: user?.is_doctor,
                        shouldBeElmVerified: true,
                      },
                    })
                  }
                >
                  {t('pages.home.identityVerification.identifyNow')}
                </Button>
                <Button
                  className="btnPrimary"
                  onClick={() => setShowVerificationModal(false)}
                >
                  {t('pages.home.identityVerification.cancel')}
                </Button>
              </Row>
            </Modal.Body>
          </Modal>
          <Modal
            show={showVerificationModalSuccess}
            onHide={() => setShowVerificationModalSuccess(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p>{t('pages.newRegister.elmVerifiedSuccessfully')}</p>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </Animate>
  );
};

export default Home;

import React from 'react';
import {Container, Row, Col, Image, Accordion} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const FAQs = () => {
  const {t} = useTranslation();

  // Access the correct data from the JSON
  const faqAnswers = t('pages.faqs.servicesOffered.answer', {
    returnObjects: true,
  });

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col md={6} sm={12}>
          <h2 className="title">{t('pages.faqs.title')}</h2>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t('pages.faqs.servicesOffered.question')}
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  {/* Use the correct array for mapping */}
                  {faqAnswers.map((item, index) => (
                    <li style={{listStyle: 'inside'}} key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t('pages.faqs.qualifications.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.qualifications.answer')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t('pages.faqs.insurance.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.insurance.answer')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t('pages.faqs.waitingTime.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.waitingTime.answer')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {t('pages.faqs.informationRequired.question')}
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  {t('pages.faqs.informationRequired.answer', {
                    returnObjects: true,
                  }).map((item, index) => (
                    <li style={{listStyle: 'inside'}} key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {t('pages.faqs.unsatisfiedService.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.unsatisfiedService.answer')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {t('pages.faqs.sessionDuration.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.sessionDuration.answer')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                {t('pages.faqs.privacyProtection.question')}
              </Accordion.Header>
              <Accordion.Body>
                {t('pages.faqs.privacyProtection.answer')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={6} sm={12} className="scientistCol customScientistCol">
          <Image src="assets/images/faq.png" className="float fixed-img" />
        </Col>
      </Row>
    </Container>
  );
};

export default FAQs;

import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Modal, Image} from 'react-bootstrap';
import {ShowAllConsultations} from './ShowAllConsultations';

export const SelectedPatient = ({patient, handleCloseSelectedPatient}) => {
  const [show, setShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [visibleConsultations, setVisibleConsultations] = useState(false);

  const showConsultations = () => {
    setSelectedPatient(patient);
    setVisibleConsultations(true);
    setShow(false);
  };

  const hideConsultations = () => {
    setVisibleConsultations(false);
    handleCloseSelectedPatient();
  };

  const handleClose = () => {
    setShow(false);
    handleCloseSelectedPatient();
  };

  const getAge = (dateString) => {
    const birthDate = new Date(dateString);
    const today = new Date();
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (birthDate.getDate() > today.getDate()) {
      const temp = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += temp;
      months--;
    }

    return years > 0 ? `${years} years` : `${months} months`;
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Viewing Profile: {patient?.full_name_arabic}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <strong style={{fontSize: '20px'}}>Age:</strong>{' '}
                  <span style={{fontSize: '16px'}}>
                    {getAge(patient?.date_of_birth)}
                  </span>
                </div>
                <div className="mb-3">
                  <strong style={{fontSize: '20px'}}>Date of Birth:</strong>{' '}
                  <span style={{fontSize: '16px'}}>
                    {patient?.date_of_birth}{' '}
                  </span>
                  <span style={{fontSize: '12px'}}>(YYYY-MM-DD)</span>
                </div>
                <div className="mb-3">
                  <strong style={{fontSize: '20px'}}>ID/Iqama Number:</strong>{' '}
                  <span style={{fontSize: '16px'}}>
                    {patient?.iqama_number}
                  </span>
                </div>
                <div className="mb-3">
                  <strong style={{fontSize: '20px'}}>Mobile Number:</strong>{' '}
                  <span style={{fontSize: '16px'}}>
                    {patient?.mobile_number}
                  </span>
                </div>
                <div className="mb-3">
                  <strong style={{fontSize: '20px'}}>Gender:</strong>{' '}
                  <span style={{fontSize: '16px'}}>{patient?.gender}</span>
                </div>
              </Col>
              <Col md={6}>
                <Image src="assets/images/success.png" fluid />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <div className="btnPrimary" onClick={showConsultations}>
                View all consultations
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>

      {visibleConsultations && (
        <ShowAllConsultations
          patient={selectedPatient}
          handleCloseAllConsultations={hideConsultations}
        />
      )}
    </>
  );
};

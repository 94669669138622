import React, {useEffect, useState, useCallback} from 'react';
import {
  FaArrowRight,
  FaIdCard,
  FaCalendarAlt,
  FaArrowLeft,
} from 'react-icons/fa';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

import {toast, ToastContainer} from 'react-toastify';
//@ts-ignore
import animatedDoctor from '../../assets/images/Designer.png';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import axios from '../../axiosConfig';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'state';
import {updateUserState} from 'state/login';
import {Spinner} from 'react-bootstrap';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ar-sa';
import {createTheme, ThemeProvider} from '@mui/material/styles';
const largeFontTheme = createTheme({
  typography: {
    fontSize: 18, // Increase base font size
  },
  components: {
    //@ts-ignore
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: '1.1rem', // Larger font for weekday labels
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: '1.1rem', // Larger font for month/year in header
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            fontSize: '1.1rem', // Larger font for buttons
          },
        },
      },
    },
  },
});
interface NewRegisterProps {
  selectedOption?: 'doctor' | 'patient' | '';
  setIdNumber: React.Dispatch<React.SetStateAction<string>>;
  setDob: React.Dispatch<React.SetStateAction<string>>;
  sendOtp: () => Promise<any>;
  onYakeenRequest: () => Promise<any>;
  shouldBeElmVerified: boolean;
}

const NewRegister: React.FC<NewRegisterProps> = ({
  selectedOption,
  setIdNumber,
  setDob,
  sendOtp,
  onYakeenRequest,
  shouldBeElmVerified,
}) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {token} = useSelector((state: RootState) => state.login);
  const {t, i18n} = useTranslation();
  const [idNumber, setLocalIdNumber] = useState('');
  const [dob, setLocalDob] = useState<moment.Moment | null>(null);
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale(i18n.language === 'ar' ? 'ar-sa' : 'en');
  }, [i18n.language]);

  const handleIdNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (!selectedOption) {
        toast.error(t('pages.newRegister.indicateType'), {
          position: i18n.language === 'ar' ? 'top-left' : 'top-right',
        });
        return;
      }

      if (/^\d{0,10}$/.test(value)) {
        setLocalIdNumber(value);
        setIdNumber(value);
      }
    },
    [selectedOption, setIdNumber, t, i18n.language],
  );
  const handleDobChange = useCallback(
    (newDate: moment.Moment | null) => {
      setLocalDob(newDate);
      setDob(newDate ? newDate.format('YYYY-MM-DD') : '');
    },
    [setDob],
  );

  const validateIqamaNumber = useCallback(
    (value: string) => /^[12]\d{9}$/.test(value),
    [],
  );
  const checkUserExists = async (iqama_number: string) => {
    try {
      const {data} = await axios.get(`${baseURL}/check-user/${iqama_number}`);
      return data.exists;
    } catch (error) {
      console.clear();
    }
  };
  const verifyElmUser = useCallback(
    async (iqama_number: number, userData: any) => {
      try {
        const response = await axios.patch(
          `${baseURL}/elm-verify-user/${iqama_number}/`,
          {...userData, is_elm_verified: true},
          {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );
        return response.data;
      } catch (error) {
        console.error('Error verifying user:', error);
        throw error;
      }
    },
    [token],
  );

  const handleNext = useCallback(async () => {
    try {
      if (step === 0) {
        if (!validateIqamaNumber(idNumber)) {
          setError(t('pages.newRegister.idIqamaNumberValidation'));
        } else if (!shouldBeElmVerified) {
          setLoading(true);
          const userExists = await checkUserExists(idNumber);
          setLoading(false);
          if (userExists) {
            toast.error(t('pages.newRegister.userAlreadyRegistered'), {
              position: i18n.language === 'ar' ? 'top-left' : 'top-right',
            });
            return;
          } else {
            setError('');
            setStep(1);
          }
        } else {
          setError('');
          setStep(1);
        }
      } else if (step === 1) {
        if (dob) {
          setError('');
          setStep(2);

          const yakeenData = await onYakeenRequest();
          if (!yakeenData || yakeenData.error) {
            return;
          }

          const {errorCode: otpErrorCode} = await sendOtp();
          if (['10006', '10007'].includes(otpErrorCode)) {
            if (shouldBeElmVerified) {
              console.log(yakeenData);
              const result = await verifyElmUser(Number(idNumber), {
                gender:
                  yakeenData.personBasicInfo.sexCode === '2'
                    ? 'Female'
                    : 'Male',
                full_name_arabic: yakeenData.personBasicInfo.fullName,
                nationality: idNumber.startsWith('1')
                  ? 'SA'
                  : yakeenData.personBasicInfo.nationalityDescEn,
                full_name: yakeenData.personBasicInfo.fullNameT,
                date_of_birth: moment(dob).locale('en').format('YYYY-MM-DD'),
                iqama_number: idNumber,
              });

              if (result && token) {
                dispatch(updateUserState(idNumber, token));
                setTimeout(() => {
                  navigate('/', {
                    state: {
                      elmVerifiedSuccessfully: true,
                    },
                  });
                }, 1000);
              } else {
                toast.error(t('pages.newRegister.elmVerifiedFailed'), {
                  position: i18n.language === 'ar' ? 'top-left' : 'top-right',
                });
              }
            } else {
              toast.success(t('pages.newRegister.redirectingToRegister'), {
                position: i18n.language === 'ar' ? 'top-left' : 'top-right',
              });
              setTimeout(() => {
                navigate('/register', {
                  state: {
                    personBasicInfo: yakeenData.personBasicInfo,
                    doctorsPortal: selectedOption === 'doctor',
                    id: idNumber,
                    dob: moment(dob).locale('en').format('YYYY-MM-DD'),
                  },
                });
              }, 3000);
            }
          } else if (otpErrorCode) {
            // Only show the error toast if it's not 10006 or 10007
            toast.error(t(`pages.newRegister.otpError.${otpErrorCode}`), {
              position: i18n.language === 'ar' ? 'top-left' : 'top-right',
            });
          }
        } else {
          setError(t('pages.newRegister.pleaseEnterDob'));
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: i18n.language === 'ar' ? 'top-left' : 'top-right',
      });
    }
  }, [
    step,
    idNumber,
    dob,
    validateIqamaNumber,
    t,
    sendOtp,
    onYakeenRequest,
    shouldBeElmVerified,
    verifyElmUser,
    navigate,
    dispatch,
    token,
    selectedOption,
    i18n.language,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        handleNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNext]);
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  };
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      handleNext();
    },
    [handleNext],
  );

  return (
    <main dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <section className="my-2 mt-lg-4 mb-lg-3" id="fillForm">
        <div
          className={`new-form-container ${
            i18n.language === 'ar' ? 'rtl' : ''
          }`}>
          <img src={animatedDoctor} className="doctor3d float" />
          {selectedOption && (
            <form className="new-form" onSubmit={handleSubmit}>
              <ul
                className={
                  i18n.language === 'ar' ? 'new-itemsAR' : 'new-itemsEN'
                }>
                {Array.from({length: 3}).map((_, index) => (
                  <li
                    key={index}
                    className={index === step ? 'new-active' : ''}></li>
                ))}
              </ul>
              <fieldset
                className={`new-fieldset ${
                  step === 0 ? 'new-enable' : 'new-disable'
                }`}>
                <div
                  className={`new-icon ${
                    i18n.language === 'ar' ? 'new-right' : 'new-left'
                  }`}>
                  <FaIdCard />
                </div>
                <input
                  type="text"
                  name="idnumber"
                  placeholder={t('pages.newRegister.idIqamaNumber')}
                  value={idNumber}
                  inputMode="numeric"
                  onChange={handleIdNumberChange}
                />
                <div
                  className={`new-icon ${
                    i18n.language === 'ar' ? 'new-left' : 'new-right'
                  } new-button`}
                  onClick={loading ? undefined : handleNext}>
                  {loading ? (
                    <Spinner style={{position: 'relative', top: '-5px'}} />
                  ) : i18n.language === 'ar' ? (
                    <FaArrowLeft />
                  ) : (
                    <FaArrowRight />
                  )}
                </div>
                {error && step === 0 && (
                  <p className="error-message">{error}</p>
                )}
              </fieldset>
              <fieldset
                className={`new-fieldset ${
                  step === 1 ? 'new-enable' : 'new-disable'
                } ${i18n.language === 'en' ? 'dob-field' : ''}`}>
                <div
                  className={`new-icon ${
                    i18n.language === 'ar' ? 'new-right' : 'new-left'
                  }`}>
                  <FaCalendarAlt />
                </div>
                <ThemeProvider theme={largeFontTheme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      views={['year', 'month', 'day']}
                      disableFuture={true}
                      value={dob}
                      onChange={handleDobChange}
                      className={
                        i18n.language === 'ar'
                          ? 'dob-datepicker-ar'
                          : 'dob-datepicker'
                      }
                      localeText={
                        i18n.language === 'ar'
                          ? {
                              cancelButtonLabel: 'إلغاء',
                              okButtonLabel: 'اختيار',
                              toolbarTitle: 'اختر التاريخ',
                            }
                          : {
                              cancelButtonLabel: 'Cancel',
                              okButtonLabel: 'OK',
                              toolbarTitle: 'Select Date',
                            }
                      }
                    />
                  </LocalizationProvider>
                </ThemeProvider>
                <div
                  className={`new-icon ${
                    i18n.language === 'ar' ? 'new-left' : 'new-right'
                  } new-button`}
                  onClick={handleNext}>
                  {i18n.language === 'ar' ? <FaArrowLeft /> : <FaArrowRight />}
                </div>
                {error && step === 1 && (
                  <p className="error-message">{error}</p>
                )}
              </fieldset>
              <fieldset
                className={`new-fieldset ${
                  step === 2 ? 'new-enable' : 'new-disable'
                }`}>
                <p>{t('pages.newRegister.thanksForYourTime')}</p>
                <div
                  className={`new-icon ${
                    i18n.language === 'ar' ? 'new-left' : 'new-right'
                  } new-button`}
                  onClick={() => navigate('/')}>
                  <FaArrowRight />
                </div>
              </fieldset>
            </form>
          )}
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default NewRegister;

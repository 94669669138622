import React, {useCallback, useEffect, useState} from 'react';
import {
  Container,
  Form,
  Row,
  Col,
  Image,
  FormLabel,
  Button,
  Modal,
  FormGroup,
} from 'react-bootstrap';
//@ts-ignore
import infoImage from '../assets/icons/info.png';
//@ts-ignore
import callImage from '../assets/images/call.png';
//@ts-ignore
import questionsImage from '../assets/images/questions.png';
//@ts-ignore
import failedImage from '../assets/images/failed.png';
//@ts-ignore
import kid3 from '../assets/images/kid3.png';
import axios from '../axiosConfig';
import Spinner from 'components/Spinner';
import {useTranslation} from 'react-i18next';
import {RootState} from 'state';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  logout,
  setIsPaymentConsultationEnabled,
  isAvailableUser,
} from 'state/login';
import {useDispatch} from 'react-redux';
import {
  initiatePaymentFailed,
  initiatePaymentStart,
  initiatePaymentSuccess,
} from 'state/payment';

const Questions: React.FC = () => {
  const {user, token, isLogged} = useSelector(
    (state: RootState) => state.login,
  );
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(`${baseURL}/validate-token/`, {
          headers: {Authorization: `Token ${token}`},
        });
        if (response.status === 200) {
          setIsTokenValidated(true);
        } else {
          if (token) dispatch(logout(token));
          navigate('/login');
        }
      } catch (error) {
        if (token) dispatch(logout(token));
        navigate('/login');
      }
    };

    if (token) {
      validateToken();
    } else {
      navigate('/login');
    }
  }, [baseURL, dispatch, navigate, token]);

  const [show, setShow] = useState<boolean>(false);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showFailedPayment, setShowFailedPayment] = useState<boolean>(false);
  const [consultationCosts, setConsultationCosts] = useState<string>('');
  const [isConsultationEnabled, setIsConsultationEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleClose = () => setShow(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleCloseFailedPayment = () => setShowFailedPayment(false);

  const getConsultationPrice = useCallback(async () => {
    const response = await axios.get(`${baseURL}/payment-amount/`, {
      headers: {Authorization: `Token ${token}`},
    });
    if (response.data && response.data.length > 0) {
      const lastObject =
        user?.nationality === 'SA'
          ? response.data[response.data.length - 1].amount
          : response.data[response.data.length - 1].amount +
            response.data[response.data.length - 1].amount * 0.15;
      setConsultationCosts(lastObject.toString());
    }
  }, [baseURL, token, user?.nationality]);

  const showPopup = () => {
    setShow(true);
  };
  const showHint2 = () => {
    var hint = document.querySelector('#popupHint2') as HTMLElement;
    hint.style.display = 'block';
  };
  const hideHint2 = () => {
    var hint = document.querySelector('#popupHint2') as HTMLElement;
    hint.style.display = 'none';
  };
  const showHint3 = () => {
    var hint = document.querySelector('#popupHint3') as HTMLElement;
    hint.style.display = 'block';
  };
  const hideHint3 = () => {
    var hint = document.querySelector('#popupHint3') as HTMLElement;
    hint.style.display = 'none';
  };

  const getAge = (dateString: Date) => {
    const birthDate = new Date(dateString);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (birthDate.getDate() > today.getDate()) {
      const temp = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += temp;
      months--;
    }

    return years;
  };

  const goToPayment = async () => {
    setIsLoading(true);

    dispatch(initiatePaymentStart()); // Dispatch the start action

    await axios({
      method: 'post',
      url: `${baseURL}/initiate-payment/`,
      headers: {
        Authorization: `Token ${token}`, // Use the token that was already set in the Redux state
      },
    })
      .then(function (response) {
        console.clear();
        handleClosePayment();

        // Check if the backend returned a redirect_url, order_id, user, and token
        if (response.data.redirect_url && response.data.order_id) {
          // Dispatch success and store order_id, user, and token from the response
          dispatch(
            initiatePaymentSuccess({
              orderId: response.data.order_id,
            }),
          );
          window.location.href = response.data.redirect_url;
        } else {
          dispatch(initiatePaymentFailed('Failed to initiate payment'));
          setShowFailedPayment(true);
        }
      })
      .catch(function (error) {
        console.clear();
        setIsLoading(false);
        handleClosePayment();
        dispatch(initiatePaymentFailed('Payment initiation failed'));
        setShowFailedPayment(true);
      });
  };

  const goToVideoCall = () => {
    navigate('/video-call', {
      state: {
        isLoggedIn: isLogged,
        user: user,
        logInToken: token,
        isFollowUp: false,
      },
    });
  };

  const handleQuestionsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = document.querySelector('form') as HTMLFormElement;
    const radioButtons = form.querySelectorAll('input[type="radio"]:checked');

    let allNoSelected = true;

    for (let i = 0; i < radioButtons.length; i++) {
      const radioButton = radioButtons[i] as HTMLInputElement;
      if (radioButton.value === 'YES') {
        allNoSelected = false;
        break;
      }
    }

    if (!allNoSelected) {
      setShow(true);
      handleClosePayment();
      return;
    } else {
      await checkConsultationAvailability();
      await checkIsConsultationPaymentEnabled(user?.id);

      if (user?.is_payment_enabled) {
        setShowPayment(true);
      } else {
        goToVideoCall();
      }
    }
  };

  const checkConsultationAvailability = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/enable-consultation/`, {
        headers: {Authorization: `Token ${token}`},
      });
      const lastIndex = response.data.length - 1;
      if (response.data[lastIndex].is_enabled) {
        setIsConsultationEnabled(true);
      } else {
        setIsConsultationEnabled(false);
        navigate('/');
      }
    } catch (error) {
      console.clear();
    }
  }, [baseURL, navigate, token]);

  const checkUserAvailability = useCallback(async () => {
    try {
      const {data: userData} = await axios.get(
        `${baseURL}/retrieve-user/${user?.iqama_number}`,
        {
          headers: {Authorization: `Token ${token}`},
        },
      );
      if (userData) {
        dispatch(
          isAvailableUser({
            is_actived: !!userData.is_actived,
          }),
        );
      }
    } catch (error) {
      console.clear();
    }
  }, [baseURL, dispatch, token]);

  const checkIsConsultationPaymentEnabled = useCallback(
    async (userId) => {
      try {
        const response = await axios.get(`${baseURL}/users/`, {
          headers: {Authorization: `Token ${token}`},
        });

        if (response.data && Array.isArray(response.data)) {
          const loggedInUser = response.data.find(
            (user) => user?.id === userId && user?.is_payment_enabled === true,
          );
          if (loggedInUser) {
            dispatch(
              setIsPaymentConsultationEnabled({is_payment_enabled: true}),
            );
          } else {
            dispatch(
              setIsPaymentConsultationEnabled({is_payment_enabled: false}),
            );
          }
        } else {
          console.clear();
        }
      } catch (error) {
        console.clear();
      }
    },
    [baseURL, dispatch, token],
  );

  useEffect(() => {
    const loadData = async () => {
      await checkConsultationAvailability();
      await checkUserAvailability();
      await checkIsConsultationPaymentEnabled(user?.id);
      await getConsultationPrice();
      setIsLoading(false);
    };

    if (isTokenValidated) {
      loadData();
    }
  }, [
    checkConsultationAvailability,
    checkUserAvailability,
    checkIsConsultationPaymentEnabled,
    getConsultationPrice,
    user?.id,
    isTokenValidated,
  ]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        isConsultationEnabled && (
          <Container className="mt-5">
            <Row>
              <Col md={6} sm={12}>
                <Form className="medicalForm" onSubmit={handleQuestionsSubmit}>
                  {user && getAge(user?.date_of_birth) >= 14 ? (
                    // QUESTIONS FOR ADULTS
                    <>
                      <FormGroup>
                        <h2 className="title">
                          {t('pages.questions.beforeConsultation')}
                        </h2>
                        <FormLabel
                          htmlFor="yesOrNo1"
                          className="questions-label">
                          {t('pages.questions.question1')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo1"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo1"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo2"
                          className="questions-label">
                          {t('pages.questions.question2')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo2"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo2"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo3"
                          className="questions-label">
                          {t('pages.questions.question3')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo3"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo3"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo4"
                          className="questions-label">
                          {t('pages.questions.question4')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo4"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo4"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question5')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo5"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo5"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo6"
                          className="questions-label">
                          {t('pages.questions.question6')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo6"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo6"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo7"
                          className="questions-label">
                          {t('pages.questions.question7')}
                          <Image
                            src={infoImage}
                            onMouseEnter={showHint2}
                            onMouseLeave={hideHint2}
                          />
                          <span id="popupHint2">
                            ({t('pages.questions.eg')}:
                            <br />o {t('pages.questions.hint2')}
                            <br />o {t('pages.questions.hint3')}
                            <br />o {t('pages.questions.hint4')}
                            <br />o {t('pages.questions.hint5')}
                            <br />o {t('pages.questions.hint6')})
                          </span>
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo7"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo7"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo8"
                          className="questions-label">
                          {t('pages.questions.question8')}
                          <Image
                            src={infoImage}
                            onMouseEnter={showHint3}
                            onMouseLeave={hideHint3}
                          />
                          <span id="popupHint3">
                            ({t('pages.questions.eg')}:
                            <br />o {t('pages.questions.hint7')}
                            <br />o {t('pages.questions.hint8')}
                            <br />o {t('pages.questions.hint9')}
                            <br />o {t('pages.questions.hint10')}
                            <br />o {t('pages.questions.hint11')}
                            <br />o {t('pages.questions.hint12')}
                            <br />o {t('pages.questions.hint13')}
                            <br />o {t('pages.questions.hint14')})
                          </span>
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo8"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo8"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                    </>
                  ) : (
                    // PEDIATRIC QUESTIONS
                    <>
                      <FormGroup>
                        <h2 className="title">
                          {t('pages.questions.beforeConsultation')}
                        </h2>
                        <FormLabel
                          htmlFor="yesOrNo1"
                          className="questions-label">
                          {t('pages.questions.question1Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo1"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo1"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo2"
                          className="questions-label">
                          {t('pages.questions.question2Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo2"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo2"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo3"
                          className="questions-label">
                          {t('pages.questions.question3Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo3"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo3"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo4"
                          className="questions-label">
                          {t('pages.questions.question4Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo4"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo4"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question5Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo5"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo5"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question6Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo6"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo6"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question7Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo7"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo7"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question8Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo8"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo8"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <FormLabel
                          htmlFor="yesOrNo5"
                          className="questions-label">
                          {t('pages.questions.question9Ped')}
                        </FormLabel>
                        <FormGroup className="d-flex mb-4" id="d-flex">
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.yes')}`}
                            id="yes"
                            name="yesOrNo9"
                            defaultValue="YES"
                            onChange={showPopup}
                          />
                          <Form.Check
                            type="radio"
                            label={`${t('pages.questions.no')}`}
                            id="no"
                            name="yesOrNo9"
                            defaultChecked
                          />
                        </FormGroup>
                      </FormGroup>
                    </>
                  )}
                  {/* PAYMENT MODAL */}
                  <Modal show={showPayment} onHide={handleClosePayment}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <br />
                        {t('pages.questions.initiateCall')}{' '}
                        <strong>
                          <span style={{color: '#404242'}}>
                            {' '}
                            {consultationCosts} {t('pages.questions.sar')},
                          </span>
                        </strong>{' '}
                        {t('pages.questions.proceed')}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: 'center'}}>
                      <Row>
                        <Button
                          type="submit"
                          className="btnPrimary"
                          onClick={goToPayment}>
                          {t('pages.questions.yes')}
                        </Button>
                        <Button
                          type="submit"
                          className="btnPrimary"
                          onClick={handleClosePayment}>
                          {t('pages.questions.no')}
                        </Button>
                      </Row>
                      <Image src={callImage} className="mb-5 float" id="call" />
                    </Modal.Body>
                  </Modal>
                  {/* FAILED PAYMENT MODAL */}
                  <Modal
                    show={showFailedPayment}
                    onHide={handleCloseFailedPayment}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('pages.questions.failed')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Button
                          type="submit"
                          className="btnPrimary"
                          onClick={goToPayment}>
                          {t('pages.questions.yes')}
                        </Button>
                        <Button
                          type="submit"
                          className="btnPrimary"
                          onClick={handleCloseFailedPayment}>
                          {t('pages.questions.no')}
                        </Button>
                      </Row>
                      <Image
                        src={failedImage}
                        className="mb-5 float"
                        id="call"
                      />
                    </Modal.Body>
                  </Modal>
                  {/* QUESTIONS MODAL */}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {t('pages.questions.apologize')}{' '}
                        <strong>
                          <a href="#tel:997" style={{color: '#404242'}}>
                            {' '}
                            {t('pages.questions.call')}
                          </a>
                        </strong>{' '}
                        {t('pages.questions.hospital')}
                      </Modal.Title>
                    </Modal.Header>
                    <Image src={callImage} className="mb-5 float" id="call" />
                  </Modal>
                  <Button type="submit" className="btnPrimary btnLeft">
                    {t('pages.questions.requestBtn')}
                  </Button>
                </Form>
              </Col>
              <Col md={6} sm={12} className="scientistCol text-center">
                {user && getAge(user?.date_of_birth) < 14 ? (
                  <Image src={kid3} className="float fixed-img" />
                ) : (
                  <Image src={questionsImage} className="float fixed-img" />
                )}
              </Col>
            </Row>
          </Container>
        )
      )}
    </>
  );
};

export default Questions;

import React, {useState} from 'react';
import {Container, Row, Col, Image, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaEye} from 'react-icons/fa';

export const OurDoctors = () => {
  const {t, i18n} = useTranslation();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const doctors = [
    {
      name: t('pages.ourDoctors.doctorName'),
      jobTitle: t('pages.ourDoctors.jobTitleValue'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue'),
      registrationNumber: t('pages.ourDoctors.registrationNumber'),
      experiences: t('pages.ourDoctors.experiencesValue'),
      image: 'assets/images/doctors/0.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName2'),
      jobTitle: t('pages.ourDoctors.jobTitleValue2'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue2'),
      registrationNumber: t('pages.ourDoctors.registrationNumber2'),
      experiences: t('pages.ourDoctors.experiencesValue2'),
      image: 'assets/images/doctors/1.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName3'),
      jobTitle: t('pages.ourDoctors.jobTitleValue3'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue3'),
      registrationNumber: t('pages.ourDoctors.registrationNumber3'),
      experiences: t('pages.ourDoctors.experiencesValue3'),
      image: 'assets/images/doctors/2.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName4'),
      jobTitle: t('pages.ourDoctors.jobTitleValue4'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue4'),
      registrationNumber: t('pages.ourDoctors.registrationNumber4'),
      experiences: t('pages.ourDoctors.experiencesValue4'),
      image: 'assets/images/doctors/3.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName5'),
      jobTitle: t('pages.ourDoctors.jobTitleValue5'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue5'),
      registrationNumber: t('pages.ourDoctors.registrationNumber5'),
      experiences: t('pages.ourDoctors.experiencesValue5'),
      image: 'assets/images/doctors/4.png',
    },
    {
      name: t('pages.ourDoctors.doctorName6'),
      jobTitle: t('pages.ourDoctors.jobTitleValue6'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue6'),
      registrationNumber: t('pages.ourDoctors.registrationNumber6'),
      experiences: t('pages.ourDoctors.experiencesValue6'),
      image: 'assets/images/doctors/5.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName10'),
      jobTitle: t('pages.ourDoctors.jobTitleValue10'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue10'),
      registrationNumber: t('pages.ourDoctors.registrationNumber10'),
      experiences: t('pages.ourDoctors.experiencesValue10'),
      image: 'assets/images/doctors/8.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName11'),
      jobTitle: t('pages.ourDoctors.jobTitleValue11'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue11'),
      registrationNumber: t('pages.ourDoctors.registrationNumber11'),
      experiences: t('pages.ourDoctors.experiencesValue11'),
      image: 'assets/images/doctors/9.jpg', // Using the image name from your document
    },
    {
      name: t('pages.ourDoctors.doctorName12'),
      jobTitle: t('pages.ourDoctors.jobTitleValue12'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue12'),
      registrationNumber: t('pages.ourDoctors.registrationNumber12'),
      experiences: t('pages.ourDoctors.experiencesValue12'),
      image: 'assets/images/doctors/10.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName14'),
      jobTitle: t('pages.ourDoctors.jobTitleValue14'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue14'),
      registrationNumber: t('pages.ourDoctors.registrationNumber14'),
      experiences: t('pages.ourDoctors.experiencesValue14'),
      image: 'assets/images/doctors/12.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName15'),
      jobTitle: t('pages.ourDoctors.jobTitleValue15'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue15'),
      registrationNumber: t('pages.ourDoctors.registrationNumber15'),
      experiences: t('pages.ourDoctors.experiencesValue15'),
      image: 'assets/images/doctors/13.jpg',
    },
    {
      name: t('pages.ourDoctors.doctorName16'),
      jobTitle: t('pages.ourDoctors.jobTitleValue16'),
      registeredTitle: t('pages.ourDoctors.registeredTitleValue16'),
      registrationNumber: t('pages.ourDoctors.registrationNumber16'),
      experiences: t('pages.ourDoctors.experiencesValue16'),
      image: 'assets/images/doctors/14.jpg',
    },
  ];

  const handleShowModal = (doctor) => {
    setSelectedDoctor(doctor);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDoctor(null);
  };

  return (
    <Container className="mt-5 pl-6">
      <Row className="wrapReverse" dir={i18n.language === 'ar' ? 'ltr' : 'rtl'}>
        <Col md={6} sm={12} className="scientistCol customScientistCol">
          <Image
            src="assets/images/ourDoctors.png"
            className="float fixed-img"
          />
        </Col>
        <Col md={6} sm={12} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          <div
            className={`ekseerForm ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            }`}
          >
            <h2 className="title">{t('pages.ourDoctors.title')}</h2>
            <h4>{t('pages.ourDoctors.description')}</h4>
          </div>
          <div className="row team-row">
            {doctors.map((doctor, index) => (
              <div
                className="col-md-4 col-sm-6 team-wrap"
                key={index}
                onClick={() => handleShowModal(doctor)}
                style={{cursor: 'pointer'}}
              >
                <div className="team-member text-center">
                  <div className="team-img">
                    <img src={doctor.image} alt={doctor.name} />
                    <div className="overlay">
                      <div className="team-details text-center">
                        <FaEye size={32} color="white" />
                      </div>
                    </div>
                  </div>
                  <span>{doctor.jobTitle}</span>
                  <h6 className="team-title">{doctor.name}</h6>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedDoctor?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDoctor && (
            <>
              <Image
                src={selectedDoctor.image}
                fluid
                className="mb-3"
                style={{margin: '0px auto'}}
              />
              <p>
                <strong>{t('pages.ourDoctors.jobTitle')}:</strong>{' '}
                {selectedDoctor.jobTitle}
              </p>
              <p>
                <strong>
                  {t('pages.ourDoctors.registeredTitleAndNumber')}:
                </strong>{' '}
                {selectedDoctor.registrationNumber}
              </p>
              <p>
                <strong>{t('pages.ourDoctors.experiences')}:</strong>{' '}
                {selectedDoctor.experiences}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

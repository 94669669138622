import {Footer} from 'components/Footer';
import PreRegister from 'components/PreRegister';
import {RedirectComponent} from 'components/RedirectedPDF';
import SharedNavbar from 'components/SharedNavbar';
import ScrollToTop from 'helpers/helpers';
import {About} from 'pages/About';
import {ConfirmForgotPassword} from 'pages/ConfirmForgotPassword';
import {Contact} from 'pages/Contact';
import FAQs from 'pages/FAQs';
import {ForgotPassword} from 'pages/ForgotPassword';
import Home from 'pages/Home';
import NotFoundPage from 'pages/NotFoundPage';
import {OurDoctors} from 'pages/OurDoctors';
import {PrivacyPolicy} from 'pages/PrivacyPolicy';
import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

export const PublicRoutes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <SharedNavbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/pre-register" element={<PreRegister />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route
          path="/confirm-reset-password/:token"
          element={<ConfirmForgotPassword />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/our-doctors" element={<OurDoctors />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/video-call" element={<Navigate to="/" />} />
        <Route path="/consultations" element={<Navigate to="/" />} />
        <Route path="/questions" element={<Navigate to="/" />} />
        <Route path="/profile" element={<Navigate to="/" />} />
        <Route path="/payment" element={<Navigate to="/" />} />
        <Route path="/redirect" element={<RedirectComponent />} />;
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default PublicRoutes;
